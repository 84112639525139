import { Select, Typography } from "antd";
import React, { useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import DeleteIcon from "@mui/icons-material/Delete";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ShowPaymentMethodDetails from "./ShowPaymentMethodDetails";
import GenericButton from "../../GenericButton/GenericButton";

const PaymentPanel = ({ paymentData, handlePaymentData,
  setIsPaymentModalOpen, isDetails,
  setSelectListingModalOpen, listingID }) => {
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);

  const [currentlySelectedPaymentDetails, setCurrentlySelectedPaymentDetails] = useState();

  return (
    <div className="bg-white p-5">
      <ShowPaymentMethodDetails
        setShowPaymentMethodModal={setShowPaymentMethodModal}
        showPaymentMethodDetails={currentlySelectedPaymentDetails}
        showPaymentMethodModal={showPaymentMethodModal}
      />
      <div className="text-lg text-themeGrey font-bold md:my-5">Credit Cards</div>
      {paymentData?.map((p, idx) => {
        return <div key={idx}>
          {p?.default ? <Typography style={{ color: 'blueviolet' }}>Default payment method <StarIcon /></Typography> : null}
          <div className="grid xs:grid-cols-1 sm:grid-cols-2 gap-3">
            <div>
              <div className="custom-form-input">
                <div className="flex items-center ">
                  <label className="font-bold text-themeGrey capitalize">Credit card owner name</label>
                </div>
              </div>
              <div className="w-[100%] rounded-md placeholder:capitalize px-3 border min-h-[32px] flex items-center bg-[#0000000a] text-[#00000040]">
                {p?.cc_owner_name}
              </div>
            </div>
            <div>
              <div className="custom-form-input">
                <div className="flex items-center ">
                  <label className="font-bold text-themeGrey capitalize">Credit card</label>
                </div>
              </div>
              <div className="w-[100%] rounded-md placeholder:capitalize px-3 border min-h-[32px] flex items-center bg-[#0000000a] text-[#00000040]">
                {p?.cc_number}
              </div>
            </div>
          </div>

          <div className="grid xs:grid-cols-1 sm:grid-cols-3 gap-3 py-5 ">
            <div>
              <div className="custom-form-input">
                <div className="flex items-center ">
                  <label className="font-bold text-themeGrey capitalize">Year</label>
                </div>
              </div>
              <div className="w-[100%] rounded-md placeholder:capitalize px-3 border min-h-[32px] flex items-center bg-[#0000000a] text-[#00000040]">
                {p?.year}
              </div>
            </div>

            <div>
              <div className="custom-form-input">
                <div className="flex items-center ">
                  <label className="font-bold text-themeGrey capitalize">Month</label>
                </div>
              </div>
              <div className="w-[100%] rounded-md placeholder:capitalize px-3 border min-h-[32px] flex items-center bg-[#0000000a] text-[#00000040]">
                {p?.month}
              </div>
            </div>
            <div>
              <div className="custom-form-input">
                <div className="flex items-center ">
                  <label className="font-bold text-themeGrey capitalize">CVC</label>
                </div>
              </div>
              <div className="w-[100%] rounded-md placeholder:capitalize px-3 border min-h-[32px] flex items-center bg-[#0000000a] text-[#00000040]">
                {p?.cvc}
              </div>
            </div>          </div>
          {!isDetails && <div className="border-b-2 border-creme">
            <div className="flex flex-wrap md:flex-nowrap gap-3 md:gap-0 justify-end my-5 ">
              <GenericButton
                type="button"
                label="Remove"
                icon={<DeleteIcon />}
                className="bg-themeGreen text-white rounded-none"
                iconLeftAlign={true}
                onClick={() => {
                  // const updatedPaymentDetails = [];
                  // paymentData?.forEach((payment, index) => {
                  //   if (index !== idx) {
                  //     if (updatedPaymentDetails.length < 1) {
                  //       updatedPaymentDetails.push({
                  //         ...payment,
                  //         default: true
                  //       })
                  //     } else {
                  //       updatedPaymentDetails.push({
                  //         ...payment,
                  //         default: false
                  //       })
                  //     }
                  //   }
                  // })
                  handlePaymentData(arr => [...paymentData.filter((e, i) => i !== idx)]);
                }}
              />
              {!p?.default ? (
                <GenericButton
                  label="Mark as default"
                  icon={<StarIcon />}
                  iconLeftAlign={true}
                  type={"button"}

                  className="bg-themeGreen text-white rounded-none"
                  onClick={() => {
                    const updatedPaymentDetails = []
                    paymentData?.forEach((payment, index) => {
                      if (index === idx) {
                        updatedPaymentDetails.push({
                          ...payment,
                          default: true
                        })
                      } else {
                        updatedPaymentDetails.push({
                          ...payment,
                          default: false
                        })
                      }
                    })
                    handlePaymentData(arr => [...updatedPaymentDetails]);
                  }}
                />
              ) : null}
              <GenericButton
                label="Show payment method"
                icon={<CreditCardIcon />}
                className="bg-themeGreen text-white rounded-none"
                iconLeftAlign={true}
                onClick={() => {
                  setCurrentlySelectedPaymentDetails(p);
                  setShowPaymentMethodModal(prev => !prev);
                }}
              />
            </div>
          </div>}
          <hr className="mb-4" />
        </div>
      })}

      {(!isDetails && paymentData.length < 1) && <div className="flex justify-end my-5">
        <GenericButton
          label="Add payment method"
          icon={<AddCircleIcon />}
          iconLeftAlign={true}
          className="bg-themeGreen text-white rounded-none"
          onClick={() => {
            if (listingID) {
              setIsPaymentModalOpen(prev => !prev)
            } else {
              setSelectListingModalOpen(prev => !prev)
            }
          }}
        />
      </div>}
      <div className="flex justify-start">
        <div>
          <div className="text-black mb-1">Manually checked</div>
          <Select
            placeholder="New"
            className="mb-1 rounded-none xs:w-full sm:w-60"
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentPanel;
