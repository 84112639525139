import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Button, DatePicker, Select } from "antd";
import Dropdown from "antd/es/dropdown/dropdown";
import { DownOutlined } from "@ant-design/icons";
import { isEmpty } from 'lodash'
import dayjs from "dayjs";
import { Space } from "antd";

import Loading from "../../components/Loaders/Loading";

import { apiInstance, routes } from "../../utils/API";
import { calculateDistance } from "../../utils/helper";

import "animation.css";
import "./relocationTool.css";

const BOOLEAN_KEY = 'upgradeAvailable'
const STRING_KEY = 'distance'
const OTHER_PROPERTIES_SIZE_LIMIT = 21

const sortOtherPropertiesByUpgradeAvailableAndDistance = (a, b) => {
  return a[BOOLEAN_KEY] === b[BOOLEAN_KEY]
    ? Number(a[STRING_KEY]) - Number(b[STRING_KEY])
    : a[BOOLEAN_KEY]
      ? 1
      : -1
}

const createOtherPropertiesMapByUpgrade = listing => {
  const listingValue = Number(
    listing?.basicInfo?.internal_listing_name?.split("B")[0]
  )

  return item => {
    const value = Number(
      item.propertyDetails?.basicInfo?.internal_listing_name?.split('B')[0]
    )

    return ({
      ...item,
      upgradeAvailable: value > listingValue
    })
  }
}

const RelocationTool = () => {
  const [selectedListigs, setSelectedListings] = React.useState(null);
  const [listings, setListings] = React.useState([]);
  const [completeListings, setCompleteListings] = React.useState([]);
  const [checkinDate, setCheckinDate] = React.useState("");
  const [checkoutDate, setCheckoutDate] = React.useState("");
  const [options, setOptions] = React.useState(null);
  const [breakdown, setBreakdown] = React.useState([]);
  const [errorListing, setErrorListing] = React.useState("");
  const [errorCheckin, setErrorCheckin] = React.useState("");
  const [errorCheckout, setErrorCheckout] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [showCleaning, setShowCleaning] = React.useState(true);
  const [relocation, setRelocation] = React.useState(false);
  const [reservation, setReservation] = React.useState(false);
  const [extension, setExtension] = React.useState(false);
  const [discount, setDiscount] = React.useState(0);
  const [discountType, setDiscountType] = React.useState("");
  const [listing, setListing] = React.useState("");
  const [counter, setCounter] = React.useState(0);
  const [currency, setCurrency] = React.useState("GBP");
  const [exchangeRate, setExchangeRate] = React.useState(1);
  const [allExchangeRates, setAllExchangeRates] = React.useState([]);
  const [optionError, setOptionError] = React.useState("");
  const [notFoundError, setNotFoundError] = React.useState('');

  React.useEffect(() => {
    if (options?.days) {
      if (Number(options.days) < 7) {
        setDiscount(0);
        setDiscountType("No discount");
      } else if (Number(options.days) >= 7 && Number(options.days) < 14) {
        setDiscount(10);
        setDiscountType("Weekly discount");
      } else if (Number(options.days) >= 14 && Number(options.days) < 28) {
        setDiscount(15);
        setDiscountType("2-week discount");
      } else if (Number(options.days) >= 28 && Number(options.days) < 90) {
        setDiscount(20);
        setDiscountType("Monthly discount");
      } else if (Number(options.days) >= 90 && Number(options.days) < 180) {
        setDiscount(27);
        setDiscountType("Quarterly discount");
      } else if (Number(options.days) >= 180 && Number(options.days) < 363) {
        setDiscount(33);
        setDiscountType("Semi-annual discount");
      } else if (Number(options.days) >= 364) {
        setDiscount(38);
        setDiscountType("Annual discount");
      }
    }
  }, [options]);

  React.useEffect(() => {
    if (options && counter === 0) {
      const data = options?.otherProperties?.filter((item) => {
        if (
          item?.availabilityInner !== false &&
          item?.propertyDetails?.basicInfo?.tags === listing?.basicInfo?.tags
        ) {
          return true;
        }
      });
      if (data?.length > 0) {
        let tempOptions = JSON.parse(JSON.stringify(options));
        let tempListings = JSON.parse(JSON.stringify(listing));
        tempListings.priceAndFees.cleaningFee =
          data[0].propertyDetails.priceAndFees.cleaningFee;
        tempOptions.rent = options.rent;
        setOptions(tempOptions);
        setCounter(1);
      }
    }
  }, [options]);

  React.useEffect(() => {
    (async () => {
      const response = localStorage.getItem("reservationProperties");
      const reservations = JSON.parse(response);

      const exchange = await apiInstance.get(routes.EXCHANGE_RATES);

      setAllExchangeRates(exchange.data.data.exchangerates);
      if (reservations.status === 200) {
        const list = reservations?.data?.data || [];
        setListings(list);
        setCompleteListings(list);
      }
      setLoading(false);
    })();
  }, []);

  const handleCurrency = (currency) => {
    setCurrency(currency);
    if (currency !== "GBP") {
      const selection = allExchangeRates.filter(
        (item) => item.slug === currency
      );
      setExchangeRate(selection[0].currentRate);
    } else {
      setExchangeRate(1);
    }
  };

  const handleItemSelect = (e) => {
    handleCurrency(e.key);
  };

  const otherProperties = options?.otherProperties?.length > 0
    ? options.otherProperties
      .map(createOtherPropertiesMapByUpgrade(listing))
      .sort(sortOtherPropertiesByUpgradeAvailableAndDistance)
      .slice(0, OTHER_PROPERTIES_SIZE_LIMIT)
    : []

  const menuItems = [
    {
      label: "GBP",
      key: "GBP",
    },
    {
      label: "EUR",
      key: "EUR",
    },
    {
      label: "AED",
      key: "AED",
    }
  ];

  const menuProps = {
    items: menuItems,
    onClick: handleItemSelect,
    placement: "bottomLeft",
    sx: { zIndex: 10 }
  };

  const handleSubmit = async () => {
    setCounter(0);
    try {
      if (!selectedListigs) {
        setErrorListing("Listing is a required field.")
        return
      } else if (!checkinDate) {
        setErrorCheckin("Checkin is a required field.")
        return
      } else if (!checkoutDate) {
        setErrorCheckout("Checkout is a required field.")
        return
      }

      if (!relocation && !reservation && !extension) {
        setOptionError("Option is required.")
        return
      }

      setIsLoading(true)

      let response
      const selectedProperty = listings.find(prop => prop?._id === selectedListigs)

      const dateFrom = `&from=${dayjs(checkinDate).format("YYYY-MM-DD")}`
      const dateTo = `&to=${dayjs(checkoutDate).format("YYYY-MM-DD")}`
      const lat = `&lat=${selectedProperty.address.lat}`
      const lng = `&lng=${selectedProperty.address.lng}`
      const propertyName = `&propertyName=${selectedProperty.basicInfo.internal_listing_name}`

      const commonParams = dateFrom + dateTo + lat + lng + propertyName

      if (relocation) {
        let url = routes.RESERVATION_RELOCATION
        url += `?listingId=${selectedProperty.ruId}${commonParams}`

        response = await apiInstance(url)
      } else {
        let url = routes.RESERVATION_TOOL_API
        url += `?listingId=${selectedProperty.ruId?.split(",")[0]}${commonParams}`

        response = await apiInstance(url)
      }

      let requiredData = { ...response.data.propertyDetails }
      let modifiedOptions = []

      if (requiredData?.otherProperties?.length) {
        modifiedOptions = requiredData.otherProperties
          .filter(item => {
            return item?.availabilityInner &&
              !item?.propertyDetails?.basicInfo?.internal_listing_name?.toLowerCase()?.includes('communal area')
          })
          .map((item) => {
            let distance = calculateDistance(
              item.propertyDetails.address.lat,
              item.propertyDetails?.address.lng,
              selectedProperty.address.lat,
              selectedProperty.address.lng
            ).toFixed(2);
            return { ...item, distance: distance }
          })
      }

      requiredData = { ...requiredData, otherProperties: [...modifiedOptions] }
      setOptions(requiredData)
      setCounter(0)
      setIsLoading(false)
      setNotFoundError('')
    } catch (error) {
      if (error?.response?.status === 404) {
        setNotFoundError(`${error.response.data.message} found`)
      }
      setIsLoading(false)
    }
  };

  const numberWithCommas = (x) => {
    const roundedNumber = Number(x).toFixed(2);
    const numberWithCommas = roundedNumber
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return numberWithCommas;
  };

  const disabledDate = (current) => {
    return current && current < dayjs(checkinDate).endOf("day");
  };
  const disabledDateCheckin = (current) => {
    return current && current < dayjs().startOf("day");
  };

  const getFormatedAmenities = (amenities) => {
    let arr = [];
    if (Object.keys(amenities).length > 0) {
      for (let [key, value] of Object.entries(amenities)) {
        if (key !== "External listing name") {
          arr.push(`${key} : ${value}`);
        }
      }
    }
    return arr.sort((a, b) => a.length - b.length);
  };

  const filteredlistings = React.useMemo(() => {
    if (!listings?.length) {
      return []
    }

    return listings.filter(listing => {
      return !listing?.basicInfo?.internal_listing_name?.toLowerCase()?.includes('communal area')
    })
  }, [listings])

  const renderAmenities = amenities => {
    if (Array.isArray(amenities)) {
      if (isEmpty(amenities[0])) {
        return "Amenities not found... Please check Guest Comm Board"
      } else {
        return getFormatedAmenities(amenities[0]).map(item => {
          return (
            <div className="bg-gray-200 px-2 rounded" key={item}>
              <p className="flex-inline text-xs">{item}</p>
            </div>
          );
        })
      }
    }

    return amenities
      ? getFormatedAmenities(amenities).map(item => {
        return (
          <div className="bg-gray-200 px-2 rounded" key={item}>
            <p className="flex-inline text-xs">{item}</p>
          </div>
        );
      })
      : "Amenities not found... Please check Guest Comm Board"
  }

  return (
    <div className="bg-slate-100 ">
      {loading ? (
        <Loading />
      ) : (
        <div className="bg-slate-100 ">
          <div className="flex items-baseline gap-5 m-4">
            <div className="text-themeGrey text-xl font-bold ">
              {relocation ? "Relocation Tool" : "Reservation Tool"}
            </div>
          </div>

          <div>
            <Dropdown menu={menuProps}>
              <Button className="flex items-center justify-around rounded-2xl bg-white fixed right-4 top-20 w-24 z-[10]">
                <Space>
                  {currency} <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </div>

          <div className={`flex ${notFoundError ? 'flex-col' : 'flex-row'} flex-wrap 
            ${notFoundError ? 'items-center' : ''} mb-10 gap-10 
            bg-slate-100 pt-14 justify-center`}>
            <div className="w-[90%] md:w-96 px-10 py-20 border-2  border-slate-100 rounded-xl shadow-xl bg-white">
              <Select
                allowClear
                disabled={isLoading}
                showSearch={true}
                filterOption={(input, option) => {
                  if (
                    option.data.basicInfo?.internal_listing_name
                      ?.toLowerCase()
                      .includes(input?.toLowerCase()) ||
                    option.data.address.address
                      ?.toLowerCase()
                      .includes(input?.toLowerCase()) ||
                    option.data.address.city
                      ?.toLowerCase()
                      .includes(input?.toLowerCase()) ||
                    option.data.address.country
                      ?.toLowerCase()
                      .includes(input?.toLowerCase()) ||
                    option.data.basicInfo?.tags
                      ?.toLowerCase()
                      .includes(input?.toLowerCase()) ||
                    option.data.basicInfo?.external_listing_name
                      ?.toLowerCase()
                      .includes(input?.toLowerCase())
                  ) {
                    return true;
                  }
                }}
                placeholder="Select Listing"
                onChange={(e) => {
                  setListing(e && listings.find(l => l._id === e));
                  setSelectedListings(e);
                  setOptions(null);
                  setBreakdown([]);
                  setErrorListing("");
                  setNotFoundError('')
                }}
                value={selectedListigs}
                options={filteredlistings.map((l, i) => {
                  return {
                    label: (
                      <div>
                        <div className="flex items-center gap-x-2">
                          {l?.basicInfo?.number_of_units > 1 && (
                            <span className="border px-1 inline-flex ml-1 leading-[normal]">
                              {l?.basicInfo?.number_of_units}{" "}
                            </span>
                          )}

                          <p className="text-xs font-bold">{`${l.basicInfo?.tags}`}</p>
                          {l?.basicInfo?.internal_listing_name}
                        </div>
                        <p className="text-xs">{`${l?.basicInfo?.external_listing_name}`}</p>
                      </div>
                    ),
                    value: l?._id,
                    key: i,
                    data: l,
                  };
                })}
                className=" w-full"
              />
              {errorListing && (
                <p className="text-xs mt-2 text-red-600">{errorListing}</p>
              )}

              <div className="mt-5">
                <DatePicker
                  placeholder="Check-in date"
                  disabled={isLoading}
                  className="w-full h-8 rounded-md"
                  value={checkinDate}
                  disabledDate={disabledDateCheckin}
                  onChange={(date) => {
                    if (date === null) {
                      setCheckinDate("");
                    } else {
                      setCheckinDate(dayjs(date));
                    }
                    setCheckoutDate("");
                    setOptions(null);
                    setBreakdown([]);
                    setErrorCheckin("");
                    setNotFoundError('')
                  }}
                />
              </div>
              {errorCheckin && (
                <p className="text-xs mt-2 text-red-600">{errorCheckin}</p>
              )}
              <div className="mt-5">
                <DatePicker
                  disabled={!checkinDate || isLoading}
                  placeholder="Check-out date"
                  className="w-full h-8 rounded-md"
                  disabledDate={disabledDate}
                  value={checkoutDate}
                  onChange={(date) => {
                    if (date === null) {
                      setCheckoutDate("");
                    } else {
                      setCheckoutDate(dayjs(date));
                    }
                    setOptions(null);
                    setBreakdown([]);
                    setErrorCheckout("");
                    setNotFoundError('')
                  }}
                />
              </div>
              {errorCheckout && (
                <p className="text-xs mt-2 text-red-600">{errorCheckout}</p>
              )}
              <div className="flex flex-col">
                <p className="mt-5">Please select one option:</p>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={reservation}
                      onChange={(e) => {
                        setShowCleaning(true);
                        setReservation(e.target.checked);
                        setRelocation(false);
                        setExtension(false);
                        setErrorCheckin("");
                        setOptionError("");
                        setNotFoundError('')
                      }}
                      style={{ color: reservation ? '#00B4B4' : '#d9d9d9' }}
                    />
                  }
                  label="New reservation"
                  style={{ fontSize: "10px" }}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relocation}
                      onChange={(e) => {
                        setShowCleaning(true);
                        setRelocation(e.target.checked);
                        setReservation(false);
                        setExtension(false);
                        setOptions(null);
                        setBreakdown([]);
                        setErrorCheckin("");
                        setOptionError("");
                        setNotFoundError('')
                      }}
                      style={{ color: relocation ? '#00B4B4' : '#d9d9d9' }}
                    />
                  }
                  label="Relocation"
                  style={{ fontSize: "10px" }}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={extension}
                      onChange={(e) => {
                        setShowCleaning(false);
                        setExtension(e.target.checked);
                        setRelocation(false);
                        setReservation(false);
                        setErrorCheckin("");
                        setOptionError("");
                        setNotFoundError('')
                      }}
                      style={{ color: extension ? '#00B4B4' : '#d9d9d9' }}
                    />
                  }
                  label="Extension"
                  style={{ fontSize: "10px" }}
                />
              </div>

              {optionError && (
                <p className="text-xs mt-2 text-red-600">{optionError}</p>
              )}

              {isLoading ? (
                <Loading />
              ) : (
                <Button className="w-full mt-6" onClick={handleSubmit}>
                  {relocation ? "Get Relocation" : "Get Price"}
                </Button>
              )}
            </div>

            {notFoundError && (
              <div className="font-bold text-lg text-themeGrey mx-auto">
                {notFoundError}
              </div>
            )}

            {/* first */}
            {options && (
              <div className=" w-[90%] md:w-96 mx-auto md:mx-0 px-5 py-5 border-2  border-slate-100 rounded-xl shadow-xl bg-white">
                <div className="mb-5 flex justify-between">
                  <div>
                    {!options.availability ? (
                      <p className="bg-red-400 text-white rounded-lg w-fit px-2 text-xs py-1">
                        Unavailable
                      </p>
                    ) : (
                      <p className="bg-green-600 text-white rounded-lg w-fit px-2 text-xs py-1">
                        Available
                      </p>
                    )}
                  </div>
                  {!relocation && (
                    <div className="bg-[#00b4b4] rounded-lg shadow-xl">
                      <p className=" px-2 text-xs py-1 text-white">
                        Min stay {options.minStay} days
                      </p>
                    </div>
                  )}
                </div>
                <img className="rounded-xl" src={listing?.media?.[0]} alt="" />
                {listing?.basicInfo?.tags && (
                  <div className="flex gap-2 mt-2">
                    <p className="text-xs font-bold">Tag</p>

                    <p className="text-xs">{`${listing?.basicInfo?.tags}`}</p>
                  </div>
                )}
                {listing?.basicInfo?.internal_listing_name && (
                  <div className="flex gap-2 mt-2">
                    <p className="text-xs font-bold">Listing</p>

                    <p className="text-xs">
                      {`${listing?.basicInfo?.internal_listing_name}`}
                    </p>
                  </div>
                )}
                <div className="flex gap-2 mt-2">
                  <p className="text-xs font-bold">Address</p>
                  <p className="text-xs">
                    {`${listing?.address?.address}, ${listing?.address?.city
                      }, ${listing?.address?.country}`}
                  </p>
                </div>
                <div className="gap-2 mt-2">
                  <p className="text-xs font-bold mb-2">Amenities</p>
                  <div className="flex flex-wrap gap-1 w-full ">
                    {renderAmenities(listing?.mondayAmenities)}
                  </div>
                </div>

                <div className="flex justify-between mt-5">
                  <div className="flex gap-1">
                    <p>Rent</p>
                  </div>
                  <p>{` ${numberWithCommas(
                    Number(options?.rent) * exchangeRate
                  )} ${currency}`}</p>
                </div>

                <div>
                  {showCleaning && (
                    <div className="flex justify-between">
                      <p>Cleaning</p>
                      <p>{`${numberWithCommas(
                        Number(listing?.priceAndFees?.cleaningFee) * exchangeRate
                      )} ${currency}`}</p>
                    </div>
                  )}

                  {discount !== 0 && (
                    <div className="flex justify-between">
                      <div className="flex gap-1">
                        <p>Discount</p>
                        <p className="text-xs font-bold self-center">
                          ({discountType})
                        </p>
                      </div>
                      <p>{discount}%</p>
                    </div>
                  )}
                  <div className="w-full border-[1px] border-slate-150 my-2" />
                  <div className="flex justify-between">
                    <div className="flex gap-1 items-center">
                      <p className="font-bold">Total</p>
                      <p className="text-xs font-bold">
                        {` (${currency === "EUR"
                          ? "€ "
                          : currency === "AED"
                            ? "AED "
                            : "£ "
                          }${(
                            (Number(options?.rent) / Number(options?.days)) *
                            ((100 - Number(discount)) / 100) *
                            exchangeRate
                          ).toFixed(2)} per night) `}
                      </p>
                    </div>
                    <p className="font-bold">
                      {`${numberWithCommas(
                        (Number(options?.rent) *
                          ((100 - Number(discount)) / 100) +
                          (showCleaning
                            ? Number(listing?.priceAndFees?.cleaningFee)
                            : 0)) *
                        exchangeRate
                      )} ${currency}`}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          {options?.otherProperties?.length > 0 && (
            <div className="flex items-baseline gap-5 m-4">
              <div className="text-themeGrey text-lg font-bold ml-10 mt-5">
                Other Properties near by
              </div>
            </div>
          )}
          {/*next */}
          <div className="flex flex-wrap  mb-10 gap-10 bg-slate-100 pt-5 justify-center">
            {otherProperties.map((item, i) => {
              if (item?.propertyDetails?.basicInfo?.internal_listing_name?.split("B")[0] >=
                listing?.basicInfo?.internal_listing_name?.split("B")[0]) {
                return (
                  <div className="w-[90%] md:w-96 mx-auto md:mx-0 px-5 py-5  border-2  border-slate-100 rounded-xl shadow-xl bg-white flex flex-col justify-between" key={i + 1}>
                    <div>
                      <div className="flex justify-between">
                        <div className="mb-5 flex justify-between w-full">
                          <div>
                            {!item.availabilityInner ? (
                              <p className="bg-red-400 text-white rounded-lg w-fit px-2 text-xs py-1">
                                Unavailable
                              </p>
                            ) : (
                              <p className="bg-green-600 text-white rounded-lg w-fit px-2 text-xs py-1">
                                Available
                              </p>
                            )}
                          </div>
                          {!relocation && (
                            <div className="bg-[#00b4b4] rounded-lg shadow-xl">
                              <p className=" px-2 text-xs py-1 text-white">
                                Min stay {item.minStayInner} days
                              </p>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="relative">
                        {item.upgradeAvailable && (
                          <div className="absolute bg-[#00b4b4] py-2 px-4 rounded-br-xl rounded-tl-lg shadow-2xl">
                            <p className="text-white font-bold ">Upgrade</p>
                          </div>
                        )}
                        <div className="absolute bg-green-600 py-2 px-4 right-[10px] bottom-[10px] rounded-lg shadow-2xl">
                          <p className="text-white font-bold ">
                            {item?.distance} miles away
                          </p>
                        </div>
                        <img
                          className="rounded-xl"
                          src={item?.propertyDetails?.media?.[0]}
                        />
                      </div>
                      {/* <p className="font-bold text-xs mt-2 flex items-center justify-center">{`Within ${item.miles} miles`}</p> */}
                      {item?.propertyDetails?.basicInfo?.tags && (
                        <div className="flex gap-2 ">
                          <p className="text-xs font-bold mt-2">Tag</p>

                          <p className="text-xs mt-2">
                            {item?.propertyDetails?.basicInfo?.tags}
                          </p>
                        </div>
                      )}
                      {item?.propertyDetails?.basicInfo
                        ?.internal_listing_name && (
                          <div className="flex gap-2 ">
                            <p className="text-xs font-bold mt-2">Listing</p>

                            <p className="text-xs mt-2">
                              {
                                item?.propertyDetails?.basicInfo
                                  ?.internal_listing_name
                              }
                            </p>
                          </div>
                        )}
                      <div className="flex gap-2 mt-2">
                        <p className="text-xs font-bold">Address</p>
                        <p className="text-xs">
                          {`${item?.propertyDetails?.address?.address}, ${item?.propertyDetails?.address?.city}, ${item?.propertyDetails?.address?.country}`}
                        </p>
                      </div>

                      <div className="gap-2 mt-2">
                        <p className="text-xs font-bold mb-2">Amenities</p>
                        <div className="flex flex-wrap gap-1 w-full ">
                          {renderAmenities(item?.propertyDetails?.mondayAmenities)}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap"></div>
                    <div className="mt-5">
                      <div className="flex justify-between mt-5">
                        <div className="flex gap-1">
                          <p>Rent</p>
                        </div>
                        <p>{` ${numberWithCommas(
                          Number(item?.rentInner) * exchangeRate
                        )} ${currency}`}</p>
                      </div>

                      {showCleaning && (
                        <div className="flex justify-between">
                          <p>Cleaning</p>
                          <p>{`${numberWithCommas(
                            Number(
                              item?.propertyDetails?.priceAndFees?.cleaningFee
                            ) * exchangeRate
                          )} ${currency}`}</p>
                        </div>
                      )}
                      {discount !== 0 && (
                        <div className="flex justify-between">
                          <div className="flex gap-1">
                            <p>Discount</p>
                            <p className="text-xs font-bold self-center">
                              ({discountType})
                            </p>
                          </div>
                          <p>{discount}%</p>
                        </div>
                      )}
                      <div>
                        <div className="flex justify-between mt-5">
                          <div className="flex gap-1 items-center">
                            <p>Difference in price</p>
                          </div>
                          <p>
                            {`${numberWithCommas(
                              (Number(item?.rentInner) *
                                ((100 - discount) / 100) +
                                (showCleaning
                                  ? Number(
                                    item?.propertyDetails?.priceAndFees?.cleaningFee
                                  )
                                  : 0) -
                                (Number(options.rent) *
                                  ((100 - Number(discount)) / 100) +
                                  (showCleaning
                                    ? Number(
                                      listing?.priceAndFees?.cleaningFee
                                    )
                                    : 0))) *
                              exchangeRate
                            )} ${currency}`}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="w-full border-[1px] border-slate-150 my-2" />
                        <div className="flex justify-between">
                          <div className="flex gap-1 items-center">
                            <p className="font-bold">Total</p>
                            <p className="text-xs font-bold">{`(${currency === "EUR"
                              ? "€ "
                              : currency === "AED"
                                ? "AED "
                                : "£ "
                              }${(
                                (Number(item?.rentInner) /
                                  Number(options.days)) *
                                ((100 - Number(discount)) / 100) *
                                exchangeRate
                              ).toFixed(2)} per night)`}</p>
                          </div>
                          <p className="font-bold">
                            {`${numberWithCommas(
                              (Number(item?.rentInner) *
                                ((100 - discount) / 100) +
                                (showCleaning
                                  ? Number(
                                    item?.propertyDetails?.priceAndFees?.cleaningFee
                                  )
                                  : 0)) *
                              exchangeRate
                            )} ${currency}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }

              return null
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default RelocationTool;
