/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Select, Input } from "antd";
import GenericButton from "../../components/GenericButton/GenericButton";
import { unitTypeList } from "../../utils/constants";
import CalendarTopBar from "../../components/Calendar/CalendarTopBar";
import MultiCalendar from "../../components/Calendar/MultiCalendar/MultiCalendar";
import { apiInstance } from "../../utils/API";
import { AppContext } from "../../AppContext";
import NoAccess from "../../components/NoAccess";
import ListingNotFound from "../../components/Calendar/MultiCalendar/ListingNotFound";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { Close } from "@mui/icons-material";

const { Search } = Input;
let timeOut = null;
let loadingMore = true;

const CalendarMulti = () => {
	const profile = React.useContext(AppContext).profile || {};
	const [allTags, setAllTags] = useState([]);
	const [tags, setTags] = useState([]);
	const [countries, setCountries] = useState([]);
	const [cities, setCities] = useState([]);
	const [country, setCountry] = useState("");
	const [city, setCity] = useState(null);
	const [unit, setUnit] = useState("");
	const [search, setSearch] = useState("");
	const [pageSize, setPageSize] = useState(20);
	const [page, setPage] = useState(1);
	const [listings, setListings] = useState([]);
	const [count, setCount] = useState(0);
	const [loading, setLoading] = useState(true);
	const [availableOnly, setAvailableOnly] = useState(false);
	const [isActive, setIsActive] = useState();
	const [openIds, setOpenIds] = useState([]);
	const [dateData, setDateData] = useState({});
	const scrollContainer = document.getElementById("scroll-container");
	const [timestamp, setTimestamp] = useState(Date.now());

	useEffect(() => {
		if (profile.readCalendar) {
			getListings({ pageSize });
			getTags();
		}
	}, [profile]);

	const getMore = async (page) => {
		if (loadingMore || loading || page * pageSize >= count) return;
		getListings({ page: page + 1 }, true);
	};

	const getListings = async (params = {}, loadMore) => {
		try {
			setLoading(true);
			if (!loadMore) {
				scrollContainer?.scroll(0, 0);
				setDateData((oldV) => {
					return {};
				});
				setListings((arr) => []);
			}

			loadingMore = true;
			let tags_ = tags;
			let country_ = country;
			let city_ = city;
			let unit_ = unit;
			let search_ = search;
			let pageSize_ = pageSize;
			let page_ = page;
			let availableOnly_ = availableOnly;
			let isActive_ = isActive;

			if (params.hasOwnProperty("tags")) {
				tags_ = params.tags;
				setTags((ar) => params.tags);
			}

			if (params.hasOwnProperty("country")) {
				country_ = params.country;
				setCountry(params.country);
			}

			if (params.hasOwnProperty("city")) {
				city_ = params.city;
				setCity(params.city);
			}

			if (params.hasOwnProperty("unit")) {
				unit_ = params.unit;
				setUnit(params.unit);
			}

			if (params.hasOwnProperty("search")) {
				search_ = params.search;
			}

			if (params.hasOwnProperty("pageSize")) {
				pageSize_ = params.pageSize;
				setPageSize(params.pageSize);
			}

			if (params.hasOwnProperty("availableOnly")) {
				availableOnly_ = params.availableOnly;
				setAvailableOnly(params.availableOnly);
			}

			if (params.hasOwnProperty("isActive")) {
				isActive_ = params.isActive;
				setIsActive(params.isActive);
			}

			if (params.hasOwnProperty("page")) {
				page_ = params.page;
				setPage(params.page);
			} else {
				page_ = 1;
				setPage(1);
			}

			let url = "";
			if (pageSize_) {
				url = url + "pageSize=" + pageSize_ + "&";
			}
			if (tags_) {
				url = url + "tags=" + tags_.join(",") + "&";
			}
			if (country_) {
				url = url + "country=" + country_ + "&";
			}
			if (city_) {
				url = url + "city=" + city_ + "&";
			}
			if (unit_) {
				url = url + "sortByUnit=" + unit_ + "&";
			}
			if (search_) {
				url = url + "search=" + search_ + "&";
			}
			if (availableOnly_) {
				url = url + "availableOnly=" + availableOnly_ + "&";
			}

			url = url + "active=" + isActive_ + "&";
			const listUrl = `/properties/propertiesList?page=${page_}&&includeCalendarData=true&${url}`;
			const res = await apiInstance.get(listUrl);
			setCount(res.data.propertyCount || 0);
			let list = res?.data?.propertiesList || [];
			list.forEach((li) => {
				setOpenIds((arr) => [...arr, li._id]);
			});
			if (loadMore) list = [...listings, ...list];

			setListings(list.filter((e) => e.appartmentIndex));
			setLoading(false);
			loadingMore = false;
		} catch (error) {}
	};

	const getTags = async () => {
		try {
			let url = `/properties/getTags`;
			const res = await apiInstance.get(url);
			setAllTags(res?.data?.tagsList[0].tags || []);
		} catch (error) {}
	};

	useEffect(() => {
		if (profile.readCalendar) {
			const apiUrl = `/properties/countryList`;
			apiInstance
				.get(apiUrl)
				.then((response) => {
					setCountries(response.data.data.countries);
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
		}
	}, [profile.readCalendar]);

	const getCities = (countryId) => {
		setCities((arr) => []);
		const apiUrl1 = `/properties/cityList/${countryId}`;
		apiInstance
			.get(apiUrl1)
			.then((response) => {
				setCities(response.data.data.cities);
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			});
	};

	const options = [];
	for (let i = 10; i < 36; i++) {
		options.push({
			value: i.toString(36) + i,
			label: i.toString(36) + i,
		});
	}

	const handleChange = (value) => {
		setTimestamp(Date.now());
		getListings({ tags: value });
	};
	let filterListings = listings;

	if (profile?._id && !profile.readCalendar) {
		return <NoAccess />;
	}

	return (
		<div className="bg-white p-5">
			<CalendarTopBar listings={listings} defaultActiveKey="1" />

			<div className="grid grid-cols-[2.5fr_1fr] items-start">
				<div className="grid grid-cols-6 gap-3 items-start">
					<GenericButton
						onClick={() => {
							setTimestamp(Date.now());
							if (availableOnly) {
								getListings({ availableOnly: false });
							} else {
								getListings({ availableOnly: true });
							}
						}}
						disabled={loading}
						label="Available listings"
						icon={availableOnly && <Close className="!h-4 !w-4" />}
						className="bg-buttonTheme hover:!bg-buttonHoverTheme disabled:bg-buttonTheme disabled:hover:!bg-buttonTheme disabled:opacity-50"
					/>

					<Select
						disabled={loading}
						mode="multiple"
						value={tags}
						allowClear={true}
						clearIcon={true}
						options={allTags.map((tagss) => {
							return { value: tagss.value, label: tagss.label };
						})}
						className="w-full shrink-0"
						placeholder="Tags"
						onChange={handleChange}
					/>

					<Select
						disabled={loading}
						showSearch={true}
						placeholder="Country"
						className=""
						options={countries.map((e) => {
							return {
								label: e.name,
								value: e.name,
								location: e.location,
								key: e.location,
							};
						})}
						onChange={(e, data) => {
							setTimestamp(Date.now());
							getListings({ country: e });
							if (data?.location) {
								getCities(data?.location);
							} else {
								setCities((arr) => []);
							}
							setCity(null);
						}}
						filterOption={(input, option) => {
							return (
								(option?.value || "")
									.toLowerCase()
									.indexOf(input?.toLowerCase()) >= 0
							);
						}}
						onClear={() => {
							getListings({ country: "" });
							setCity(null);
						}}
						allowClear={true}
					/>
					<Select
						disabled={loading || cities.length === 0}
						showSearch={true}
						placeholder="City"
						allowClear={true}
						value={city}
						filterOption={(input, option) => {
							return (
								(option?.value || "")
									.toLowerCase()
									.indexOf(input?.toLowerCase()) >= 0
							);
						}}
						onClear={() => {
							setTimestamp(Date.now());
							setCity(null);
							getListings({ city: "" });
						}}
						options={cities.map((e) => {
							return { label: e.name, value: e.name, key: e.location };
						})}
						onChange={(e) => {
							setTimestamp(Date.now());
							getListings({ city: e });
						}}
					/>

					<div className="">
						<Select
							disabled={loading}
							allowClear
							className="w-full"
							placeholder="Unit Type"
							defaultValue="all"
							value={unit || "Unit Type"}
							onChange={(e) => {
								setTimestamp(Date.now());
								getListings({ unit: e });
							}}
							// onChange={(e) => setUnit(e)}
							options={unitTypeList}
						/>
					</div>

					<div className="">
						<Select
							disabled={loading}
							allowClear
							className="w-full"
							placeholder="isActive"
							value={isActive}
							onChange={(e) => {
								setTimestamp(Date.now());
								getListings({ isActive: e });
							}}
							options={[
								{ label: "Active", value: true },
								{ label: "Inactive", value: false },
							]}
						/>
					</div>
				</div>
				<div className="flex gap-3 place-self-end">
					<div className="mt-0">
						<Search
							value={search}
							disabled={loading}
							onChange={(e) => {
								setSearch(e.target.value);
								if (timeOut) {
									clearTimeout(timeOut);
								}
								timeOut = setTimeout(() => {
									setTimestamp(Date.now());
									getListings({ search: e.target.value });
								}, 1000);
							}}
							placeholder="Search by listing"
						/>
					</div>
				</div>
			</div>

			<BottomScrollListener
				onBottom={() => {
					getMore(page);
				}}
				offset={500}
				debounce={300}
			>
				{(scrollRef) => (
					<div
						ref={scrollRef}
						className="mt-2 h-[70vh] overflow-y-scroll"
						id="scroll-container"
					>
						{!loading && listings.length === 0 ? (
							<ListingNotFound />
						) : (
							<MultiCalendar
								listings={filterListings}
								loading={loading}
								openIds={openIds}
								setOpenIds={setOpenIds}
								dateData={dateData}
								setDateData={setDateData}
								timestamp={timestamp}
							/>
						)}
					</div>
				)}
			</BottomScrollListener>
		</div>
	);
};

export default CalendarMulti;
