import {
	addDays,
	addMonths,
	differenceInDays,
	endOfDay,
	isSameDay,
	startOfDay,
} from "date-fns";
import { formatDate } from "../../../utils/dateHelper";
import { range } from "../../../utils/helper";

function getNext(date, list) {
	let item = list.find(
		(r) =>
			endOfDay(new Date(r.guest.checkOutDate)) > date &&
			startOfDay(new Date(r.guest.checkInDate)) < date
	);
	if (!item)
		item = list.find((r) => startOfDay(new Date(r.guest.checkInDate)) >= date);

	if (item) {
		item = { ...item };
		delete item.reservations;

		const checkOutDate = endOfDay(new Date(item.guest.checkOutDate));
		let checkInDate = startOfDay(new Date(item.guest.checkInDate));

		if (date > checkInDate) checkInDate = date;

		item.checkInDate_ = checkInDate;
		item.checkOutDate_ = checkOutDate;

		let next_ = list.find(
			(r) =>
				r.reservationId !== item.reservationId &&
				startOfDay(new Date(r.guest.checkInDate)) >= checkInDate
		);

		if (next_?.reservationId === item.reservationId) next_ = null;
		if (next_) {
			let nextList = list.filter(
				(r) =>
					r.reservationId !== item.reservationId &&
					startOfDay(new Date(r.guest.checkInDate)) <= checkInDate &&
					endOfDay(new Date(r.guest.checkOutDate)) >= checkInDate
			);

			// let nextList = list.filter(r => r.reservationId !== item.reservationId && isSameDay(new Date(r.guest.checkInDate), new Date(next_.guest.checkInDate)))
			nextList = nextList.sort((a, b) =>
				startOfDay(new Date(a.guest.checkOutDate)) <
				startOfDay(new Date(b.guest.checkOutDate))
					? -1
					: 1
			);
			next_ = { ...next_ };
			let checkOutDateNext = endOfDay(
				new Date((nextList[0] || next_).guest?.checkOutDate)
			);
			const checkInDateNext = startOfDay(new Date(next_.guest.checkInDate));

			let nextCheckInDate = null;
			list.forEach((nl) => {
				const nlDate = startOfDay(new Date(nl.guest.checkInDate));
				// console.log(nlDate)
				if (nextCheckInDate == null && nlDate > checkInDateNext) {
					nextCheckInDate = nlDate;
				}
			});

			// console.log(checkInDate, checkInDateNext, nextCheckInDate, checkOutDateNext)
			if (nextCheckInDate !== null && nextCheckInDate < checkOutDateNext)
				checkOutDateNext = endOfDay(addDays(nextCheckInDate, -1));
			// console.log(checkInDate, checkInDateNext, nextCheckInDate, checkOutDateNext)

			let parallelResercations = list.filter(
				(r) =>
					startOfDay(new Date(r.guest.checkInDate)) <= checkInDate &&
					endOfDay(new Date(r.guest.checkOutDate)) >= checkInDate
			);
			const ids = parallelResercations.map((e) => e.reservationId);
			const chennels = parallelResercations.map((e) => e.channel || "PMS");

			next_.checkInDate_ = checkInDateNext;
			next_.checkOutDate_ = checkOutDateNext;

			if (isSameDay(checkInDateNext, checkInDate)) {
				if (checkOutDate > checkOutDateNext) {
					item.checkOutDate_ = checkOutDateNext;
				} else {
					item.checkOutDate_ = checkOutDate;
				}
				item.reservations = ids;
				item.reservationChannels = chennels;

				return item;
			} else if (checkInDateNext < checkOutDate) {
				item = { ...item };
				item.checkOutDate_ = endOfDay(addDays(checkInDateNext, -1));
				item.reservations = ids;
				item.reservationChannels = chennels;

				let parallelResercations2 = list.filter(
					(r) =>
						startOfDay(new Date(r.guest.checkInDate)) <= checkInDateNext &&
						endOfDay(new Date(r.guest.checkOutDate)) >= checkInDateNext
				);
				const ids2 = parallelResercations2.map((e) => e.reservationId);
				const chennels2 = parallelResercations2.map((e) => e.channel || "PMS");

				next_.reservations = ids2;
				next_.reservationChannels = chennels2;
				if (ids2.length > 1) {
					next_.checkOutDate_ = checkOutDateNext;
				} else {
					if (checkOutDate > checkOutDateNext) {
						next_.checkOutDate_ = checkOutDateNext;
					} else {
						next_.checkOutDate_ = checkOutDate;
					}
				}
				return [item, next_];
			} else {
			}
		} else {
			let parallelResercations = list.filter(
				(r) =>
					startOfDay(new Date(r.guest.checkInDate)) <= checkInDate &&
					endOfDay(new Date(r.guest.checkOutDate)) >= checkInDate
			);
			const ids = parallelResercations.map((e) => e.reservationId);
			const chennels = parallelResercations.map((e) => e.channel || "PMS");

			item.reservations = ids;
			item.reservationChannels = chennels;
		}
	}
	return item;
}
export const prepareCalendarData = (
	startDate_,
	endDate_,
	reservations,
	dates,
	returnBoth,
	// minStay
	unit = null,
	numberOfUnits = null,
	reservationsCount = null
) => {
	const allReservations = [...reservations];
	if (!!unit) reservations = reservations.filter((r) => r.unitNo === unit);
	const startDate = startOfDay(startDate_);
	const endDate = endOfDay(endDate_);
	try {
		const jsonDate = {};
		const reservationList = [];
		let nextDate = !reservations[0]?.guest?.checkInDate
			? null
			: startOfDay(new Date(reservations[0]?.guest?.checkInDate));
		while (nextDate !== null) {
			const next = getNext(nextDate, reservations);
			if (next && Array.isArray(next)) {
				nextDate = startOfDay(addDays(next[1].checkOutDate_, 1));
				reservationList.push({ ...next[0] });
				reservationList.push({ ...next[1] });
			} else if (next) {
				nextDate = startOfDay(addDays(next.checkOutDate_, 1));
				reservationList.push({ ...next });
			} else {
				nextDate = null;
			}
		}

		reservationList.forEach((resrv) => {
			if (resrv.checkOutDate_ < startDate) return;
			const guest = resrv.guest;
			if (guest) {
				let checkInDate = resrv.checkInDate_;
				if (startDate > checkInDate) checkInDate = startDate;
				let size = differenceInDays(resrv.checkOutDate_, checkInDate) + 1;

				const data = {
					start: formatDate(checkInDate, "yyyy-MM-dd"), //guest.checkInDate,
					date: checkInDate,
					endDate: resrv.checkOutDate_,
					// price: resrv.price,
					size: size,
					reservations: resrv.reservations,
					reservationChannels: resrv.reservationChannels || [resrv.channel],
					reservation: {
						name: `${guest.guestFirstName || ""} ${guest.guestLastName || ""} `,
						listingId: resrv.listingId,
						reservationId: resrv.reservationId,
						numberOfGuest: guest.guestCount,
						unitNo: resrv.unitNo,
					},
				};
				jsonDate[data.start] = data;
			}
		});

		dates.forEach((date) => {
			const date_ = date.date;
			let d = new Date(date_);
			if (!hasAdded(jsonDate, date_)) {
				let count = 0;
				if (!date.isAvailable) {
					while (
						!!dates.find(
							(e) =>
								e.isAvailable === date.isAvailable &&
								isSameDay(new Date(e.date), d)
						)
					) {
						d = addDays(d, 1);
						count = count + 1;
					}
				}

				const allUnitsReservations = range(1, numberOfUnits, 1)
					.map((i) => {
						const res = allReservations.filter((r) => {
							return (
								r.unitNo === i &&
								new Date(r.checkInDate) <= new Date(date_) &&
								new Date(r.checkOutDate) >= new Date(date_)
							);
						});

						if (res.length > 0) return i;
						return null;
					})
					.filter((i) => i !== null);

				const isBlocked =
					!date.isAvailable ||
					(unit &&
						numberOfUnits &&
						!allUnitsReservations.includes(unit) &&
						date.blockedUnits &&
						date.blockedUnits +
							unit +
							allUnitsReservations.filter((i) => i > unit).length >
							numberOfUnits);

				jsonDate[date_] = {
					start: date_,
					date: new Date(date_),
					size: count || 1,
					endDate:
						count === 0 ? new Date(date_) : addDays(new Date(date_), count - 1),
					price: date.price,
					isAvailable: !isBlocked,
					isBlocked: isBlocked,
					listingId: date.listingId,
					note: date.note,
					minimumNights: date.minimumNights,
					blockedUnits: date.blockedUnits,
				};
			}
		});

		const allDates = getMultyCalendarDates(startDate, endDate);

		allDates.forEach((date) => {
			const date_ = date.date;
			if (!hasAdded(jsonDate, date_)) {
				jsonDate[date_] = {
					start: date_,
					date: new Date(date_),
					endDate: new Date(date_),
					size: 1,
					price: 0,
					isAvailable: false,
				};
			}
		});

		// if (unit === 0) {
		//   allDates.forEach((date, i) => {
		//     const date_ = date.date;

		//     let count = 0;
		//     const ids = [];
		//     const allIds = [];
		//     const dateObj = jsonDate[date_] || {};

		//     unitList.forEach((list, i) => {
		//       const resev = list.find(
		//         (r) =>
		//           r.reservation &&
		//           r.reservation.unitNo === i + 1 &&
		//           (r.start === date_ ||
		//             (new Date(date_) > r.date && new Date(date_) <= r.endDate))
		//       );
		//       if (resev) {
		//         if (resev.reservations)
		//           count = count + 1; //resev.reservations.length
		//         else count = count + 1;
		//       } else {
		//         if (dateObj.blockedUnits && ids.length < dateObj.blockedUnits)
		//           ids.push(i);
		//       }
		//       allIds.push(i);
		//     });

		//     if (dateObj.isBlocked) {
		//       dateObj.blockedIndexs = allIds;
		//     } else {
		//       dateObj.blockedIndexs = ids;
		//     }
		//     jsonDate[date_] = dateObj;
		//     jsonDate[date_].count = count;
		//   });
		// }

		let array = [];
		for (const value of Object.entries(jsonDate)) {
			const item = value[1];
			if (item.date >= startDate && item.date <= endDate) array.push(item);
			else {
			}
		}

		if (reservationsCount)
			for (let i = 0; i < array.length; i++) {
				const item = array[i];
				const reservationsLength = reservationsCount.filter((i) => {
					return (
						new Date(i.checkInDate) <= new Date(item.date) &&
						new Date(i.checkOutDate) >= new Date(item.date)
					);
				}).length;
				array[i].count = reservationsLength;
			}

		array = array.sort((a, b) => (a.date > b.date ? 1 : -1));
		if (returnBoth) return { json: jsonDate, array };
		return array;
	} catch (e) {
		console.log(e);
		if (returnBoth) return { json: {}, array: [] };
		return [];
	}
};

function hasAdded(json, date) {
	let has = false;
	const d_ = new Date(date);

	for (const value of Object.entries(json)) {
		const d = new Date(value[1].start);
		const d2 = addDays(d, value[1].size);

		if (
			differenceInDays(d, d_) === 0 ||
			(differenceInDays(d, d_) <= 0 && differenceInDays(d2, d_) > 0)
		) {
			has = true;
			break;
		}
	}
	return has;
}
export const getMonths = (year) => {
	const months = [];
	let date = new Date(`01 01 ${year}`);
	range(1, 12, 1).forEach((e) => {
		months.push({ text: formatDate(date, "MMMM"), date: date });
		date = addMonths(date, 1);
	});
	return months;
};
export const getMultyCalendarDates = (startDate, endDate) => {
	let date = startDate;
	const dates = [];
	let end = differenceInDays(endOfDay(endDate), startOfDay(startDate)) + 1;

	range(1, end, 1).forEach((e) => {
		dates.push({
			text: formatDate(date, "EEE dd MMM"),
			date: formatDate(date, "yyyy-MM-dd"),
		});
		date = addDays(date, 1);
	});

	return dates;
};
