/* eslint-disable react/jsx-key */
import {
	// CalendarOutlined,
	DownOutlined,
	// LeftOutlined,
	// RightOutlined,
	UpOutlined,
} from "@ant-design/icons";
import { DatePicker, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import BlockListingCalendar from "./BlockListingCalendar";
import ReservationInfoModal from "./ReservationInfoModal";
import { Lock } from "@mui/icons-material";
import { addDays, differenceInDays, isSameDay } from "date-fns";
import { getMultyCalendarDates, prepareCalendarData } from "./helper";
import { getCurrencySymbol, range } from "../../../utils/helper";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { CALENDAR_MONTHLY } from "../../../constants/dashboardRoutes";
import { formatDate } from "../../../utils/dateHelper";
import { apiInstance } from "../../../utils/API";
import { getLogoPath } from "../../../utils/constants";
import { Icon } from "@iconify-icon/react";

const { RangePicker } = DatePicker;

const MultiCalendar = ({
	listings,
	loading,
	openIds,
	setOpenIds,
	dateData,
	setDateData,
	timestamp,
}) => {
	// const [month, setMonth] = useState(null)
	const containerRef = useRef(null);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [realStartDate, setRealStartDate] = useState(null);
	const [realEndDate, setRealEndDate] = useState(null);
	const [rawData, setRawData] = useState([]);
	const [dates, setDates] = useState([]);
	const [dateLoading, setDateLoading] = useState(true);
	const [chunksWithIds, setChunkswithIds] = useState([]);
	const barRef = useRef(null);

	useEffect(() => {
		setStartDate(addDays(new Date(), -1));
		setEndDate(addDays(new Date(), 25));
	}, [timestamp]);

	useEffect(() => {
		if (startDate && endDate)
			setDates((arr) => [...getMultyCalendarDates(startDate, endDate)]);
		if (listings.length > 0 && startDate && endDate) {
			if (!isSameDay(startDate, endDate)) {
				const ids = listings.map((e) => e.appartmentIndex).filter((e) => e);
				getData(ids, startDate, endDate);
			}
		} else {
			setDateData((oldV) => {
				return {};
			});
		}
	}, [startDate, endDate]);

	useEffect(() => {
		if (listings.length > 0 && startDate && endDate) {
			if (!isSameDay(startDate, endDate)) {
				const ids = listings.map((e) => e.appartmentIndex).filter((e) => e);
				const newIds = [];
				ids.forEach((id) => {
					if (!dateData[id] || dateData[id].length === 0) newIds.push(id);
				});
				getData(newIds, startDate, endDate);
			}
		} else {
			setDateData((oldV) => {
				return {};
			});
		}
	}, [listings]);

	const handleScroll = () => {
		const container = containerRef.current;
		barRef.current.scrollLeft = container.scrollLeft;
		if (container.scrollLeft === 0) {
			if (!dateLoading) {
				setStartDate(addDays(startDate, -15));
			}
		} else if (
			container.scrollLeft + container.clientWidth >=
			container.scrollWidth
		) {
			if (!dateLoading) {
				setEndDate(addDays(endDate, 15));
			}
		}
	};

	const getData = async (allIds, start, end) => {
		setDateLoading(true);

		try {
			const chunkSize = 10;
			const chunks = [];

			for (let i = 0; i < allIds.length; i += chunkSize) {
				const chunk = allIds.slice(i, i + chunkSize);
				chunks.push(chunk);
			}
			for (const ids of chunks) {
				let sd =
					realStartDate === null
						? start
						: start === realStartDate && end > realEndDate
						? addDays(realEndDate, 1)
						: start;
				let ed =
					realEndDate === null
						? end
						: end === realEndDate && start < realStartDate
						? addDays(realStartDate, -1)
						: end;

				for (let id of ids) {
					if (!chunksWithIds.includes(id)) {
						sd = startDate;
						ed = endDate;
						setChunkswithIds((prev) => {
							return [...prev, id];
						});
					}
				}

				const res = await apiInstance.get(
					`/api/calendar/listing?lids=${ids.join(
						","
					)}&startDate=${sd}&endDate=${ed}`
				);
				const data = res.data || {};
				const newData = {};
				listings.forEach((l) => {
					if (ids.includes(l.appartmentIndex)) {
						const noOfUnits = parseInt("" + (l?.number_of_units || "1"));
						if (noOfUnits === 1) {
							let previousRawDataReservations = rawData.map(
								(e) => e?.[l.appartmentIndex]?.reservations || []
							);
							let previousRawDataDates = rawData.map(
								(e) => e?.[l.appartmentIndex]?.dates || []
							);

							newData[l.appartmentIndex] = prepareCalendarData(
								startDate,
								endDate,
								[
									...previousRawDataReservations.flat(),
									...(data[l.appartmentIndex]?.reservations || []),
								].filter((value, index, array) => {
									return (
										array.findIndex(
											(e) => e.reservationId === value.reservationId
										) === index
									);
								}),
								[
									...previousRawDataDates.flat(),
									...(data[l.appartmentIndex]?.dates || []),
								].filter((value, index, array) => {
									return (
										array.findIndex((e) => e.date === value.date) === index
									);
								}),
								false,
								null,
								null,
								null
							);
						} else {
							const unitList = [];
							const previousRawDataDates = rawData.map(
								(e) => e?.[l.appartmentIndex]?.dates || []
							);
							range(1, noOfUnits, 1).forEach((no) => {
								let previousRawDataReservations = rawData.map((e) => {
									return e?.[l.appartmentIndex]?.reservations || [];
								});

								let list = prepareCalendarData(
									startDate,
									endDate,
									[
										...previousRawDataReservations.flat(),
										...(data[l.appartmentIndex]?.reservations || []),
									].filter((value, index, array) => {
										return (
											array.findIndex(
												(e) => e.reservationId === value.reservationId
											) === index
										);
									}),
									[
										...previousRawDataDates.flat(),
										...(data[l.appartmentIndex]?.dates || []),
									].filter((value, index, array) => {
										return (
											array.findIndex((e) => e.date === value.date) === index
										);
									}),
									false,
									no,
									noOfUnits,
									null
								);
								newData[l.appartmentIndex + "_" + no] = list;
								unitList.push(list);
							});
							const previousRawDataReservations = rawData.map(
								(e) => e?.[l.appartmentIndex]?.reservations || []
							);
							newData[l.appartmentIndex] = prepareCalendarData(
								startDate,
								endDate,
								[],
								[
									...previousRawDataDates.flat(),
									...(data[l.appartmentIndex]?.dates || []),
								].filter((value, index, array) => {
									return (
										array.findIndex((e) => e.date === value.date) === index
									);
								}),
								false,
								null,
								null,
								[
									...previousRawDataReservations.flat(),
									...(data[l.appartmentIndex]?.reservations || []),
								].filter((value, index, array) => {
									return (
										array.findIndex(
											(e) => e.reservationId === value.reservationId
										) === index
									);
								})
							);
						}
					}
				});

				setDateData((oldData) => {
					return { ...oldData, ...newData };
				});
				setRawData((oldData) => {
					return [...oldData, data];
				});
				setDateLoading(false);
				setRealStartDate(start);
				setRealEndDate(end);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const [listView, setListView] = useState("normal");

	const onBlockSave = (listingId) => {
		getData([listingId], startDate, endDate);
	};

	return (
		<>
			<div className="flex relative ">
				<div className="w-64" id="cal-listings">
					<div
						className={`h-[40px] flex items-center justify-between sticky z-[200] top-0`}
					>
						<div className="flex items-center justify-between w-[260px] gap-4">
							<div className="flex gap-2 items-center text-themeSecondaryGreen">
								<RangePicker
									className="border-[#dfe1e6] hover:border-[#dfe1e6] shadow-none focus:shadow-none"
									size="small"
									disabled={dateLoading}
									format="DD MMM YY"
									value={[dayjs(startDate), dayjs(endDate)]}
									onChange={(range) => {
										if (range) {
											const valueOfInput1 = range[0];
											const valueOfInput2 = range[1];
											setStartDate(valueOfInput1.toDate());
											setEndDate(valueOfInput2.toDate());
										}
									}}
								/>
							</div>
						</div>
					</div>
					<div className="">
						{listings.map((e, i) => {
							return (
								<ListingItem
									e={e}
									i={i}
									key={i}
									listView={listView}
									openIds={openIds}
									setOpenIds={setOpenIds}
									startDate={startDate}
								/>
							);
						})}
					</div>

					{loading && (
						<div className="text-center text-lg pt-4">Loading....</div>
					)}
				</div>
				<div className="w-[calc(100%-16rem)]">
					<div className="fixed flex items-center top-26 right-8 z-[300] cursor-pointer">
						<div
							onClick={() => {
								setListView("normal");
							}}
							className={`${
								listView === "normal" ? "bg-gray-500" : "bg-gray-400 "
							} text-white  p-1 flex items-center justify-center rounded-s-[3px]`}
						>
							<Icon icon="icons8:align-justify" height={24} width={24} />
						</div>
						<div
							onClick={() => {
								setListView("addSpace");
							}}
							className={`${
								listView === "addSpace" ? "bg-gray-500" : "bg-gray-400 "
							}  text-white p-1 flex items-center justify-center rounded-e-[3px]`}
						>
							<Icon
								icon="fluent:text-align-justify-20-regular"
								height={24}
								width={24}
							/>
						</div>
					</div>
					<div
						className={`pl-4 sticky z-[200] top-0 overflow-x-scroll no-scrollbar bg-white`}
						ref={barRef}
						onScroll={(scroll) => {
							containerRef.current.scrollLeft = scroll.target.scrollLeft;
						}}
					>
						<div className="h-[40px] bg-white flex items-center">
							{dates.map((item, i) => {
								const today = isSameDay(new Date(item.date), new Date());
								const isPast =
									differenceInDays(new Date(item.date), new Date()) + 1 < 1;
								return (
									<div
										key={i}
										className={`${
											isPast
												? "bg-[#e8eaf375] border-x-[#e8eaf375]"
												: "border-x-white"
										} relative w-[96px] h-[41px] shrink-0 text-[#515b65] text-xs font-semibold px-2 mb-2 border-b border-b-[#e2e3e5] flex items-center `}
									>
										{today && (
											<div
												className={`absolute w-[1px] -left-4 inset-y-0 ${
													today ? "bg-[#57b5b5]" : "bg-gray-300"
												} z-10`}
											/>
										)}
										{item.text}
									</div>
								);
							})}
						</div>
					</div>
					<div
						className="overflow-y-auto"
						ref={containerRef}
						onScroll={handleScroll}
					>
						<div className="pl-4 listing-data bg-[#FAFBFC]" id="">
							{/* this is the top bar that displays the dates on multi calendars  */}

							{listings.map((e, i) => {
								return (
									<Row
										e={e}
										key={i}
										i={i}
										listView={listView}
										startDate={startDate}
										dateData={dateData}
										openIds={openIds}
										onBlockSave={onBlockSave}
										total={dates.length}
										endDate={endDate}
									/>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MultiCalendar;

const Row = ({
	e,
	i,
	listView,
	dateData,
	openIds,
	onBlockSave,
	total,
	startDate,
	endDate,
}) => {
	const [isReservationOpen, setIsReservationOpen] = useState(false);
	const [reservationId, setReservationId] = useState("");
	const [listingId, setListingId] = useState("");
	const [modalListingData, setModalListingdata] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const noOfUnits = parseInt("" + e?.number_of_units);
	const hasUnits = noOfUnits > 1;

	const daysDifference =
		differenceInDays(
			endDate,
			dateData[e.appartmentIndex]?.[dateData[e.appartmentIndex]?.length - 1]
				?.date
		) || 0;

	return (
		<div key={i} className="font-open-sans">
			{isModalOpen && (
				<BlockListingCalendar
					modalListingData={modalListingData}
					setModalListingdata={setModalListingdata}
					listingId={listingId}
					isModalOpen={isModalOpen}
					setIsModalOpen={setIsModalOpen}
					onBlockSave={onBlockSave}
				/>
			)}
			{isReservationOpen && (
				<ReservationInfoModal
					reservationId={reservationId}
					isModalOpen={isReservationOpen}
					listing={e}
					setIsModalOpen={setIsReservationOpen}
				/>
			)}

			{hasUnits ? (
				<div className="">
					{(!dateData[e.appartmentIndex] ||
						dateData[e.appartmentIndex].length === 0) && (
						<PlaceHolder listView={listView} />
					)}
					<div className={`relative flex`}>
						{dateData[e.appartmentIndex]?.map((item, index) => {
							return (
								<RowCellPrice
									hasUnits={hasUnits}
									listView={listView}
									indexN={differenceInDays(item.date, startDate)}
									noOfUnits={noOfUnits}
									item={item}
									index={index}
									key={index}
									setModalListingdata={setModalListingdata}
									e={e}
									total={total}
									setReservationId={setReservationId}
									setIsReservationOpen={setIsReservationOpen}
									setListingId={setListingId}
									setIsModalOpen={setIsModalOpen}
									list={dateData[e.appartmentIndex]}
								/>
							);
						})}
						{daysDifference > 0 &&
							range(1, daysDifference, 1).map(() => {
								return (
									<div role="status" className=" animate-pulse">
										<div
											className={`${
												listView === "addSpace"
													? "h-[44px] mb-4"
													: "h-[41px] mb-1"
											}  w-[95px] bg-gray-200 rounded-md text-[13px] -skew-x-[20deg] ml-[1px]`}
										></div>
									</div>
								);
							})}
					</div>
					{openIds.includes(e._id) && (
						<div className="">
							{range(1, noOfUnits, 1).map((no, idx) => {
								const unitRows = dateData[e.appartmentIndex + "_" + no];
								return (
									<div className={`flex`} key={idx}>
										{(!unitRows || unitRows.length === 0) && (
											<PlaceHolder listView={listView} />
										)}

										{unitRows?.map((item, index) => {
											let pItem = null;
											if (dateData[e.appartmentIndex])
												pItem = dateData[e.appartmentIndex].find(
													(pi) => pi.start === item.start
												);

											return (
												<RowCell
													hasUnits={hasUnits}
													listView={listView}
													item={item}
													rowIndex={no - 1}
													pItem={pItem}
													index={index}
													indexN={differenceInDays(item.date, startDate)}
													key={index}
													setModalListingdata={setModalListingdata}
													e={e}
													total={total}
													setReservationId={setReservationId}
													setIsReservationOpen={setIsReservationOpen}
													setListingId={setListingId}
													setIsModalOpen={setIsModalOpen}
												/>
											);
										})}
										{daysDifference > 0 &&
											range(1, daysDifference, 1).map(() => {
												return (
													<div role="status" className=" animate-pulse">
														<div
															className={`${
																listView === "addSpace"
																	? "h-[44px] mb-4"
																	: "h-[41px] mb-1"
															}  w-[95px] bg-gray-200 rounded-md text-[13px] -skew-x-[20deg] ml-[1px]`}
														></div>
													</div>
												);
											})}
									</div>
								);
							})}
						</div>
					)}
				</div>
			) : (
				<div className={`flex items-center cursor-pointer`}>
					{(!dateData[e.appartmentIndex] ||
						dateData[e.appartmentIndex].length === 0) && (
						<PlaceHolder listView={listView} />
					)}
					{dateData[e.appartmentIndex]?.map((item, index) => {
						return (
							<RowCell
								listView={listView}
								item={item}
								index={index}
								indexN={differenceInDays(item.date, startDate)}
								key={index}
								setModalListingdata={setModalListingdata}
								e={e}
								total={total}
								setReservationId={setReservationId}
								setIsReservationOpen={setIsReservationOpen}
								setListingId={setListingId}
								setIsModalOpen={setIsModalOpen}
							/>
						);
					})}
					{daysDifference > 0 &&
						range(1, daysDifference, 1).map(() => {
							return (
								<div role="status" className=" animate-pulse">
									<div
										className={`${
											listView === "addSpace"
												? "h-[44px] mb-4"
												: "h-[41px] mb-1"
										}  w-[95px] bg-gray-200 rounded-md text-[13px] -skew-x-[20deg] ml-[1px]`}
									></div>
								</div>
							);
						})}
				</div>
			)}
		</div>
	);
};

const PlaceHolder = ({ listView }) => {
	return (
		<div role="status" className=" animate-pulse w-full">
			<div
				className={`${
					listView === "addSpace" ? "h-[44px] mb-4" : "h-[41px] mb-1"
				} w-full bg-gray-200 rounded-md`}
			></div>
		</div>
	);
};
const RowCell = ({
	hasUnits,
	listView,
	item,
	pItem,
	index,
	indexN,
	rowIndex,
	setModalListingdata,
	setReservationId,
	setIsReservationOpen,
	setListingId,
	setIsModalOpen,
	e,
	total,
}) => {
	let className = ` ${
		listView === "addSpace" ? "h-[44px] mb-4" : "h-[41px] mb-1"
	} relative z-[9] capitalize shrink-0 line-clamp-1 font-bold text-[#788999] text-[13px] -ml-[1px] cursor-poniter  -skew-x-[20deg] flex flex-col items-center justify-center `;
	let isReserved = !!item.reservation;
	let isBlocked = !item.isAvailable;
	let isAvailable = item.isAvailable && !hasUnits;
	let seprate = false;
	if (
		item.isAvailable & hasUnits &&
		!isReserved &&
		pItem &&
		pItem.blockedIndexs?.includes(rowIndex)
	) {
		isBlocked = true;
		seprate = true;
	}

	const isPast = differenceInDays(item.date, new Date()) + 1 < 1;
	const today = isSameDay(item.date, new Date());

	if (item.reservations && item.reservations.length > 1)
		className = className + "isReserved-over-listing";
	else if (isReserved) className = className + "isReserved-listing";
	else if (isBlocked) className = className + "isBlocked-listing";
	else if (isAvailable && isPast)
		className = className + "isAvailable-past-listing";
	else if (isAvailable) className = className + "isAvailable-listing";
	else if (hasUnits) className = className + "";
	else className = className + "default-listing";
	let size = 1;
	let width = `${93}px`;
	if (isReserved || isBlocked) {
		size = item.size;
		if (indexN + size > total) size = total - indexN;
		if (size === 1) width = `93px`;
		else width = `${93 * size + size * 3 - 3}px`;
	}
	if (
		item.isAvailable &&
		hasUnits &&
		isBlocked &&
		pItem?.blockedIndexs?.includes(rowIndex)
	) {
		size = 1;
		width = `${93}px`;
	}

	const minimumNights = item?.minimumNights || 0;

	try {
		return (
			<div
				className="relative px-[2px]"
				key={index}
				onClick={() => {
					if (isReserved) {
						setReservationId(
							item.reservations || item?.reservation?.reservationId
						);
						setIsReservationOpen(true);
					} else {
						if (!hasUnits) {
							const data = {
								startDate: item.date,
								endDate: item.endDate,
								listingId: e.appartmentIndex,
								price: item.price,
								note: item.note,
								minimumNights,
								isAvailable: item.isAvailable,
								hasUnits: false,
							};
							setModalListingdata(data);
							setListingId(e.appartmentIndex);
							setIsModalOpen(true);
						}
					}
				}}
			>
				<div
					className={`absolute inset-0 inset-y-0 ${
						isPast ? "bg-[#e8eaf375]" : ""
					}  `}
				/>
				<div
					className={`absolute -left-4 w-[1px] bottom-0 -top-1 ${
						today ? "bg-[#57b5b5]" : "bg-[#e2e3e5]"
					} z-10 `}
				/>
				<div className={className} style={{ width: width }}>
					{item.isAvailable && !hasUnits && (
						<span>
							{getCurrencySymbol(e?.currency)} {item.price}
						</span>
					)}
					{item.reservation && (
						<div className="w-full flex items-center  font-semibold px-4 skew-x-[20deg] whitespace-pre">
							{item.reservationChannels &&
								item.reservationChannels.length > 0 &&
								item.reservationChannels.map((ch, idx) => {
									return (
										<img
											src={getLogoPath(ch)}
											alt={ch}
											height={100}
											width={100}
											key={idx}
											className={` inline-block ${
												idx > 0 ? "-ml-[0.65em]" : ""
											}  h-[25px] w-[25px] object-contain border border-white rounded-full`}
										/>
									);
								})}

							{size > 1 &&
								(item.reservationChannels &&
								item.reservationChannels.length > 1 ? (
									<div className="inline-block leading-[0] ml-1">
										{[...new Set(item.reservationChannels || [])].join(", ")}
									</div>
								) : (
									<div className="inline-block leading-[0] ml-1">
										{item.reservation.name} - {item.reservation.numberOfGuest}{" "}
										guest
									</div>
								))}
						</div>
					)}
					{!seprate && !item.reservation && isBlocked && (
						<div
							className={`skew-x-[20deg] w-full flex ${
								item.size > 1 ? "justify-between" : "justify-center"
							}  text-gray-500 px-4`}
						>
							<span>
								<Lock className="!h-[15px] !w-[15px] text-[grey]" />
							</span>{" "}
							{item.size > 1 && (
								<span>
									<Lock className="!h-[15px] !w-[15px] text-[grey]" />
								</span>
							)}
						</div>
					)}
					{seprate && (
						<div
							className={`w-full flex justify-center text-gray-500 px-4 skew-x-[20deg]`}
						>
							<span>
								<Lock className="!h-[15px] !w-[15px] text-[grey]" />
							</span>
						</div>
					)}

					{listView === "addSpace" && !isBlocked && !hasUnits && (
						<div className="text-[10px] font-normal lowercase">
							{minimumNights ? `${minimumNights} nights min` : ""}
						</div>
					)}
				</div>
			</div>
		);
	} catch (e) {
		console.log(e);
		return <>{e.message}</>;
	}
};

const RowCellPrice = ({
	hasUnits,
	listView,
	noOfUnits,
	item,
	index,
	indexN,
	setModalListingdata,
	setReservationId,
	setIsReservationOpen,
	setListingId,
	setIsModalOpen,
	e,
	total,
	list,
}) => {
	let className = `${
		listView === "addSpace" ? "h-[44px] mb-4" : "h-[41px] mb-1"
	}  relative z-[9] overflow-hidden shrink-0 relative font-bold text-[#788999] text-[13px] -ml-[1px] cursor-poniter  -skew-x-[20deg] flex flex-col items-center justify-center `;
	let isReserved = !!item.reservation;
	let isBlocked = !item.isAvailable;
	let isAvailable = item.isAvailable;
	const isPast = differenceInDays(item.date, new Date()) + 1 < 1;
	const today = isSameDay(item.date, new Date());

	if (isReserved) className = className + "isReserved-listing";
	else if (isBlocked) className = className + "isBlocked-listing";
	else if (isAvailable && isPast)
		className = className + "isAvailable-past-listing";
	else if (isAvailable) className = className + "isAvailable-listing";
	else className = className + "default-listing";

	let percent = ((item.count || 0) / noOfUnits) * 100;
	if (percent > 100) percent = 100;
	let percent2 = ((item.blockedUnits || 0) / noOfUnits) * 100;
	if (percent2 > 100) percent2 = 100;

	let size = 1;
	let width = `${93}px`;
	if (isReserved || isBlocked) {
		size = item.size;
		if (indexN + size > total) size = total - indexN;
		if (size === 1) width = `93px`;
		else width = `${93 * size + size * 3 - 3}px`;
	}

	const minimumNights = item?.minimumNights || 0;

	try {
		return (
			<div
				className="relative px-[2px]"
				key={index}
				onClick={() => {
					if (isReserved) {
						setReservationId(
							item?.reservations || item?.reservation?.reservationId
						);
						setIsReservationOpen(true);
					} else {
						const data = {
							startDate: item.date,
							endDate: item.endDate,
							listingId: e.appartmentIndex,
							price: item.price,
							note: item.note,
							minimumNights,
							maxNoOfUnits: noOfUnits,
							bookedUnit: item.count || 0,
							blockedUnits: item.blockedUnits,
							hasUnits: true,
							dates: list,
						};
						setModalListingdata(data);
						setListingId(e.appartmentIndex);
						setIsModalOpen(true);
					}
				}}
			>
				<div
					className={`absolute inset-0 inset-y-0 ${
						isPast ? "bg-[#e8eaf375]" : ""
					}  `}
				/>
				<div
					className={`absolute -left-4 w-[1px] bottom-0 -top-1 ${
						today ? "bg-[#57b5b5]" : "bg-[#e2e3e5]"
					} z-10 `}
				/>
				<div style={{ width: width }} className={className}>
					<div className="absolute inset-0 rotate-180">
						<div className="bg-[#bae8da]" style={{ height: `${percent}%` }} />
						{percent2 > 0 && (
							<div
								className="bg-[#d9dff5]"
								style={{ height: `${percent2}%` }}
							/>
						)}
					</div>
					{!item.isBlocked && (
						<span className="relative">
							{getCurrencySymbol(e?.currency)} {item.price}
						</span>
					)}

					{listView === "addSpace" && !item.isBlocked && (
						<div className="text-[10px] font-normal relative">
							{minimumNights ? `${minimumNights} nights min` : ""}
						</div>
					)}

					{item.isBlocked && (
						<div
							className={`w-full skew-x-[20deg] relative flex ${
								item.size > 1 ? "justify-between" : "justify-center"
							}  text-gray-500 px-4`}
						>
							<span>
								<Lock className="!h-[15px] !w-[15px] text-[grey]" />
							</span>{" "}
							{item.size > 1 && (
								<span>
									<Lock className="!h-[15px] !w-[15px] text-[grey]" />
								</span>
							)}
						</div>
					)}
				</div>
			</div>
		);
	} catch (e) {
		console.log(e);
		return <></>;
	}
};

const ListingItem = ({ e, i, listView, openIds, setOpenIds, startDate }) => {
	const noOfUnits = parseInt("" + e?.number_of_units);
	const hasUnits = noOfUnits !== 1;
	const open = openIds.includes(e._id);
	return (
		<div className="bg-white mb-1" key={i}>
			<div
				className={`${
					listView === "addSpace" ? "h-[44px] mb-4" : "h-[41px] mb-1"
				} flex  relative items-center justify-between gap-2 px-2 hover:bg-[#091e420a]`}
			>
				{hasUnits && (
					<div className="flex items-center">
						<div className="border-2 border-buttonTheme font-bold rounded-sm px-1 relative before:absolute before:bg-buttonTheme before:w-[1.5px] before:h-[14px] before:-ml-1 before:-mt-[1px] before:inset-x-0 before:top-1/2 before:-translate-y-1/2 before:rounded-sm after:absolute after:bg-buttonTheme after:w-[1.5px] after:h-[10px] after:-ml-[6.5px] after:-mt-[1px] after:inset-x-0 after:top-1/2 after:-translate-y-1/2 after:rounded-sm">
							{e?.number_of_units}
						</div>
					</div>
				)}
				<div className=" flex items-center justify-between gap-2 w-full">
					<div className="">
						<Tooltip
							placement="top"
							color="white"
							title={e?.mainTitle}
							overlayInnerStyle={{ color: "#6B778C" }}
						>
							<Link
								className="hover:underline hover:text-buttonTheme font-medium text-[#2f4858]"
								to={`${CALENDAR_MONTHLY}?listing=${
									e?.appartmentIndex
								}&date=${formatDate(startDate, "MMM yyyy")}`}
							>
								{e?.mainTitle.slice(0, 15)}
								{e?.mainTitle?.length > 15 && "..."}
							</Link>
						</Tooltip>
					</div>
					<div className="flex gap-1 items-center text-[#6b778c] text-[13px]">
						<div>{e?.appartmentIndex}</div>
						{hasUnits && (
							<div className="mb-2 ">
								{open ? (
									<UpOutlined
										onClick={() =>
											setOpenIds((arr) => [...arr.filter((id) => id !== e._id)])
										}
									/>
								) : (
									<DownOutlined
										onClick={() => setOpenIds((arr) => [...arr, e._id])}
									/>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
			{hasUnits && open === true && (
				<div className="relative">
					{range(1, noOfUnits, 1).map((no) => (
						<div
							key={no}
							className={`${
								listView === "addSpace" ? "h-[44px] mb-4" : "h-[41px] mb-1"
							} flex items-center hover:bg-[#091e420a] pl-12 text-[13px] text-[#2f4858]  before:absolute before:w-[0.5px] before:opacity-50 before:bg-[#dfe1e6] before:left-6 before:top-0 before:bottom-1/2 before:-mt-[1px]  after:absolute after:w-[0.5px] after:opacity-50 after:bg-[#dfe1e6] after:left-6 after:top-1/2 after:bottom-0 after:-mt-[1px]`}
						>
							<div className="w-3 border-b border-b-[#dfe1e6] opacity-50 absolute left-6" />
							<div>Unit {no}</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};
