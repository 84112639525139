import React, { useEffect } from "react";
import { Tabs, Tooltip, Input } from "antd";
import SelectWithSearch from "react-select";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import GenericButton from "../GenericButton/GenericButton";
import Airbnb from "../../assets/airbnb_logo.png";
import Booking from "../../assets/booking_logo.png";
import Vrbo from "../../assets/vrbo_logo.png";
import HelpIcon from "@mui/icons-material/Help";
import ApartmentIcon from "@mui/icons-material/Apartment";
import SettingsIcon from "@mui/icons-material/Settings";
import { CountryCode } from "../countryCode";
import { useState } from "react";
import { addYears } from "date-fns";
import { formatDate } from "../../utils/dateHelper";

const antStyle = {
  position: "absolute",
  top: "50%",
  transform: "translate(0px, -50%)",
  right: "10px",
};

const tableTitle = (title) => (
  <div className="border-b-2 border-slate-400 h-10 p-2">
    <p className="text-slate-500">{title}</p>
  </div>
);

const CustomizeToolTip = ({ title }) => (
  <Tooltip title={title}>
    <HelpIcon className="text-sky-600 !text-[17px]" />
  </Tooltip>
);

const FirstColumn = ({ title }) => (
  <div className="flex justify-start items-center h-12 pl-4 border-b-[1px] border-slate-300">
    <p className="font-semibold mr-1">{title}</p>
    <CustomizeToolTip title="Default rate that applies to newly available dates in the future." />
  </div>
);
const sortCountryCodeOptions = CountryCode.map((item) => ({
  label: item.currency.code,
  value: item.currency.code,
  key: item.id,
}));

const Fees = ({ payload, setPayload }) => {
  const [currencySymbol, setCurrencySymbol] = useState("");

  useEffect(() => {
    CountryCode.map((c) => {
      if (c?.currency?.code === payload?.priceAndFees?.currency) {
        setCurrencySymbol(c?.currency?.symbol);
      }
    });
  }, [payload?.priceAndFees?.currency]);

  return (
    <div className="overflow-x-scroll h-auto bg-white">
      <div style={{ width: "200px", margin: 20 }}>
        <p className="text-xl text-themeGrey font-semibold my-2">Currency</p>
        <SelectWithSearch
          selectTitle="Currency"
          selectPlaceholder="Select Currency"
          options={sortCountryCodeOptions}
          name="currency"
          value={{
            label: payload?.priceAndFees?.currency,
            value: payload?.priceAndFees?.currency,
          }}
          onChange={(e) =>
            setPayload({
              ...payload,
              priceAndFees: { ...payload.priceAndFees, currency: e.value },
            })
          }
        />
      </div>
      <div className="grid grid-flow-col justify-stretch w-[1200px] 2xl:w-full">
        <div style={{ width: "100%" }}>
          {tableTitle("Fee")}
          <FirstColumn title="Base price" />
          <FirstColumn title="Mark %" />
          <FirstColumn title="Price after markup" />
          <FirstColumn title="Price for extra person" />
          <FirstColumn title="Apply for each guest after" />
          <FirstColumn title="Refundable damage deposit fee" />
          <FirstColumn title="Cleaning fee" />
          <FirstColumn title="Short-term cleaning fee" />
          <FirstColumn title="Pet fee" />
          <FirstColumn title="Check-in fee" />
          <FirstColumn title="Resort fee" />
          <FirstColumn title="Community fee" />
          <FirstColumn title="Management fee" />
          <FirstColumn title="Linen fee" />
        </div>
        <div style={{ width: "85%" }}>
          {tableTitle("Default")}
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              disabled={!payload?.priceAndFees?.currency}
              defaultValue={payload?.priceAndFees?.basePrice}
              value={payload?.priceAndFees?.basePrice || ""}
              name="base_price"
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    priceAndFees: {
                      ...payload.priceAndFees,
                      basePrice: e.target.value,
                    },
                  });
                }
              }}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative "></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              defaultValue={payload?.priceAndFees?.priceForExtraPerson}
              value={payload?.priceAndFees?.priceForExtraPerson || ""}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    priceAndFees: {
                      ...payload.priceAndFees,
                      priceForExtraPerson: e.target.value,
                    },
                  });
                }
              }}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              defaultValue={payload?.priceAndFees?.applyForEachGuestAfter}
              value={payload?.priceAndFees?.applyForEachGuestAfter || ""}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    priceAndFees: {
                      ...payload.priceAndFees,
                      applyForEachGuestAfter: e.target.value,
                    },
                  });
                }
              }}
            />
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              defaultValue={payload?.priceAndFees?.refundableDamageDepositFees}
              value={payload?.priceAndFees?.refundableDamageDepositFees || ""}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    priceAndFees: {
                      ...payload.priceAndFees,
                      refundableDamageDepositFees: e.target.value,
                    },
                  });
                }
              }}
            />
            <p style={antStyle}>{currencySymbol}</p>
            <SettingsIcon
              style={{ right: "-30px" }}
              className="!text-[18px] mr-2 ml-1 absolute"
            />
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              defaultValue={payload?.priceAndFees?.cleaningFee}
              value={payload?.priceAndFees?.cleaningFee || ""}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    priceAndFees: {
                      ...payload.priceAndFees,
                      cleaningFee: e.target.value,
                    },
                  });
                }
              }}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
        </div>
        {/* airbnb column */}
        <div style={{ width: "130px" }}>
          <div className="flex justify-center items-center border-b-2 border-slate-400 h-10">
            <img src={Airbnb} className="object-contain h-full" />
            <CustomizeToolTip title="Changes are exported to Airbnbn upon clicking save and export." />
          </div>

          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.basePrice}
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              addonAfter="%"
              value={payload?.priceAndFees?.markUpAirbnb || ""}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    priceAndFees: {
                      ...payload.priceAndFees,
                      markUpAirbnb: e.target.value,
                    },
                  });
                }
              }}
            />
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={
                payload?.priceAndFees?.markUpAirbnb
                  ? (payload?.priceAndFees?.basePrice *
                    payload?.priceAndFees?.markUpAirbnb) /
                  100 +
                  parseInt(payload?.priceAndFees?.basePrice, 10)
                  : payload?.priceAndFees?.basePrice
              }
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.priceForExtraPerson}
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.applyForEachGuestAfter}
              disabled={true}
            />
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.refundableDamageDepositFees}
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.cleaningFee}
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              defaultValue={payload?.priceAndFees?.airbnbShortTermCleaningFee}
              value={payload?.priceAndFees?.airbnbShortTermCleaningFee || ""}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    priceAndFees: {
                      ...payload.priceAndFees,
                      airbnbShortTermCleaningFee: e.target.value,
                    },
                  });
                }
              }}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              defaultValue={payload?.priceAndFees?.airbnbPetFee}
              value={payload?.priceAndFees?.airbnbPetFee || ""}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    priceAndFees: {
                      ...payload.priceAndFees,
                      airbnbPetFee: e.target.value,
                    },
                  });
                }
              }}
            />
            <p style={antStyle}>{currencySymbol}</p>
            <SettingsIcon
              style={{ right: "-30px" }}
              className="!text-[18px] mr-2 ml-1 absolute"
            />
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              defaultValue={payload?.priceAndFees?.airbnbResortFee}
              value={payload?.priceAndFees?.airbnbResortFee || ""}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    priceAndFees: {
                      ...payload.priceAndFees,
                      airbnbResortFee: e.target.value,
                    },
                  });
                }
              }}
            />
            <p style={antStyle}>{currencySymbol}</p>
            <SettingsIcon
              style={{ right: "-30px" }}
              className="!text-[18px] mr-2 ml-1 absolute"
            />
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              defaultValue={payload?.priceAndFees?.communityFee}
              value={payload?.priceAndFees?.communityFee || ""}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    priceAndFees: {
                      ...payload.priceAndFees,
                      communityFee: e.target.value,
                    },
                  });
                }
              }}
            />
            <p style={antStyle}>{currencySymbol}</p>
            <SettingsIcon
              style={{ right: "-30px" }}
              className="!text-[18px] mr-2 ml-1 absolute"
            />
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              defaultValue={payload?.priceAndFees?.airbnbManagementFee}
              value={payload?.priceAndFees?.airbnbManagementFee || ""}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    priceAndFees: {
                      ...payload.priceAndFees,
                      airbnbManagementFee: e.target.value,
                    },
                  });
                }
              }}
            />
            <p style={antStyle}>{currencySymbol}</p>
            <SettingsIcon
              style={{ right: "-30px" }}
              className="!text-[18px] mr-2 ml-1 absolute"
            />
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              defaultValue={payload?.priceAndFees?.airbnbLinenFee}
              value={payload?.priceAndFees?.airbnbLinenFee || ""}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    priceAndFees: {
                      ...payload.priceAndFees,
                      airbnbLinenFee: e.target.value,
                    },
                  });
                }
              }}
            />
            <p style={antStyle}>{currencySymbol}</p>
            <SettingsIcon
              style={{ right: "-30px" }}
              className="!text-[18px] mr-2 ml-1 absolute"
            />
          </div>
        </div>
        {/* booking column */}
        <div style={{ width: "130px" }}>
          <div className="flex justify-center items-center border-b-2 border-slate-400 h-10">
            <img src={Booking} className="object-contain h-full" />
            <CustomizeToolTip title="Changes are exported to Airbnbn upon clicking save and export." />
          </div>

          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.basePrice}
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-end items-center h-12 border-b-[1px] border-slate-300">
            <SettingsIcon
              style={{ right: "-30px" }}
              className="!text-[18px] mr-2"
            />
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.basePrice}
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.priceForExtraPerson}
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.applyForEachGuestAfter}
              disabled={true}
            />
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.cleaningFee}
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
        </div>
        {/* vrbo column */}
        <div style={{ width: "130px" }}>
          <div className="flex justify-center items-center border-b-2 border-slate-400 h-10">
            <img src={Vrbo} className="object-contain h-full" />
            <CustomizeToolTip title="Changes are exported to Airbnbn upon clicking save and export." />
          </div>

          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.basePrice}
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              addonAfter="%"
              defaultValue={payload?.priceAndFees?.markUpVrbo}
              value={payload?.priceAndFees?.markUpVrbo || ""}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    priceAndFees: {
                      ...payload.priceAndFees,
                      markUpVrbo: e.target.value,
                    },
                  });
                }
              }}
            />
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={
                payload?.priceAndFees?.markUpVrbo
                  ? (payload?.priceAndFees?.basePrice *
                    payload?.priceAndFees?.markUpVrbo) /
                  100 +
                  parseInt(payload?.priceAndFees?.basePrice, 10)
                  : payload?.priceAndFees?.basePrice
              }
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.priceForExtraPerson}
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.applyForEachGuestAfter}
              disabled={true}
            />
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.refundableDamageDepositFees}
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.cleaningFee}
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              defaultValue={payload?.priceAndFees?.vrboPetFee}
              value={payload?.priceAndFees?.vrboPetFee || ""}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    priceAndFees: {
                      ...payload.priceAndFees,
                      vrboPetFee: e.target.value,
                    },
                  });
                }
              }}
            />
            <p style={antStyle}>{currencySymbol}</p>
            <SettingsIcon
              style={{ right: "-30px" }}
              className="!text-[18px] mr-2 ml-1 absolute"
            />
          </div>

          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
        </div>
        {/* apartment column */}
        <div style={{ width: "130px" }}>
          <div className="flex justify-center items-center border-b-2 border-slate-400 h-10">
            <ApartmentIcon className="text-orange-400 !text-[30px] mr-1" />
            <CustomizeToolTip title="Changes are exported to Airbnbn upon clicking save and export." />
          </div>

          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.basePrice}
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-end items-center h-12 border-b-[1px] border-slate-300">
            <SettingsIcon
              style={{ right: "-30px" }}
              className="!text-[18px] mr-2"
            />
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.basePrice}
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.refundableDamageDepositFees}
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.cleaningFee}
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
        </div>
        {/* hostaway column */}
        <div style={{ width: "130px" }}>
          <div className="flex justify-center items-center border-b-2 border-slate-400 h-10">
            <p className="italic text-[30px] text-orange-400 font-semibold mr-1">
              H
            </p>
            <CustomizeToolTip title="Changes are exported to Airbnbn upon clicking save and export." />
          </div>

          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.basePrice}
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-end items-center h-12 border-b-[1px] border-slate-300">
            <SettingsIcon
              style={{ right: "-30px" }}
              className="!text-[18px] mr-2"
            />
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.basePrice}
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.priceForExtraPerson}
              disabled={true}
            />
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.applyForEachGuestAfter}
              disabled={true}
            />
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.refundableDamageDepositFees}
              disabled={true}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              value={payload?.priceAndFees?.cleaningFee}
            />
            <p style={antStyle}>{currencySymbol}</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <Input
              className="w-[150px]"
              defaultValue={payload?.priceAndFees?.hostAwayCheckInFee}
              value={payload?.priceAndFees?.hostAwayCheckInFee || ""}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    priceAndFees: {
                      ...payload.priceAndFees,
                      hostAwayCheckInFee: e.target.value,
                    },
                  });
                }
              }}
            />
            <p style={antStyle}>{currencySymbol}</p>
            <SettingsIcon
              style={{ right: "-30px" }}
              className="!text-[18px] mr-2 ml-1 absolute"
            />
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative"></div>
        </div>
      </div>
    </div>
  );
};

const Discounts = ({ payload, setPayload }) => {
  const discounts = payload?.discounts || {}
  return (
    <div className="overflow-x-scroll h-auto bg-white">
      <div className="grid grid-flow-col justify-stretch w-[1200px] 2xl:w-full">
        <div className="">
          {tableTitle("Discount")}
          <FirstColumn title="Weekly discount" />
          <FirstColumn title="2 Weeks discount" />
          <FirstColumn title="Monthly discount" />
          <FirstColumn title="Quarterly discount" />
          {/* <FirstColumn title="6 Months discount" /> */}
          <FirstColumn title="Last Minute discount" />
        </div>
        <div className="">
          {tableTitle("Default")}
          <div
            className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative abcd"
            style={{ width: "fit-content" }}
          >
            <Input
              className="w-[150px]"
              disabled={false}
              name="week_1"
              defaultValue={discounts.week_1}
              value={discounts.week_1}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    discounts: {
                      ...discounts,
                      week_1: e.target.value,
                    },
                  });
                }
              }}
              type="number"
            />
            <p style={antStyle}>%</p>
          </div>
          <div
            className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative abcd"
            style={{ width: "fit-content" }}
          >
            <Input
              className="w-[150px]"
              disabled={false}
              name="week_2"
              defaultValue={discounts.week_2}
              value={discounts.week_2}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    discounts: {
                      ...discounts,
                      week_2: e.target.value,
                    },
                  });
                }
              }}
              type="number"
            />
            <p style={antStyle}>%</p>
          </div>
          <div
            className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative abcd"
            style={{ width: "fit-content" }}
          >
            <Input
              className="w-[150px]"
              disabled={false}
              name="month_1"
              defaultValue={discounts.month_1}
              value={discounts.month_1}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    discounts: {
                      ...discounts,
                      month_1: e.target.value,
                    },
                  });
                }
              }}
              type="number"
            />
            <p style={antStyle}>%</p>
          </div>
          <div
            className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative abcd"
            style={{ width: "fit-content" }}
          >
            <Input
              className="w-[150px]"
              disabled={false}
              name="month_3"
              defaultValue={discounts.month_3}
              value={discounts.month_3}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    discounts: {
                      ...discounts,
                      month_3: e.target.value,
                    },
                  });
                }
              }}
              type="number"
            />
            <p style={antStyle}>%</p>
          </div>
          {/* <div
            className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative abcd"
            style={{ width: "fit-content" }}
          >
            <Input
              className="w-[150px]"
              disabled={false}
              name="month_6"
              defaultValue={discounts.month_6 ||"0"}
              value={discounts.month_6 }
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    discounts: {
                      ...discounts,
                      month_6: e.target.value,
                    },
                  });
                }
              }}
              type="number"
            />
            <p style={antStyle}>%</p>
          </div> */}
          <div
            className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative abcd"
            style={{ width: "fit-content" }}
          >
            <Input
              className="w-[150px]"
              disabled={true}
              name="last_minute"
              defaultValue={discounts.last_minute}
              value={discounts.last_minute}
              onChange={(e) => {
                if (/^\d*\.?\d*$/.test(e.target.value) || !e.target.value) {
                  setPayload({
                    ...payload,
                    discounts: {
                      ...discounts,
                      last_minute: e.target.value,
                    },
                  });
                }
              }}
              type="number"
            />
            <p style={antStyle}>%</p>
          </div>
        </div>
        <div className="">
          <div className="flex justify-center items-center border-b-2 border-slate-400 h-10">
            <img src={Airbnb} className="object-contain h-full" alt="" />
            <CustomizeToolTip title="Changes are exported to Airbnbn upon clicking save and export." />
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.week_1 || 0}%</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.week_2 || 0}%</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.month_1 || 0}%</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.month_3 || 0}%</p>
          </div>
          {/* <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.month_6 || 0}%</p>
          </div> */}
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.last_minute || 0}%</p>
          </div>
        </div>
        <div className="">
          <div className="flex justify-center items-center border-b-2 border-slate-400 h-10">
            <img src={Booking} className="object-contain h-full" alt="" />
            <CustomizeToolTip title="Changes are exported to Airbnbn upon clicking save and export." />
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.week_1 || 0}%</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.week_2 || 0}%</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.month_1 || 0}%</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.month_3 || 0}%</p>
          </div>
          {/* <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.month_6 || 0}%</p>
          </div> */}
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.last_minute || 0}%</p>
          </div>
        </div>
        <div className="">
          <div className="flex justify-center items-center border-b-2 border-slate-400 h-10">
            <img src={Vrbo} className="object-contain h-full" alt="" />
            <CustomizeToolTip title="Changes are exported to Airbnbn upon clicking save and export." />
          </div>

          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.week_1 || 0}%</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.week_2 || 0}%</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.month_1 || 0}%</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.month_3 || 0}%</p>
          </div>
          {/* <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.month_6 || 0}%</p>
          </div> */}
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.last_minute || 0}%</p>
          </div>
        </div>
        <div className="">
          <div className="flex justify-center items-center border-b-2 border-slate-400 h-10">
            <ApartmentIcon className="text-orange-400 !text-[30px] mr-1" />
            <CustomizeToolTip title="Changes are exported to Airbnbn upon clicking save and export." />
          </div>

          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.week_1 || 0}%</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.week_2 || 0}%</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.month_1 || 0}%</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.month_3 || 0}%</p>
          </div>
          {/* <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.month_6 || 0}%</p>
          </div> */}
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.last_minute || 0}%</p>
          </div>
        </div>
        <div className="">
          <div className="flex justify-center items-center border-b-2 border-slate-400 h-10">
            <p className="italic text-[30px] text-orange-400 font-semibold mr-1">
              H
            </p>
            <CustomizeToolTip title="Changes are exported to Airbnbn upon clicking save and export." />
          </div>

          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.week_1 || 0}%</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.week_2 || 0}%</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.month_1 || 0}%</p>
          </div>
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.month_3 || 0}%</p>
          </div>
          {/* <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.month_6 || 0}%</p>
          </div> */}
          <div className="flex justify-start items-center h-12 border-b-[1px] border-slate-300 table-box-wrap relative">
            <p>{discounts.last_minute || 0}%</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const items = (payload, setPayload) => {
  return [
    {
      key: "1",
      label: `Fees`,
      children: <Fees payload={payload} setPayload={setPayload} />,
    },
    {
      key: "2",
      label: `Discounts`,
      children: <Discounts payload={payload} setPayload={setPayload} />,
    },
  ];
};

const PriceAndFee = ({ payload, setPayload }) => {
  const date = payload.updatedAt || payload.createdAt || ""
  const dateObj = addYears(new Date(date), 3)

  return (
    <div className="bg-createEditListingBackground h-auto p-5">
      <div className="flex items-center">
        <p className="text-xl text-slate-600 font-semibold">
          Price and Fees{" "}
          <span>
            <ContentCopyIcon className="!text-[22px] text-buttonTheme cursor-pointer" />
          </span>
        </p>
        {date && <div className="text-cancelButton text-base font-medium px-4">Update discounts before {formatDate(dateObj, "yyyy-MM-dd")}</div>}
      </div>

      <div id="PriceAndFee">
        <Tabs
          defaultActiveKey="1"
          items={items(payload, setPayload)}
          tabBarStyle={{ color: "#2f9f9f" }}
        />
      </div>
    </div>
  );
};

export default PriceAndFee;
