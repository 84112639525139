import React from "react";
import { Tooltip, Input } from "antd";
import HelpIcon from "@mui/icons-material/Help";
import { Controller } from "react-hook-form";

const { TextArea } = Input;

const GenericInput = ({
  inputTitle,
  tooltipTitle,
  toolTipPlacement = "right",
  inputPlaceholder,
  defaultValue = "",
  requiredFlag = false,
  toolTipFlag = false,
  textAreaFlag = false,
  rows = 4,
  disabled = false,
  name = "",
  message = "",
  control,
  onChange,
  type = "text",
  pattern = "",
  patternMSG = "",
  id = {},
  formatedValue,
  value,
}) => {
  return (
    <div className="">
      <div className="flex items-center">
        <p className="font-semibold text-slate-700">
          {inputTitle} {requiredFlag && <span className="text-red-600">*</span>}
        </p>
        {toolTipFlag && (
          <Tooltip placement={toolTipPlacement} title={tooltipTitle}>
            <HelpIcon className="text-sky-600 !text-[17px] ml-1" />
          </Tooltip>
        )}
      </div>
      {textAreaFlag ? (
        <Controller
          name={name}
          rules={{
            required: requiredFlag ? message : false,
            pattern: {
              value: pattern,
              message: patternMSG,
            },
          }}
          defaultValue={defaultValue}
          render={({ field }) => (
            <TextArea
              {...field}
              placeholder={inputPlaceholder}
              rows={rows}
              disabled={disabled}
              className="mt-1"
              name={name}
              onChange={(e) => {
                field.onChange(e);
                onChange(e);
              }}
              type={type}
              id={id}
            />
          )}
          control={control}
        />
      ) : (
        <Controller
          name={name}
          rules={{
            required: requiredFlag ? message : false,
            pattern: {
              value: pattern,
              message: patternMSG,
            },
          }}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Input
              {...field}
              placeholder={inputPlaceholder}
              disabled={disabled}
              className="mt-1"
              name={name}
              onChange={(e) => {
                field.onChange(e);
                onChange(e);
              }}
              value={value}
              maxLength={50}
              type={type}
              id={id}
            />
          )}
          control={control}
        />
      )}
    </div>
  );
};

export default GenericInput;
