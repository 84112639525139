import { useContext, useEffect, useState } from "react";
import {
	Collapse,
	DatePicker,
	Divider,
	Input,
	InputNumber,
	Modal,
	Switch,
} from "antd";
import dayjs from "dayjs";
import { manageToast } from "../../Common/ManageToast";
import GenericButton from "../../GenericButton/GenericButton";
import { apiInstance } from "../../../utils/API";
import { differenceInDays } from "date-fns";
import { range } from "../../../utils/helper";
import { AppContext } from "../../../AppContext";
import { Link, useNavigate } from "react-router-dom";
import { RESERVATIONS } from "../../../constants/dashboardRoutes";
import { formatDate } from "../../../utils/dateHelper";

const { RangePicker } = DatePicker;

export default function BlockListingCalendar(props) {
	const {
		isModalOpen,
		setIsModalOpen,
		listingId,
		modalListingData,
		onBlockSave,
	} = props;
	const profile = useContext(AppContext).profile;
	const navigate = useNavigate();
	let noAccess = !profile.updateCalendar;
	const [availability, setAvailability] = useState(true);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [note, setNote] = useState("");
	const [price, setPrice] = useState(null);
	const [minStay, setMinStay] = useState(null);
	const [noOfUnits, setNoOfUnits] = useState(null);
	const [hasUnits, setHasUnits] = useState(false);
	const [sending, setSending] = useState(false);
	const [oldUnits, setOldUnits] = useState("");

	const dates = modalListingData.dates;
	let maxDate = null;

	if (dates && dates.length > 0) maxDate = dayjs(dates[dates.length - 1].date);

	useEffect(() => {
		let data = { note: "", isAvailable: true };
		if (modalListingData?.startDate) {
			setStartDate(dayjs(modalListingData?.startDate));
		}
		if (modalListingData?.endDate) {
			setEndDate(dayjs(modalListingData?.endDate));
		}
		if (modalListingData?.price) {
			setPrice(modalListingData?.price);
			data.price = modalListingData?.price;
		}
		if (modalListingData?.minimumNights) {
			setMinStay(modalListingData?.minimumNights);
			data.minimumNights = modalListingData?.minimumNights;
		}
		if (modalListingData?.note) {
			setNote(modalListingData?.note);
			data.note = modalListingData?.note;
		}
		if (modalListingData?.hasUnits && !modalListingData.blockedUnits) {
			let count = modalListingData?.maxNoOfUnits - modalListingData?.bookedUnit;
			if (count < 0) count = 0;
			setNoOfUnits(count);
			data.units = count;
		} else if (modalListingData?.hasUnits) {
			let count =
				modalListingData?.maxNoOfUnits -
				(modalListingData?.blockedUnits + modalListingData?.bookedUnit);
			if (count < 0) count = 0;
			setNoOfUnits(count);
			data.units = count;
		}

		// blockedUnits

		if (modalListingData?.hasUnits) {
			setHasUnits(modalListingData?.hasUnits);
		}
		if (modalListingData.hasOwnProperty("isAvailable")) {
			setAvailability(modalListingData.isAvailable);
			data.isAvailable = modalListingData.isAvailable;
		}
		setOldUnits(data.units);
	}, [modalListingData]);

	const onSave = async () => {
		try {
			const totalUnits = modalListingData?.maxNoOfUnits || 0;

			const data = {
				start: startDate.toDate(),
				end: endDate.toDate(),
				price: price,
				note: note,
				minimumNights: minStay,
				differences: {},
			};

			if (!data.start) {
				manageToast(false, "Start Date is required.");
				return;
			}
			if (!data.end) {
				manageToast(false, "End Date is required.");
				return;
			}
			if (!data.price || data.price < 0) {
				manageToast(false, "Price is required.");
				return;
			}

			if (hasUnits && noOfUnits === null) {
				manageToast(false, "Room to sell is required.");
				return;
			} else if (hasUnits && (noOfUnits > totalUnits || noOfUnits < 0)) {
				manageToast(false, "Room to sell is invalid.");
				return;
			}

			if (!data.minimumNights) {
				manageToast(false, "Minimum Stay is required.");
				return;
			}

			if (hasUnits) {
				data.dates = [];
				const filteredDate = dates.filter(
					(d) => d.date >= startDate.toDate() && d.date <= endDate.toDate()
				);
				filteredDate.forEach((d) => {
					let units = noOfUnits;
					if (noOfUnits + d.count > totalUnits) units = totalUnits - d.count;

					let blockedUnits = totalUnits - d.count - units;
					if (blockedUnits < 0) blockedUnits = 0;

					data.dates.push({
						blockedUnits: blockedUnits,
						units: units,
						isAvailable: blockedUnits !== totalUnits,
					});
				});
			} else {
				const dif = endDate.diff(startDate, "day");
				data.dates = range(0, dif, 1).map((e) => {
					return {
						isAvailable: availability,
						blockedUnits: availability ? 0 : 1,
						units: availability ? 1 : 0,
					};
				});
			}

			data.differences.roomsToSell = [data.dates[0].units, oldUnits, "updated"];
			data.differences.price = [price, modalListingData?.price, "updated"];
			data.differences.minimumNights = [
				minStay,
				modalListingData?.minimumNights,
				"updated",
			];

			setSending(true);
			const res = await apiInstance.put(
				"/api/calendar/listing/" +
					listingId +
					`?userName=${localStorage.getItem("userName")}`,
				data
			);
			if (res.status === 201) {
				manageToast(true, "Calendar Updated");
				onBlockSave(listingId);
				setIsModalOpen(false);
				navigate(0);
				setSending(false);
			}
		} catch (error) {
			console.log(error);
			setSending(false);
		}
	};

	return (
		<Modal
			open={isModalOpen}
			onCancel={() => {
				setNote(undefined);
				setPrice("");
				setStartDate(null);
				setEndDate(null);
				setIsModalOpen(false);
			}}
			width={400}
			footer={
				noAccess ? (
					<></>
				) : (
					<div className="flex justify-between items-center">
						<div>
							{differenceInDays(new Date(startDate), new Date()) >= 0 && (
								<Link
									target="_blank"
									to={`${RESERVATIONS}/add?listing=${listingId}&checkin=${formatDate(
										new Date(startDate),
										"dd MMMM, yyyy"
									)}`}
								>
									<GenericButton
										type="button"
										label="Add Reservation"
										className="bg-lightButtonTheme hover:!bg-lightButtonHoverTheme text-buttonTheme disabled:bg-lightButtonTheme disabled:hover:!bg-lightButtonTheme disabled:opacity-50"
										key={1}
									/>
								</Link>
							)}
						</div>
						<GenericButton
							label="Save"
							isLoading={sending}
							loadingClass="text-white"
							disabled={
								differenceInDays(new Date(startDate), new Date()) < 0 || sending
							}
							className="bg-buttonTheme hover:!bg-buttonHoverTheme disabled:bg-buttonTheme disabled:hover:!bg-buttonTheme"
							onClick={() => onSave()}
							key={2}
						/>
					</div>
				)
			}
		>
			<p className="text-xl font-semibold">Manage Dates</p>
			<div className="mt-5 px-3 pt-3 pb-2">
				<RangePicker
					disabled={noAccess}
					className="w-full"
					format="YYYY-MM-DD"
					value={[startDate, endDate]}
					disabledDate={(d) => {
						if (dayjs(new Date()) < d) return false;
						if (maxDate && maxDate > d) return false;
						return true;
					}}
					onChange={(range) => {
						if (range) {
							let valueOfInput1 = range[0];
							let valueOfInput2 = range[1];
							if (valueOfInput1 < dayjs(new Date()))
								valueOfInput1 = dayjs(new Date());

							if (maxDate && valueOfInput2 > maxDate) valueOfInput2 = maxDate;

							setStartDate(valueOfInput1);
							setEndDate(valueOfInput2);
						}
					}}
				/>
			</div>
			<Collapse
				defaultActiveKey={["1", "2"]}
				items={[
					{
						key: "1",
						label: <p className="text-lg font-semibold">Pricing</p>,
						children: (
							<div>
								<div className="flex items-center ">
									<label className="font-bold text-themeGrey capitalize">
										Nightly base rate before markup
									</label>
								</div>
								<InputNumber
									disabled={noAccess}
									value={price}
									className={`w-[100%] rounded-none placeholder:capitalize`}
									type="number"
									onChange={(e) => {
										setPrice(e);
									}}
								/>

								<p className="font-semibold text-buttonTheme hover:!text-buttonHoverTheme mt-2 cursor-pointer">
									Show prices per channel
								</p>
							</div>
						),
					},
					{
						key: "2",
						label: <p className="text-lg font-semibold">Availability</p>,
						children: (
							<>
								<div>
									<div className="flex items-center ">
										<label className="font-bold text-themeGrey capitalize">
											Minimum stay
										</label>
									</div>

									<InputNumber
										disabled={noAccess}
										value={minStay}
										className={` w-[100%] rounded-none placeholder:capitalize`}
										type="number"
										onChange={(e) => {
											setMinStay(e);
										}}
									/>
									{hasUnits && (
										<div className="flex items-center ">
											<label className="font-bold text-themeGrey capitalize">
												Rooms to sell
											</label>
										</div>
									)}

									{hasUnits ? (
										<InputNumber
											disabled={noAccess}
											value={noOfUnits}
											className={` w-[100%] rounded-none placeholder:capitalize`}
											type="number"
											onChange={(e) => {
												setNoOfUnits(e);
											}}
										/>
									) : (
										<div className="mt-5">
											<p className="text-sm text-slate-500">Availablility</p>
											<div className="flex gap-2">
												<Switch
													checked={availability}
													onChange={(e) => {
														setAvailability(e);
													}}
												/>
												<p>{availability ? "Available" : "Blocked"}</p>
											</div>
										</div>
									)}
								</div>
							</>
						),
					},
					{
						key: "3",
						label: <p className="text-lg font-semibold">Calendar Notes</p>,
						children: (
							<div>
								<div className="flex items-center ">
									<label className="font-bold text-themeGrey capitalize">
										Save calendar notes for the selected dates
									</label>
								</div>
								<Input
									disabled={noAccess}
									value={note}
									className={` w-[100%] rounded-none placeholder:capitalize`}
									onChange={(e) => {
										setNote(e.target.value);
									}}
								/>
							</div>
						),
					},
				]}
				bordered={false}
				className="bg-white"
				expandIconPosition="end"
			/>
		</Modal>
	);
}
