/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GenericButton from "../../../components/GenericButton/GenericButton";
import { Checkbox, Form } from "antd";
import { manageToast } from "../../../components/Common/ManageToast";
import { initialValues } from "../../../components/UserManagement/Users/Helper";
import AddBasicInfo from "../../../components/UserManagement/Users/AddBasicInfo";
import AccessPermissions from "../../../components/UserManagement/Users/AccessPermissions";
import NotificationsPermissions from "../../../components/UserManagement/Users/NotificationsPermissions";
// import ListingAccess from "../../../components/UserManagement/Users/ListingAccess";
import { USER_MANAGEMENT_USERS } from "../../../constants/dashboardRoutes";
import { uploadFile } from "../../../utils/s3Helper";
import { convertArrayToFormData, convertFormDataToArray } from "../helper";
import { apiInstance } from "../../../utils/API";
import Loading from "../../../components/Loaders/Loading";
import { AppContext } from "../../../AppContext";
import NoAccess from "../../../components/NoAccess";
import AssignCity from "../../../components/UserManagement/Users/AssignCity";

export default function UserForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const profile = useContext(AppContext).profile || {};
  const hasAccess = true;
  const admin = profile.isAdmin;
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [email, setEmail] = useState(null);
  // const [listings, setListings] = useState([])
  const [selListings, setSelListings] = useState([]);
  // const [searchList, setSearchList] = useState("")

  const [form] = Form.useForm();
  const formulaNameToShowTotalPriceForm = Form.useWatch(
    "formulaNameToShowTotalPriceForm",
    form
  );
  const accessContactData = Form.useWatch("accessContactData", form);
  const isAdmin = Form.useWatch("isAdmin", form);

  useEffect(() => {
    if (hasAccess) {
      fetchGroups();
      // getListings()
    }
  }, [hasAccess]);

  useEffect(() => {
    if (id && id !== "add") {
      fetchUser();
      fetchNotifications();
    } else {
      setLoading(false);
    }
  }, [id, hasAccess]);

  async function fetchUser() {
    try {
      setLoading(true);
      if (id && id !== "add") {
        const res = await apiInstance.get("/api/users/" + id);
        const data = res.data;
        setEmail(data.email);
        setImageUrl(data.avtar);
        setSelListings((arr) => [...(data.listingIds || [])]);

        data.emergencyPhoneCountryCode = (
          (data.emergencyPhone || "").split("-")[0] || ""
        ).replace("+", "");
        data.emergencyPhone = (data.emergencyPhone || "").split("-")[1] || "";
        data.phoneCountryCode = (
          (data.phone || "").split("-")[0] || ""
        ).replace("+", "");
        data.phone = (data.phone || "").split("-")[1] || "";

        form.setFieldsValue(data);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } else {
        setLoading(false);
      }
    } catch (error) {
      const message = error?.response?.data;
      console.log(error);
      manageToast(false, `${message?.err}` || "Something went wrong.");
      setLoading(false);
    }
  }

  async function fetchNotifications() {
    setLoading(true);
    try {
      setLoading(true);
      let url = `/api/users/${id}/notification-settings`;
      const res = await apiInstance.get(url);
      const data = convertArrayToFormData(res.data);
      form.setFieldsValue(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  // const getListings = async (search) => {
  //   try {
  //     let url = `/properties/propertiesList?page=1&pageSize=10`
  //     if (search) {
  //       url = url + "&search=" + search
  //     }
  //     const res = await apiInstance.get(url)
  //     setListings(res?.data?.propertiesList || []);
  //   } catch (error) {
  //     console.log(error)
  //   }
  // };

  const validateForm = async () => {
    try {
      await form.validateFields();
      return {};
    } catch (errorInfo) {
      const errors = {};
      errorInfo.errorFields.forEach((field) => {
        errors[field.name[0]] = field.errors[0];
      });

      const errorMessages = Object.values(errors);
      const errorMessage = errorMessages.join(". \n");
      manageToast(false, errorMessage);
      return false;
    }
  };

  const onSubmit = async (data) => {
    if (imageFile) {
      const url = await uploadFile(imageFile);
      if (url && typeof url === "string") {
        data.avtar = url;
        setImageFile(null);
      }
    }
    const phoneCountryCode = data.phoneCountryCode;
    const emergencyPhoneCountryCode = data.emergencyPhoneCountryCode;
    if (data.phone && data.phoneCountryCode) {
      data.phone = "+" + phoneCountryCode + "-" + data.phone;
    }
    if (data.emergencyPhone && data.emergencyPhoneCountryCode) {
      data.emergencyPhone =
        "+" + emergencyPhoneCountryCode + "-" + data.emergencyPhone;
    }
    if (!data.userGroupId) {
      data.userGroupId = null;
    }
    try {
      const notificationSettings = data.notifications;
      delete data.notifications;
      data.listingIds = selListings;
      setSending(true);
      let res = null;
      if (id !== "add") {
        res = await apiInstance.put("/api/users/" + id, { ...data });
      } else {
        res = await apiInstance.post("/api/users", { ...data });
      }
      if (res.status === 200) {
        const array = convertFormDataToArray(
          notificationSettings,
          res.data._id,
          0
        );
        updateNotificationSettings(array, res.data._id);
      }
      setSending(false);
    } catch (error) {
      manageToast(
        false,
        error?.response?.data?.message || "Something went wrong."
      );
      setSending(false);
    }
  };

  const updateNotificationSettings = async (data, userId) => {
    try {
      setSending(true);
      const res = await apiInstance.put(
        `/api/users/${userId}/notification-settings`,
        { list: data }
      );
      if (res.status === 200) {
        manageToast(true, "Saved Successfully");
        if (id === "add") {
          setTimeout(() => {
            navigate(USER_MANAGEMENT_USERS);
          }, 1500);
        }
      }
      setSending(false);
    } catch (error) {
      console.log(error);
      manageToast(false, "Invalid Id");
      setSending(false);
    }
  };

  async function fetchGroups() {
    try {
      let url = `/api/group-rights`;
      const list = await apiInstance.get(url);
      setGroups((arr) => [...list.data]);
    } catch (error) {
      console.log(error);
    }
  }

  if (profile?._id && !hasAccess) {
    return <NoAccess />;
  }

  return (
    <div id="user-table" className=" bg-white relative">
      <Form
        name="user"
        initialValues={initialValues}
        onFinish={onSubmit}
        autoComplete="off"
        form={form}
      >
        <div className="p-5 flex items-center justify-between border-b-2 bg-white fixed left-0 md:left-[257px] top-12 right-0 z-[999]">
          <div
            className="font-bold text-md text-themeGreen flex items-center justify-between hover:underline cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIosIcon style={{ fontSize: 12 }} /> Users
          </div>

          <div className="flex items-center gap-3">
            {!id && (
              <>
                <p className="text-themeGrey">
                  Send registeration email to the user
                </p>
                <Checkbox />
              </>
            )}
            <GenericButton
              loadingClass="text-white"
              isLoading={sending}
              disabled={sending}
              onClick={validateForm}
              type="submit"
              label="Save"
              className="bg-buttonTheme hover:!bg-buttonHoverTheme disabled:bg-buttonTheme disabled:hover:!bg-buttonTheme"
            />
            <GenericButton
              disabled={sending}
              onClick={() => {
                navigate(USER_MANAGEMENT_USERS);
              }}
              label="Cancel"
              className="bg-labelGreen text-themeGreen disabled:bg-labelGreen disabled:text-themeGreen disabled:opacity-50"
            />
          </div>
        </div>

        {loading ? (
          <div className="h-[50vh] flex justify-center items-center">
            <Loading />
          </div>
        ) : (
          <div className="xl:grid grid-cols-6 gap-4 px-5 pt-[72px]">
            <div className="col-span-5 my-5" id="sections">
              <AddBasicInfo
                admin={admin}
                imageUrl={imageUrl}
                setImageUrl={setImageUrl}
                imageFile={imageFile}
                setImageFile={setImageFile}
                email={email}
                id={id}
                form={form}
              />
              {admin && <AssignCity />}
              {admin && (
                <AccessPermissions
                  formulaNameToShowTotalPriceForm={
                    formulaNameToShowTotalPriceForm
                  }
                  form={form}
                  groups={groups}
                  accessContactData={accessContactData}
                  isAdmin={isAdmin}
                />
              )}
              {admin && <NotificationsPermissions />}
              {/* <ListingAccess getListings={getListings} listings={listings} searchList={searchList} setSearchList={setSearchList}
              selListings={selListings} setSelListings={setSelListings} /> */}
            </div>
            {admin && (
              <div className="my-5 text-themeGrey font-bold text-md fixed top-[115px] right-0 md:flex hidden">
                <ul>
                  {[
                    "BASIC INFO",
                    "ACCESS PERMISSIONS",
                    "NOTIFICATIONS",
                    "LISTINGS ACCESS",
                  ].map((e, i) => {
                    return (
                      <li
                        key={i}
                        className={`mb-7 cursor-pointer hover:underline ${
                          i === 0 ? "text-themeGreen" : ""
                        }`}
                      >
                        {e}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        )}
      </Form>
    </div>
  );
}

// <Tabs
//   renderTabBar={(tabBarProps, DefaultTabBar) => {
//     return <DefaultTabBar {...tabBarProps} className="text-md font-bold ">
//       {(node) => {
//         console.log(node)
//         return <li className={`mb-7 cursor-pointer hover:underline ${tabBarProps.activeKey === node.key ? "text-themeGreen" : ""}`}>
//           {node}
//         </li>
//       }}
//     </DefaultTabBar>
//   }}
//   defaultActiveKey={1}
//   tabPosition="right"
//   items={[
//     { key: 1, label: "BASIC INFO", children: <AddBasicInfo imageUrl={imageUrl} setImageUrl={setImageUrl} imageFile={imageFile} setImageFile={setImageFile} id={id} form={form} />, },
//     { key: 2, label: "ACCESS PERMISSIONS", children: <AccessPermissions formulaNameToShowTotalPriceForm={formulaNameToShowTotalPriceForm} form={form} groups={groups} accessContactData={accessContactData} isAdmin={isAdmin} /> },
//     { key: 3, label: "NOTIFICATIONS", children: <NotificationsPermissions /> },
//     { key: 4, label: "LISTINGS ACCESS", children: <ListingAccess getListings={getListings} listings={listings} searchList={searchList} setSearchList={setSearchList} selListings={selListings} setSelListings={setSelListings} /> }
//   ]} />
