/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Badge, Button, Form } from "antd";
import ForumIcon from "@mui/icons-material/Forum";
import dayjs from "dayjs";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Collapse } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import StarIcon from "@mui/icons-material/Star";
import KeyIcon from "@mui/icons-material/Key";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { useNavigate } from "react-router-dom";
import {
  CALENDAR_MONTHLY,
  INBOX_MESSAGES,
  RESERVATIONS,
} from "../../constants/dashboardRoutes";
import { getPaymentColor, getPaymentStatus, initialValues } from "./Helper";
import { manageToast } from "../../components/Common/ManageToast";
import GenericButton from "../../components/GenericButton/GenericButton";
import TasksPanel from "../../components/Reservations/TasksPanel";
import AttachmentsPanel from "../../components/Reservations/AttachmentsPanel";
import DoorCodePanel from "../../components/Reservations/DoorCodePanel";
import CustomFieldPanel from "../../components/Reservations/CustomFieldPanel";
import AddPaymentDetailsModal from "../../components/Reservations/PaymentPanel/AddPaymentDetailsModal";
import SelectListingModal from "../../components/Reservations/PaymentPanel/SelectListingModal";
import GuestReservationPanel from "../../components/Reservations/GuestReservationPanel";
import PaymentPanel from "../../components/Reservations/PaymentPanel/PaymentPanel";
import { Person2 } from "@mui/icons-material";
// import ExpensesAndExtras from "../../components/Reservations/ExpensesAndExtras";
import CancelReservationModal from "../../components/Reservations/CancelReservationModal";
import { differenceInDays, format } from "date-fns";
import AddForceFullyReservationModal from "../../components/Reservations/AddForceFullyReservationModal";
import { apiInstance } from "../../utils/API";
import Loading from "../../components/Loaders/Loading";
import { findDifferentValues } from "../../utils/helper";
import { AppContext } from "../../AppContext";
import NoAccess from "../../components/NoAccess";
const { Panel } = Collapse;

const AddReservation = () => {
  const profile = useContext(AppContext).profile || {};
  const hasCreateAccess = profile.createReservation;
  const hasReadAccess = profile.readReservation;
  const hasUpdateAccess = profile.updateReservation;
  const hasDeleteAccess = profile.deleteReservation;
  const { id } = useParams();
  const { pathname } = useLocation();
  const search = useLocation().search;
  const listingID_ = new URLSearchParams(search).get("listing");
  const checkInDate_ = new URLSearchParams(search).get("checkin");

  const isAdd = id === "add";
  let isEdit = id !== "add";
  const navigate = useNavigate();
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [selectListingModalOpen, setSelectListingModalOpen] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [currentSelectedListing, setCurrentSelectedListing] = useState(null);
  const [listings, setListings] = useState([]);
  const [listingID, setListingID] = useState("");
  const [priceData, setPriceData] = useState(null);
  const [priceDataLoading, setPriceDataLoading] = useState(false);

  const [attachment, setAttachment] = useState([]);
  const [reservationData, setReservationData] = useState(null);
  const [reservationStatus, setReservationStatus] = useState(false);
  const [hasErros, sethasErros] = useState(false);
  const [confirmData, setConfirmData] = useState(false);
  const [error, setError] = useState("");
  const isInactive =
    reservationData?.status === "Canceled" ||
    reservationData?.status === "Request";
  const isDetails = pathname === RESERVATIONS + "/" + id + "/details";

  useEffect(() => {
    if (checkInDate_) {
      form.setFieldValue(["guest", "checkInDate"], dayjs(checkInDate_));
    }
  }, []);

  useEffect(() => {
    if (!isEdit) {
      setLoading(false);
    }
    if (hasReadAccess || hasUpdateAccess) {
      getListings();
    }
  }, [id, hasReadAccess, hasUpdateAccess]);

  async function fetchReservationDetails(list) {
    try {
      setLoading(true);
      if (id) {
        const res = await apiInstance.get(
          "/reservation/reservationDetails/" + id
        );
        const data = res.data?.reservationRecord;
        if (!data) {
          manageToast(false, "No reservation found");
        } else {
          setReservationData(data);
          const checkInDate = data.guest.checkInDate;
          const checkOutDate = data.guest.checkOutDate;

          data.guest.checkInDate = !checkInDate ? null : dayjs(checkInDate);
          data.guest.checkOutDate = !checkOutDate ? null : dayjs(checkOutDate);

          // getPrice(data.listingId, data.guest.checkInDate, data.guest.checkOutDate)

          const phone = (data.guest.phone || "")
            .toString()
            .replace("+", "")
            .replaceAll(" ", "");
          if (phone.includes("-")) {
            data.guest.phoneCountryCode = phone.split("-")[0] || "";
            data.guest.phone = phone.split("-")[1] || "";
          } else {
            data.guest.phoneCountryCode = "";
            data.guest.phone = phone;
          }
          setPaymentData((arr) => [...(data.payment || [])]);
          setAttachment((arr) => [...(data?.attachment?.attachmentLink || [])]);
          setCurrentSelectedListing((od) => {
            return list.find((e) => e.ruId === data.listingId);
          });
          setListingID(data.listingId);
          form.setFieldValue(["guest", "listingID"], data.listingId);
          form.setFieldsValue(data);

          setLoading(false);
        }
      }
    } catch (error) {
      const e = error?.response?.data;
      console.log(error);
      manageToast(
        false,
        e?.message || error.message || "Something went wrong."
      );
      // setLoading(false)
    }
  }

  const getListings = () => {
    apiInstance
      .get(`/reservation/get-propertyForReservation`)
      .then((response) => {
        if (response.status === 200) {
          const list = response?.data?.data || [];
          setListings(list);
          if (listingID_) {
            form.setFieldValue(["guest", "listingID"], listingID_);
            const listingObj = list.find((el) => el.ruId === listingID_);
            if (listingObj) {
              setListingID(listingID_);
              setCurrentSelectedListing(listingObj);

              // if (checkInDate_) {
              //   const date = dayjs(checkInDate_);
              //   const checkOutDate = date.add(
              //     parseInt(
              //       "" + listingObj?.additionalInfo?.minimum_nights || "1"
              //     ) || 1,
              //     "day"
              //   );
              //   form.setFieldValue(["guest", "checkOutDate"], checkOutDate);
              // }

              form.setFieldValue(
                ["guest", "checkInTime"],
                listingObj.additionalInfo.check_in_time_start
              );
              form.setFieldValue(
                ["guest", "checkOutTime"],
                listingObj.additionalInfo.check_in_time_end
              );
              form.setFieldValue(
                ["guest", "guestCount"],
                listingObj.basicInfo.person_capacity
              );

              form.setFieldValue(
                ["guest", "currency"],
                listingObj.priceAndFees?.currency
              );
              form.setFieldValue(
                ["guest", "basePrice"],
                listingObj.priceAndFees?.basePrice
              );
              form.setFieldValue(
                ["guest", "cleaningFee"],
                listingObj.priceAndFees?.cleaningFee
              );
            }
          }
          if (id && isEdit) {
            fetchReservationDetails(list);
          } else {
            setLoading(false);
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const getPrice = async (listingID, checkInDate, checkOutDate) => {
    try {
      setPriceData(null);
      if (listingID && checkInDate && checkOutDate) {
        const from = checkInDate.format("YYYY-MM-DD");
        const to = checkOutDate.format("YYYY-MM-DD");
        if (
          from === reservationData?.guest?.checkInDate?.format("YYYY-MM-DD") &&
          to === reservationData?.guest?.checkOutDate?.format("YYYY-MM-DD")
        ) {
          return;
        }
        setPriceDataLoading(true);
        const response = await apiInstance.get(
          `/ru/reservation/price?listingID=${listingID}&checkInDate=${from}&checkOutDate=${to}&reservationId=${
            reservationData?.reservationId || ""
          }`
        );
        const updatedData = response?.data;
        if (updatedData) {
          updatedData.originalPrice =
            updatedData.rentPriceWitoutDiscount + updatedData.cleaningPrice;
        }
        setPriceData(updatedData);
        if (updatedData?.currency)
          form.setFieldValue(["guest", "currency"], updatedData?.currency);

        setPriceDataLoading(false);
      }
    } catch (e) {
      setPriceDataLoading(false);
    }
  };

  const handlePaymentData = (data) => {
    try {
      setPaymentData((arr) => [...arr, data]);
      setIsPaymentModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAttachment = (data) => {
    setAttachment(data);
  };

  const onSubmit = async (data) => {
    try {
      // const maximumNights = parseInt(
      //   currentSelectedListing?.additionalInfo?.maximum_nights || "10000"
      // );
      // const diff = data.guest.checkOutDate.diff(data.guest.checkInDate, "day");

      // if (diff > maximumNights) {
      //   manageToast(false, "Maximum night stay is " + maximumNights);
      //   return;
      // }
      if (data.guest.checkOutDate.diff(dayjs(new Date()), "day") < 0) {
        manageToast(false, "Check out date should be a future date");
        return;
      }
      data.isExtension = true;
      data.guest.checkInDate = data.guest.checkInDate.format("YYYY-MM-DD");
      data.guest.checkOutDate = data.guest.checkOutDate.format("YYYY-MM-DD");

      if (
        (isAdd ||
          data.guest.checkInDate !==
            reservationData?.guest?.checkInDate?.format("YYYY-MM-DD") ||
          data.guest.checkOutDate !==
            reservationData?.guest?.checkOutDate?.format("YYYY-MM-DD")) &&
        !priceData
      ) {
        manageToast(false, "Please wait, we are calculating the prices.");
        if (!priceDataLoading)
          getPrice(listingID, data.guest.checkInDate, data.guest.checkOutDate);
        return;
      }

      if (priceData) {
        data.finalPrice = priceData.ruPrice;
        data.originalPrice = priceData.originalPrice;
        data.otherPrices = {
          rent: priceData.rentPriceWitoutDiscount,
          cleaningPrice: priceData.cleaningPrice,
          lastMinuteDiscountAmount: priceData.lastMinuteDiscountAmount,
          lastMinuteDiscount: priceData.lastMinuteDiscount,
          longStayDiscountAmount: priceData.longStayDiscountAmount,
          longStayDiscount: priceData.longStayDiscount,
        };
      } else {
        data.finalPrice = reservationData?.finalPrice;
        data.otherPrices = reservationData?.otherPrices || {
          rent: 0,
          cleaningPrice: 0,
          lastMinuteDiscountAmount: 0,
          longStayDiscountAmount: 0,
          lastMinuteDiscount: 0,
          longStayDiscount: 0,
        };
      }

      data.guest.listingName =
        currentSelectedListing?.basicInfo?.internal_listing_name;

      if (currentSelectedListing?.additionalInfo?.cancellationPolicy?._id) {
        const policy =
          currentSelectedListing?.additionalInfo?.cancellationPolicy || {};
        data.guest.cancellationPolicy = data.guest.cancellationPolicy || {};
        data.guest.cancellationPolicy.cancellationPolicyName =
          policy?.cancellationPolicyName;
        data.guest.cancellationPolicy.from = policy?.from;
        data.guest.cancellationPolicy.to = policy?.to;
        data.guest.cancellationPolicy.refundPercentage =
          policy?.refundPercentage;
      }
      const phoneCountryCode = data.guest.phoneCountryCode || "0";
      if (data.guest.phone) {
        data.guest.phone = "+" + phoneCountryCode + "-" + data.guest.phone;
      }
      delete data.guest.phoneCountryCode;

      data.payment = paymentData || [];
      data.attachment = {
        attachmentLink: attachment,
      };

      setSending(true);
      let res = null;
      if (isEdit) {
        data.reservationId = reservationData.reservationId;
        data._id = reservationData._id;

        const difference = findDifferentValues(data, reservationData);
        data.differences = difference;

        res = await apiInstance.put(
          "/reservation/reservation/" +
            id +
            `?userName=${localStorage.getItem("userName")}`,
          {
            ...data,
          }
        );
        if (res.status === 200) {
          if (res.data?.updatedReservation) {
            const data2 = res.data.updatedReservation;
            const checkInDate = data2.guest.checkInDate;
            const checkOutDate = data2.guest.checkOutDate;

            data2.guest.checkInDate = !checkInDate ? null : dayjs(checkInDate);
            data2.guest.checkOutDate = !checkOutDate
              ? null
              : dayjs(checkOutDate);

            setReservationData(data2);
          }
          setPriceData(null);
          manageToast(true, "Updated Successfully");
        }
      } else {
        res = await apiInstance.post(
          "/reservation/reservation" +
            `?userName=${localStorage.getItem("userName")}`,
          { ...data }
        );
        if (res.status === 200) {
          manageToast(true, "Submitted Successfully");
          navigate(RESERVATIONS);
        }
      }
      setSending(false);
    } catch (error) {
      console.log(error);
      setSending(false);
      const confiltCode = error?.response?.data?.code === "CONFLICT";
      if (confiltCode) {
        setError(
          error?.response?.data?.message ||
            error?.response?.data?.err ||
            "Something went wrong."
        );
        // setConfirmData(data);
        await forceSubmit(data);
      } else {
        manageToast(
          false,
          error?.response?.data?.message ||
            error?.response?.data?.err ||
            "Something went wrong."
        );
      }
    }
  };

  const forceSubmit = async (data) => {
    try {
      setSending(true);
      let res = null;
      data.force = true;
      if (data._id) {
        res = await apiInstance.put(
          "/reservation/reservation/" + data.reservationId,
          { ...data }
        );
      } else {
        res = await apiInstance.post("/reservation/reservation", { ...data });
      }
      if (res.status === 200) {
        manageToast(true, "Saved Successfully");
        navigate(RESERVATIONS);
      }
      setSending(false);
    } catch (error) {
      manageToast(
        false,
        error?.response?.data?.message ||
          error?.response?.data?.err ||
          "Something went wrong."
      );
      setSending(false);
    }
  };

  const handleDeleteReservation = async (id) => {
    try {
      const response = await apiInstance.delete(
        `/reservation/reservation/${id}`
      );
      if (response.status === 200) {
        manageToast(true, "Reservation deleted successfully.");
        navigate(RESERVATIONS);
      }
    } catch (error) {
      manageToast(false, "Error deleting reservation!");
      console.log(error);
    }
  };

  if (profile._id && !hasCreateAccess && isAdd) {
    return <NoAccess />;
  }

  if (profile._id && !hasUpdateAccess && isEdit && !isDetails) {
    return <NoAccess />;
  }

  if (profile._id && hasReadAccess && !isEdit && isDetails) {
    return <NoAccess />;
  }
  return (
    <div className="bg-white relative">
      <AddForceFullyReservationModal
        error={error}
        data={confirmData}
        setConfirmData={setConfirmData}
        isModalOpen={!!confirmData}
        setIsModalOpen={setConfirmData}
      />

      <CancelReservationModal
        getListings={() => getListings()}
        reservationId={reservationData?.reservationId}
        id={reservationData?._id}
        status={reservationData?.status}
        reservationStatus={reservationStatus}
        setReservationStatus={setReservationStatus}
      />

      <AddPaymentDetailsModal
        isDetails={isDetails}
        isModalOpen={isPaymentModalOpen}
        setIsModalOpen={setIsPaymentModalOpen}
        handlePaymentData={handlePaymentData}
      />

      <SelectListingModal
        isDetails={isDetails}
        selectListingModalOpen={selectListingModalOpen}
        setSelectListingModalOpen={setSelectListingModalOpen}
      />

      <Form
        onFieldsChange={(e) => {
          if (e.length === 1) {
            sethasErros(false);
          }
        }}
        onFinishFailed={(e) => {
          sethasErros(e.errorFields.length > 0);
        }}
        name="basic"
        initialValues={initialValues}
        onFinish={isDetails ? null : onSubmit}
        autoComplete="off"
        form={form}
      >
        <div className="py-5 md:px-5 px-2 flex md:flex-nowrap flex-wrap gap-2 md:gap-0 border-b-2 items-center justify-between fixed bg-white md:left-[257px] left-0 right-0 z-[9]">
          <Link className="text-themeGreen" to={RESERVATIONS}>
            <ArrowBackIosNewIcon style={{ fontSize: 16 }} /> Reservations
          </Link>
          <div className="flex flex-wrap md:flex-nowrap  gap-2">
            {isDetails ? (
              !loading && (
                <>
                  {hasUpdateAccess && (
                    <Button
                      className="bg-themeGreen text-white disabled:bg-buttonTheme disabled:hover:!bg-buttonTheme disabled:text-white disabled:opacity-50"
                      htmlType="button"
                      onClick={() => {
                        navigate(`${RESERVATIONS}/${id}`);
                      }}
                      disabled={sending || loading}
                    >
                      <div
                        className={`flex items-center justify-around w-auto}`}
                      >
                        <BorderColorIcon style={{ fontSize: 12 }} />
                        <p className="ml-1">Edit</p>
                      </div>
                    </Button>
                  )}
                  <Link
                    to={
                      CALENDAR_MONTHLY +
                      "?listing=" +
                      reservationData?.listingId +
                      "&date=" +
                      format(
                        new Date(reservationData?.guest?.checkInDate),
                        "MMM yyyy"
                      )
                    }
                  >
                    <GenericButton
                      type="button"
                      label="Calendar"
                      className="bg-labelGreen text-themeGreen"
                      iconLeftAlign={true}
                      icon={
                        <CalendarMonthIcon
                          className="cursor-pointer"
                          style={{ fontSize: 12 }}
                        />
                      }
                    />
                  </Link>
                  <Button
                    className="bg-labelGreen text-themeGreen"
                    htmlType="button"
                    // onClick={() => { navigate(`${RESERVATIONS}/${id}`) }}
                    disabled={true}
                  >
                    <div className={`flex items-center justify-around w-auto}`}>
                      <Person2 style={{ fontSize: 12 }} />
                      <p className="ml-1">Guest Portal</p>
                    </div>
                  </Button>
                  <Link
                    to={
                      INBOX_MESSAGES +
                      "?reservationId=" +
                      reservationData?.reservationId
                    }
                  >
                    <GenericButton
                      type="button"
                      label="Messages"
                      className="bg-labelGreen text-themeGreen"
                      iconLeftAlign={true}
                      icon={
                        <ForumIcon
                          className="cursor-pointer"
                          style={{ fontSize: 12 }}
                        />
                      }
                    />
                  </Link>
                </>
              )
            ) : (
              <>
                <GenericButton
                  loadingClass="text-white"
                  isLoading={sending}
                  disabled={
                    differenceInDays(
                      new Date(reservationData?.checkOutDate),
                      new Date()
                    ) < 0 ||
                    reservationData?.status === "Canceled" ||
                    sending ||
                    loading
                  }
                  type="submit"
                  label="Save"
                  className="bg-themeGreen hover:!text-white text-white disabled:bg-buttonTheme disabled:hover:!bg-buttonTheme disabled:text-white"
                />
                <GenericButton
                  type="button"
                  disabled={sending}
                  onClick={() => {
                    navigate(RESERVATIONS);
                  }}
                  label="Cancel"
                  className="bg-labelGreen text-themeGreen disabled:bg-labelGreen disabled:text-themeGreen disabled:opacity-50"
                />
              </>
            )}
            {!isAdd && !loading && (
              <>
                {hasDeleteAccess &&
                  reservationData?.status !== "Canceled" &&
                  (reservationData?.channel === "direct" ||
                    reservationData?.channel === "Direct" ||
                    reservationData?.channel === "Pms" ||
                    reservationData?.channel === "PMS") &&
                  differenceInDays(
                    new Date(reservationData?.checkOutDate),
                    new Date()
                  ) > 0 && (
                    <GenericButton
                      type="button"
                      disabled={sending}
                      onClick={() => {
                        if (reservationData?.reservationId) {
                          setReservationStatus("Canceled");
                        }
                      }}
                      label="Cancel reservation"
                      className="bg-cancelButton hover:!bg-cancelButtonHover text-white hover:!text-white  disabled:text-white disabled:bg-cancelButton disabled:hover:!bg-cancelButton disabled:opacity-50"
                    />
                  )}

                <GenericButton
                  type="button"
                  disabled={sending}
                  onClick={() => {
                    handleDeleteReservation(reservationData?._id);
                  }}
                  label="Delete reservation"
                  className="bg-cancelButton hover:!bg-cancelButtonHover text-white hover:!text-white  disabled:text-white disabled:bg-cancelButton disabled:hover:!bg-cancelButton disabled:opacity-50"
                />

                {reservationData?.status === "Request" && (
                  <GenericButton
                    type="button"
                    disabled={sending}
                    onClick={() => {
                      if (reservationData?.reservationId) {
                        setReservationStatus("Confirmed");
                      }
                    }}
                    label="Confirm reservation"
                    className="bg-lightButtonHoverTheme hover:!bg-lightButtonHoverTheme text-buttonTheme hover:!text-buttonTheme  disabled:text-buttonTheme disabled:bg-lightButtonHoverTheme disabled:hover:!bg-lightButtonHoverTheme disabled:opacity-50"
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="md:pt-[72px] pt-[53px]">
          {loading ? (
            <div className="h-[50vh] flex justify-center items-center">
              <Loading />
            </div>
          ) : (
            <Collapse
              defaultActiveKey="1"
              accordion
              className="bg-white border-none"
              bordered={false}
            >
              <Panel
                className="border-none"
                header={
                  <div className="font-bold text-xl flex items-start gap-2">
                    <CalendarMonthIcon />{" "}
                    <div>
                      {isAdd ? (
                        "Guest Reservation"
                      ) : (
                        <>
                          Guest: {reservationData?.guest?.guestFirstName}{" "}
                          {reservationData?.guest?.guestLastName} / Check-in:{" "}
                          {dayjs(reservationData?.guest?.checkInDate).format(
                            "DD-MM-YYYY"
                          )}{" "}
                          / Status: {reservationData?.status}
                        </>
                      )}{" "}
                      {getPaymentStatus(reservationData) && (
                        <Badge
                          color={getPaymentColor(
                            getPaymentStatus(reservationData)
                          )}
                          count={getPaymentStatus(reservationData)}
                        />
                      )}
                    </div>{" "}
                    {hasErros && (
                      <InfoCircleOutlined className="text-cancelButton" />
                    )}
                  </div>
                }
                key="1"
              >
                <GuestReservationPanel
                  isDetails={isDetails || isInactive}
                  isEdit={isEdit}
                  isAdd={isAdd}
                  reservationData={reservationData}
                  form={form}
                  checkInDate_={checkInDate_}
                  listings={listings}
                  setListingID={setListingID}
                  currentSelectedListing={currentSelectedListing}
                  setCurrentSelectedListing={setCurrentSelectedListing}
                  getPrice={getPrice}
                  setPriceData={setPriceData}
                  priceData={priceData}
                  priceDataLoading={priceDataLoading}
                />
              </Panel>
              <Panel
                header={
                  <div className="font-bold text-xl flex items-center gap-2">
                    <AttachMoneyIcon /> <div>Payment</div>
                  </div>
                }
                key="2"
              >
                <PaymentPanel
                  isDetails={isDetails || isInactive}
                  paymentData={paymentData}
                  handlePaymentData={setPaymentData}
                  setIsPaymentModalOpen={setIsPaymentModalOpen}
                  setSelectListingModalOpen={setSelectListingModalOpen}
                  listingID={listingID}
                />
              </Panel>

              {/* {(isDetails || isEdit) && <Panel
            header={
              <div className="font-bold text-xl flex items-center gap-2">
                <AttachMoneyIcon /> <div>Expenses and extras</div>
              </div>
            }
            key="4">
            <ExpensesAndExtras />
          </Panel>} */}

              <Panel
                header={
                  <div className="font-bold text-xl flex items-center gap-2">
                    <StarIcon /> <div>Custom Field</div>
                  </div>
                }
                key="5"
              >
                <CustomFieldPanel />
              </Panel>
              <Panel
                header={
                  <div className="font-bold text-xl flex items-center gap-2">
                    <KeyIcon /> <div>Door code</div>
                  </div>
                }
                key="6"
              >
                <DoorCodePanel isDetails={isDetails || isInactive} />
              </Panel>
              <Panel
                header={
                  <div className="font-bold text-xl flex items-center gap-2">
                    <ReceiptIcon /> <div>Attachment</div>
                  </div>
                }
                key="7"
              >
                <AttachmentsPanel
                  attachmentData={attachment}
                  handleAttachment={handleAttachment}
                />
              </Panel>
              <Panel
                header={
                  <div className="font-bold text-xl flex items-center gap-2">
                    <BusinessCenterIcon /> <div>Tasks</div>
                  </div>
                }
                key="8"
              >
                <TasksPanel />
              </Panel>
            </Collapse>
          )}
        </div>
      </Form>
    </div>
  );
};

export default AddReservation;
