export const country = [
  {
    // value: "8009",
    value: "United Kingdom",
    label: "United Kingdom",
  },
  {
    value: "6807",
    label: "Spain",
  },
  {
    value: "76074",
    label: "France",
  },
  {
    value: "55443",
    label: "Isle of Man",
  },
  {
    value: "58829",
    label: "Bouvet Island",
  },
  {
    value: "58834",
    label: "Comoros",
  },
  {
    value: "58835",
    label: "Falkland Islands",
  },
  {
    value: "58836",
    label: "French Southern Territories",
  },
  {
    value: "58838",
    label: "Holy See",
  },
  {
    value: "58841",
    label: "Mayotte",
  },
  {
    value: "58842",
    label: "Nauru",
  },
  {
    value: "58843",
    label: "Niue",
  },
  {
    value: "58844",
    label: "Palau",
  },
  {
    value: "58847",
    label: "Pitcairn",
  },
  {
    value: "58848",
    label: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    value: "58849",
    label: "Saint Pierre and Miquelon",
  },
  {
    value: "58850",
    label: "South Georgia and the South Sandwich Islands",
  },
  {
    value: "58851",
    label: "Svalbard and Jan Mayen",
  },
  {
    value: "58852",
    label: "Timor-Leste",
  },
  {
    value: "58853",
    label: "Tokelau",
  },
  {
    value: "58855",
    label: "United States Minor Outlying Islands",
  },
  {
    value: "9",
    label: "Austria",
  },
  {
    value: "10",
    label: "Belgium",
  },
  {
    value: "11",
    label: "Belarus",
  },
  {
    value: "12",
    label: "Bosnia and Herzegovina",
  },
  {
    value: "13",
    label: "Bulgaria",
  },
  {
    value: "14",
    label: "Croatia",
  },
  {
    value: "15",
    label: "Cyprus",
  },
  {
    value: "16",
    label: "Czech Republic",
  },
  {
    value: "17",
    label: "Denmark",
  },
  {
    value: "18",
    label: "Estonia",
  },
  {
    value: "19",
    label: "Finland",
  },
  {
    value: "20",
    label: "France",
  },
  {
    value: "21",
    label: "Germany",
  },
  {
    value: "22",
    label: "Greece",
  },
  {
    value: "23",
    label: "Hungary",
  },
  {
    value: "24",
    label: "Israel",
  },
  {
    value: "25",
    label: "Italy",
  },
  {
    value: "26",
    label: "Latvia",
  },
  {
    value: "27",
    label: "Liechtenstein",
  },
  {
    value: "28",
    label: "Lithuania",
  },
  {
    value: "29",
    label: "Luxembourg",
  },
  {
    value: "30",
    label: "Macedonia",
  },
  {
    value: "31",
    label: "Malta",
  },
  {
    value: "32",
    label: "Netherlands",
  },
  {
    value: "33",
    label: "Poland",
  },
  {
    value: "34",
    label: "Portugal",
  },
  {
    value: "35",
    label: "Ireland",
  },
  {
    value: "36",
    label: "Romania",
  },
  {
    value: "37",
    label: "Russia",
  },
  {
    value: "38",
    label: "Slovakia",
  },
  {
    value: "39",
    label: "Slovenia",
  },
  {
    value: "40",
    label: "Spain",
  },
  {
    value: "41",
    label: "Sweden",
  },
  {
    value: "42",
    label: "Switzerland",
  },
  {
    value: "43",
    label: "Turkey",
  },
  {
    value: "44",
    label: "Ukraine",
  },
  {
    value: "45",
    label: "United Kingdom",
  },
  {
    value: "46",
    label: "Serbia",
  },
  {
    value: "47",
    label: "Montenegro",
  },
  {
    value: "317",
    label: "United Arab Emirates",
  },
  {
    value: "318",
    label: "Oman",
  },
  {
    value: "327",
    label: "Canada",
  },
  {
    value: "328",
    label: "Costa Rica",
  },
  {
    value: "330",
    label: "United States",
  },
  {
    value: "331",
    label: "Argentina",
  },
  {
    value: "332",
    label: "Brazil",
  },
  {
    value: "333",
    label: "Peru",
  },
  {
    value: "334",
    label: "Uruguay",
  },
  {
    value: "335",
    label: "China",
  },
  {
    value: "336",
    label: "India",
  },
  {
    value: "337",
    label: "Japan",
  },
  {
    value: "338",
    label: "Singapore",
  },
  {
    value: "339",
    label: "South Korea",
  },
  {
    value: "340",
    label: "Thailand",
  },
  {
    value: "342",
    label: "Gambia",
  },
  {
    value: "343",
    label: "Kenya",
  },
  {
    value: "344",
    label: "Morocco",
  },
  {
    value: "345",
    label: "South Africa",
  },
  {
    value: "8454",
    label: "Australia",
  },
  {
    value: "8455",
    label: "Norway",
  },
  {
    value: "8457",
    label: "Andorra",
  },
  {
    value: "8729",
    label: "Uganda",
  },
  {
    value: "8731",
    label: "Fiji",
  },
  {
    value: "8740",
    label: "Albania",
  },
  {
    value: "8743",
    label: "Anguilla",
  },
  {
    value: "8745",
    label: "Barbados",
  },
  {
    value: "8764",
    label: "Mexico",
  },
  {
    value: "8868",
    label: "Egypt",
  },
  {
    value: "8869",
    label: "Tanzania",
  },
  {
    value: "8870",
    label: "Indonesia",
  },
  {
    value: "8871",
    label: "Mauritius",
  },
  {
    value: "8872",
    label: "Belize",
  },
  {
    value: "8873",
    label: "Philippines",
  },
  {
    value: "8874",
    label: "Moldova",
  },
  {
    value: "8875",
    label: "Turks & Caicos Islands",
  },
  {
    value: "8876",
    label: "Vietnam",
  },
  {
    value: "8877",
    label: "Colombia",
  },
  {
    value: "9473",
    label: "Kosovo",
  },
  {
    value: "9502",
    label: "Tunisia",
  },
  {
    value: "11151",
    label: "Bahamas",
  },
  {
    value: "11152",
    label: "Dominican Republic",
  },
  {
    value: "11153",
    label: "Ecuador",
  },
  {
    value: "11154",
    label: "Jamaica",
  },
  {
    value: "11155",
    label: "New Zealand",
  },
  {
    value: "11321",
    label: "Aruba",
  },
  {
    value: "11341",
    label: "Lebanon",
  },
  {
    value: "11396",
    label: "Puerto Rico",
  },
  {
    value: "11406",
    label: "Seychelles",
  },
  {
    value: "11411",
    label: "Curacao",
  },
  {
    value: "11421",
    label: "Armenia",
  },
  {
    value: "11455",
    label: "Chile",
  },
  {
    value: "11483",
    label: "Iran",
  },
  {
    value: "11545",
    label: "Sri Lanka",
  },
  {
    value: "11642",
    label: "Iceland",
  },
  {
    value: "11668",
    label: "Antigua & Barbuda",
  },
  {
    value: "11670",
    label: "Benin",
  },
  {
    value: "11673",
    label: "Georgia",
  },
  {
    value: "11675",
    label: "Dominica",
  },
  {
    value: "11681",
    label: "Malaysia",
  },
  {
    value: "11682",
    label: "Panama",
  },
  {
    value: "14489",
    label: "Zimbabwe",
  },
  {
    value: "14499",
    label: "Taiwan",
  },
  {
    value: "14916",
    label: "Saint Lucia",
  },
  {
    value: "15024",
    label: "U.S. Virgin Islands",
  },
  {
    value: "17472",
    label: "Senegal",
  },
  {
    value: "18069",
    label: "Maldives",
  },
  {
    value: "18081",
    label: "Qatar",
  },
  {
    value: "18084",
    label: "Hong Kong",
  },
  {
    value: "18132",
    label: "Guadeloupe",
  },
  {
    value: "18134",
    label: "Republic of the Congo",
  },
  {
    value: "18357",
    label: "Yemen",
  },
  {
    value: "18940",
    label: "British Virgin Islands",
  },
  {
    value: "18945",
    label: "Algeria",
  },
  {
    value: "18946",
    label: "Angola",
  },
  {
    value: "18947",
    label: "Central African Republic",
  },
  {
    value: "18948",
    label: "Chad",
  },
  {
    value: "18949",
    label: "Cote d'Ivoire",
  },
  {
    value: "18950",
    label: "Democratic Republic of the Congo",
  },
  {
    value: "18951",
    label: "Libya",
  },
  {
    value: "18952",
    label: "Iraq",
  },
  {
    value: "18953",
    label: "Burma",
  },
  {
    value: "18954",
    label: "Nigeria",
  },
  {
    value: "18955",
    label: "North Korea",
  },
  {
    value: "18956",
    label: "Pakistan",
  },
  {
    value: "18957",
    label: "Sao Tome and Principe",
  },
  {
    value: "18958",
    label: "Saudi Arabia",
  },
  {
    value: "18959",
    label: "Somalia",
  },
  {
    value: "18960",
    label: "Sudan",
  },
  {
    value: "18961",
    label: "Syria",
  },
  {
    value: "19072",
    label: "French Polynesia",
  },
  {
    value: "19127",
    label: "Caribbean",
  },
  {
    value: "19203",
    label: "Saint Vincent and the Grenadines",
  },
  {
    value: "24839",
    label: "New Caledonia",
  },
  {
    value: "24933",
    label: "Nepal",
  },
  {
    value: "25610",
    label: "Saint-Barthélemy",
  },
  {
    value: "26427",
    label: "Ghana",
  },
  {
    value: "26493",
    label: "Bolivia",
  },
  {
    value: "26497",
    label: "Reunion",
  },
  {
    value: "26499",
    label: "Venezuela",
  },
  {
    value: "26500",
    label: "Bahrain",
  },
  {
    value: "26501",
    label: "Bangladesh",
  },
  {
    value: "26502",
    label: "Botswana",
  },
  {
    value: "26503",
    label: "Brunei",
  },
  {
    value: "26504",
    label: "Burundi",
  },
  {
    value: "26505",
    label: "Cambodia",
  },
  {
    value: "26506",
    label: "Cameroon",
  },
  {
    value: "26507",
    label: "Cape Verde",
  },
  {
    value: "26508",
    label: "Djibouti",
  },
  {
    value: "26509",
    label: "El Salvador",
  },
  {
    value: "26510",
    label: "Eritrea",
  },
  {
    value: "26511",
    label: "Ethiopia",
  },
  {
    value: "26512",
    label: "Gabon",
  },
  {
    value: "26513",
    label: "Grenada",
  },
  {
    value: "26514",
    label: "Guatemala",
  },
  {
    value: "26515",
    label: "Guinea",
  },
  {
    value: "26516",
    label: "Guinea-Bissau",
  },
  {
    value: "26517",
    label: "Guyana",
  },
  {
    value: "26518",
    label: "Haiti",
  },
  {
    value: "26519",
    label: "Honduras",
  },
  {
    value: "26520",
    label: "Ivory Coast",
  },
  {
    value: "26521",
    label: "Jordan",
  },
  {
    value: "26522",
    label: "Kazakhstan",
  },
  {
    value: "26523",
    label: "Kiribati",
  },
  {
    value: "26524",
    label: "Kuwait",
  },
  {
    value: "26525",
    label: "Kyrgyzstan",
  },
  {
    value: "26526",
    label: "Laos",
  },
  {
    value: "26527",
    label: "Lesotho",
  },
  {
    value: "26528",
    label: "Madagascar",
  },
  {
    value: "26529",
    label: "Malawi",
  },
  {
    value: "26530",
    label: "Mali",
  },
  {
    value: "26531",
    label: "Marshall Islands",
  },
  {
    value: "26532",
    label: "Mauritania",
  },
  {
    value: "26533",
    label: "Monaco",
  },
  {
    value: "26534",
    label: "Mozambique",
  },
  {
    value: "26535",
    label: "Namibia",
  },
  {
    value: "26536",
    label: "Nicaragua",
  },
  {
    value: "26537",
    label: "Niger",
  },
  {
    value: "26538",
    label: "Papua New Guinea",
  },
  {
    value: "26539",
    label: "Paraguay",
  },
  {
    value: "26540",
    label: "Rwanda",
  },
  {
    value: "26541",
    label: "Sierra Leone",
  },
  {
    value: "26542",
    label: "Solomon Islands",
  },
  {
    value: "26543",
    label: "Suriname",
  },
  {
    value: "26544",
    label: "Swaziland",
  },
  {
    value: "26545",
    label: "Tajikistan",
  },
  {
    value: "26546",
    label: "Togo",
  },
  {
    value: "26547",
    label: "Tonga",
  },
  {
    value: "26548",
    label: "Tuvalu",
  },
  {
    value: "26549",
    label: "Uzbekistan",
  },
  {
    value: "26550",
    label: "Vanuatu",
  },
  {
    value: "26551",
    label: "Zambia",
  },
  {
    value: "26563",
    label: "Martinique",
  },
  {
    value: "26579",
    label: "Equitorial Guinea",
  },
  {
    value: "26601",
    label: "Aland Islands",
  },
  {
    value: "45796",
    label: "Greenland",
  },
  {
    value: "46039",
    label: "Trinidad and Tobago",
  },
  {
    value: "46848",
    label: "Mongolia",
  },
  {
    value: "47233",
    label: "Bermuda",
  },
  {
    value: "47814",
    label: "Burkina Faso",
  },
  {
    value: "47818",
    label: "Federated States of Micronesia",
  },
  {
    value: "47823",
    label: "Myanmar",
  },
  {
    value: "55505",
    label: "Gibraltar",
  },
  {
    value: "57296",
    label: "Jersey",
  },
  {
    value: "58826",
    label: "Afghanistan",
  },
  {
    value: "58827",
    label: "Azerbaijan",
  },
  {
    value: "58828",
    label: "Bhutan",
  },
  {
    value: "58830",
    label: "British Indian Ocean Territory",
  },
  {
    value: "58832",
    label: "Christmas Island",
  },
  {
    value: "58833",
    label: "Cocos",
  },
  {
    value: "58837",
    label: "Guernsey",
  },
  {
    value: "58839",
    label: "Liberia",
  },
  {
    value: "58846",
    label: "Palestine, State of",
  },
  {
    value: "58854",
    label: "Turkmenistan",
  },
  {
    value: "329",
    label: "Cuba",
  },
  {
    value: "26577",
    label: "Cayman Islands",
  },
  {
    value: "47813",
    label: "Bonaire",
  },
  {
    value: "47816",
    label: "Cook Islands",
  },
  {
    value: "47842",
    label: "St. Kitts & Nevis",
  },
  {
    value: "47817",
    label: "Faroe Islands",
  },
];
