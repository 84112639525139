import React, { useEffect, useState } from "react";
import AppProvider from "./context/AppProvider";
import "./App.css";
import "./styles/custom.css";
import AppRouter from "./routes/AppRouter";
import { ToastContainer } from "react-toastify";
import { apiInstance, routes } from "./utils/API";

function App() {
  const [data, setData] = useState(null);

  useEffect(() => {
    console.log(localStorage.getItem("authorizationToken"));
    if (localStorage.getItem("authorizationToken")) {
      // Function to fetch and update data
      const fetchData = async () => {
        try {
          // Perform API request to fetch data
          const response = await apiInstance.get(routes.RESERVATION_PROPERTIES);

          // Store data in localStorage
          localStorage.setItem(
            "reservationProperties",
            JSON.stringify(response)
          );

          // Update state with new data
          setData(response);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      // Fetch data initially
      fetchData();

      // Schedule data update every 24 hours
      if (data !== null) {
        const intervalId = setInterval(fetchData, 24 * 60 * 60 * 1000);

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
      }
    }
  }, []);

  return (
    <div className="App">
      <AppProvider>
        <ToastContainer style={{ zIndex: 99999999 }} />
        <AppRouter />
      </AppProvider>
    </div>
  );
}

export default App;
