import React, { useState, useMemo, useEffect } from "react";
import { Select, Input, Form } from "antd";
import SelectWithSearch from "react-select";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import GenericInput from "../GenericInput/GenericInput";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { LANGUAGES } from "../../constants/additionalInfoOptions";
import Typography from "antd/es/typography/Typography";
import { Controller } from "react-hook-form";
import { countriesData } from "../countriesData";

const { Option } = Select;

const ContactPersonAndInvoicing = ({
  payload,
  setPayload,
  errors,
  control,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(
    payload?.contactPersonAndInvoicing?.country_code
  );
  const [selectedAlternateCountry, setSelectedAlternateCountry] = useState(
    payload?.contactPersonAndInvoicing?.alternate_country_code
  );

  const [form] = Form.useForm();

  const renderCountry = useMemo(() => {
    return countriesData.map((country, index) => (
      <Option key={country.code} value={country.code}>
        {country.flag}
      </Option>
    ));
  }, []);

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setPayload({
      ...payload,
      contactPersonAndInvoicing: {
        ...payload.contactPersonAndInvoicing,
        ["country_code"]: value,
      },
    });

    if (selectedAlternateCountry === undefined) {
      setSelectedAlternateCountry(value);
      setPayload({
        ...payload,
        contactPersonAndInvoicing: {
          ...payload.contactPersonAndInvoicing,
          ["country_code"]: value,
          ["alternate_country_code"]: value,
        },
      });
    }
  };
  const handleAlternateCountryChange = (value) => {
    setSelectedAlternateCountry(value);
    setPayload({
      ...payload,
      contactPersonAndInvoicing: {
        ...payload.contactPersonAndInvoicing,
        ["alternate_country_code"]: value,
      },
    });
  };

  const handleChange = (e) => {
    if (e.select === "select") {
      setPayload({
        ...payload,
        contactPersonAndInvoicing: {
          ...payload.contactPersonAndInvoicing,
          [e.name]: e.value,
        },
      });
    } else {
      setPayload({
        ...payload,
        contactPersonAndInvoicing: {
          ...payload.contactPersonAndInvoicing,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const handlePhoneChange = (value, data) => {
    if (value) {
      setPayload({
        ...payload,
        contactPersonAndInvoicing: {
          ...payload.contactPersonAndInvoicing,
          ["country_code"]: data.dialCode,
          ["phone_number"]: value.slice(data.dialCode?.length),
        },
      });
    }
  };

  const _getLanguageLabel = (value) => {
    const filteredLanguage = LANGUAGES?.filter((l) => l.value === value);
    return filteredLanguage[0]?.label;
  };

  useEffect(() => {
    const val = payload?.contactPersonAndInvoicing?.phone_number;
    if (val !== null && val !== undefined) {
      form.setFieldsValue({
        phone_number: val,
      });
    }
  }, [payload?.contactPersonAndInvoicing?.phone_number]);

  return (
    <div className="bg-createEditListingBackground h-auto p-3">
      <p className="text-xl text-slate-600 font-semibold">
        Contact person and invoicing info{" "}
        <span>
          <ContentCopyIcon className="!text-[22px] text-buttonTheme cursor-pointer" />
        </span>
      </p>
      <div className="xl:grid xl:grid-cols-2 xl:gap-4">
        <div>
          <GenericInput
            inputTitle="Contact person's name"
            inputPlaceholder="Contact person's name"
            requiredFlag={true}
            toolTipFlag={true}
            name="contact_person_name"
            toolTipPlacement="top"
            tooltipTitle="This field is required"
            defaultValue={
              payload?.contactPersonAndInvoicing?.contact_person_name
            }
            value={payload?.contactPersonAndInvoicing?.contact_person_name}
            message="Please enter contact person's first name"
            control={control}
            onChange={(e) => handleChange(e)}
          />
          {errors.contact_person_name?.type === "required" && (
            <p role="alert" style={{ color: "red" }}>
              {errors.contact_person_name?.message}
            </p>
          )}
          <div className="mt-2 mb-1">
            <div className="flex gap-1 mb-1">
              <p className="font-semibold text-slate-700">Phone number</p>
              <p className="text-red-600"> *</p>
            </div>

            <div className="flex mt-1 hidden ">
              <Select
                showSearch
                placeholder="Select a country"
                optionFilterProp="children"
                value={44}
                defaultValue={countriesData.find(
                  (data) =>
                    data.flag === payload?.contactPersonAndInvoicing?.flag
                )}
                onChange={handleCountryChange}
                style={{ width: "60px" }}
              >
                {renderCountry}
              </Select>
              <Controller
                name="phone_number"
                rules={{
                  required: "Phone number is required",
                  pattern: {
                    message: "Only 10 numbers are allowed",
                  },
                }}
                defaultValue={payload?.contactPersonAndInvoicing?.phone_number}
                render={({ field }) => (
                  <Input
                    {...field}
                    addonBefore={selectedCountry ? `+${selectedCountry}` : ""}
                    placeholder="Phone number"
                    defaultValue={
                      payload &&
                      payload?.contactPersonAndInvoicing &&
                      payload?.contactPersonAndInvoicing?.phone_number
                    }
                    value={payload?.contactPersonAndInvoicing?.phone_number}
                    name="phone_number"
                    onChange={(e) => {
                      field.onChange(e);
                      handleChange(e);
                    }}
                    type="number"
                  />
                )}
                control={control}
              />
            </div>
            {console.log(payload.contactPersonAndInvoicing)}
            <PhoneInput
              inputStyle={{ width: "100%" }}
              enableSearch={true}
              value={`+${payload?.contactPersonAndInvoicing?.country_code}${payload?.contactPersonAndInvoicing?.phone_number}`}
              onChange={handlePhoneChange}
            />
            {errors.phone_number !== undefined &&
              !payload?.contactPersonAndInvoicing?.phone_number && (
                <p role="alert" style={{ color: "red" }}>
                  {errors.phone_number?.message}
                </p>
              )}
          </div>

          {/* <div>
            <Typography style={{ margin: 5, fontWeight: 500 }}>
              Contact person's spoken language
            </Typography>
            <SelectWithSearch
              selectPlaceholder="Contact person's spoken language"
              options={LANGUAGES}
              name="contact_person_spoken_language"
              value={{
                label: _getLanguageLabel(
                  payload?.contactPersonAndInvoicing
                    ?.contact_person_spoken_language
                ),
                value:
                  payload?.contactPersonAndInvoicing
                    ?.contact_person_spoken_language,
              }}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  contactPersonAndInvoicing: {
                    ...payload.contactPersonAndInvoicing,
                    contact_person_spoken_language: e.value,
                  },
                })
              }
            />
          </div> */}

          <GenericInput
            inputTitle="Contact person's address"
            inputPlaceholder="Contact person's address"
            requiredFlag={true}
            toolTipFlag={true}
            name="contact_person_address"
            toolTipPlacement="top"
            tooltipTitle="This field is required"
            defaultValue={
              payload?.contactPersonAndInvoicing?.contact_person_address
            }
            message="Please enter contact person's address"
            control={control}
            value={payload?.contactPersonAndInvoicing?.contact_person_address}
            onChange={(e) => handleChange(e)}
          />
          {errors.contact_person_address?.type === "required" && (
            <p role="alert" style={{ color: "red" }}>
              {errors.contact_person_address?.message}
            </p>
          )}
        </div>

        <div>
          <GenericInput
            inputTitle="Contact person's last name"
            inputPlaceholder="Contact person's last name"
            requiredFlag={true}
            toolTipFlag={true}
            name="contact_person_last_name"
            toolTipPlacement="top"
            tooltipTitle="This field is required"
            defaultValue={
              payload?.contactPersonAndInvoicing?.contact_person_last_name
            }
            value={payload?.contactPersonAndInvoicing?.contact_person_last_name}
            message="Please enter contact person's last name"
            control={control}
            onChange={(e) => handleChange(e)}
          />
          {errors.contact_person_last_name?.type === "required" && (
            <p role="alert" style={{ color: "red" }}>
              {errors.contact_person_last_name?.message}
            </p>
          )}

          <div className="mt-2">
            <GenericInput
              inputTitle="Contact person's email"
              inputPlaceholder="Contact person's email"
              requiredFlag={true}
              toolTipFlag={true}
              className="mt-3"
              name="contact_person_email"
              toolTipPlacement="top"
              tooltipTitle="This field is required"
              defaultValue={
                payload?.contactPersonAndInvoicing?.contact_person_email
              }
              value={payload?.contactPersonAndInvoicing?.contact_person_email}
              message="Please enter contact person's email"
              control={control}
              onChange={(e) => handleChange(e)}
            />
            {errors.contact_person_email?.type === "required" && (
              <p role="alert" style={{ color: "red" }}>
                {errors.contact_person_email?.message}
              </p>
            )}
          </div>
          <div className="mt-2">
            <GenericInput
              inputTitle="Tax ID"
              inputPlaceholder="Tax ID"
              name="tax_id"
              disabled={true}
              onChange={(e) => handleChange(e)}
              control={control}
              defaultValue={payload?.contactPersonAndInvoicing?.tax_id}
              value={payload?.contactPersonAndInvoicing?.tax_id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPersonAndInvoicing;
