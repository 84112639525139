import {
  CALENDAR,
  CALENDAR_MONTHLY,
  CALENDAR_MULTI,
  CALENDAR_YEARLY,
  LISTINGS,
  LISTINGS_CUSTOM_FIELDS,
  OVERVIEW,
  RESERVATION,
  RESERVATIONS,
  RESERVATIONS_COUPONS,
  RESERVATIONS_CUSTOM_FIELDS,
  RESERVATIONS_GUESTBOOK,
  USER_MANAGEMENT,
  USER_MANAGEMENT_GROUPS,
  USER_MANAGEMENT_USERS,
  LOGS,
  RESERVATION_TOOL,
  LISTING,
  CLEANING,
  VIEWS_AVAILABLE_PROPERTIES,
  VIEWS_RESERVATIONS,
  RESERVATION_REPORT,
  RESERVATION_REPORT_NEW,
  RESERVATION_REPORT_CANCELED,
  INBOX_MESSAGES,
  INBOX_MESSAGE_TEMPLATES,
  INBOX_AUTOMATIONS,
  INBOX_MANAGE_MESSAGES,
  INBOX_MESSAGE,
  MONDAY_DOT_COM,
  CHECKIN_INSTRUCTIONS,
  AVAILABLE_PROPERTIES,
  FINANCE,
  SYNC_DATA,
  RELOCATION_TOOL,
  VIEWS_ALL_RESERVATIONS,
  VIEWS,
  PROPETY_REPORT,
  RESERVATION_OVERVIEW,
  GUEST_COM,
} from "../../constants/dashboardRoutes";
import { useNavigate } from "react-router-dom";
import { getItem } from "../../utils";
import React, { useEffect, useMemo, useState } from "react";
import { AppContext } from "../../AppContext";
import { Icon } from "@iconify-icon/react";

export const SidebarMenu = () => {
  const navigation = useNavigate();
  const profile = React.useContext(AppContext).profile || {};
  const [route, setRoute] = useState(null);
  const items = [];
  useEffect(() => {
    const path = window.location.pathname;
    let count = 0;
    let status = true;
    for (let x of items) {
      if (path.includes(x.key)) {
        status = false;
        break;
      }
    }
    console.log(status, "final status");
    console.log(route, "final route");
    if (status) {
      if (items[0]?.children?.length > 0) {
        navigation(items[0].children?.[0].key);
      } else {
        navigation(items[0].key);
      }
    }
  }, [route]);

  // if (profile.readCalendar) {
  //   items.push(
  //     getItem(
  //       "Calendar",
  //       CALENDAR,
  //       <Icon icon="majesticons:calendar-line" height={20} width={20} />,
  //       [
  //         getItem(
  //           "Multi",
  //           CALENDAR_MULTI,
  //           <Icon icon="bx:calendar" height={20} width={20} />
  //         ),
  //         getItem(
  //           "Monthly",
  //           CALENDAR_MONTHLY,
  //           <Icon icon="majesticons:calendar-line" height={20} width={20} />
  //         ),
  //         getItem(
  //           "Yearly",
  //           CALENDAR_YEARLY,
  //           <Icon icon="lucide:calendar-search" height={20} width={20} />
  //         ),
  //       ]
  //     )
  //   );
  // }

  // if (profile.readReservation) {
  //   items.push(
  //     getItem(
  //       "Reservation",
  //       RESERVATION,
  //       <Icon icon="majesticons:calendar-line" height={20} width={20} />,
  //       [
  //         getItem(
  //           "Reservations",
  //           RESERVATIONS,
  //           <Icon icon="majesticons:calendar-line" height={20} width={20} />
  //         ),
  //         getItem(
  //           "Custom Fields",
  //           RESERVATIONS_CUSTOM_FIELDS,
  //           <Icon icon="basil:diamond-solid" height={20} width={20} />
  //         ),
  //         getItem(
  //           "Guestbook",
  //           RESERVATIONS_GUESTBOOK,
  //           <Icon icon="ant-design:book-outlined" height={20} width={20} />
  //         ),
  //         getItem(
  //           "Coupons",
  //           RESERVATIONS_COUPONS,
  //           <Icon icon="majesticons:tag-line" height={20} width={20} />
  //         ),
  //       ]
  //     )
  //   );
  // }

  // items.push(
  //   getItem(
  //     "Inbox",
  //     INBOX_MESSAGE,
  //     <Icon icon="ph:chat-fill" height={20} width={20} />,
  //     [
  //       //      getItem("Inbox", INBOX_MESSAGES, <ChatBubbleIcon sx={{ height: 20, width: 20 }} />),
  //       //      getItem("Message Templates", INBOX_MESSAGE_TEMPLATES, <SpeakerNotesIcon sx={{ height: 20, width: 20 }} />),
  //       getItem(
  //         "Automations",
  //         INBOX_AUTOMATIONS,
  //         <Icon icon="ic:baseline-try" height={20} width={20} />
  //       ),
  //       //      getItem("Manage Messages", INBOX_MANAGE_MESSAGES, <RateReviewIcon sx={{ height: 20, width: 20 }} />),
  //     ]
  //   )
  // );
  if (profile.readCleaning) {
    if (!route) {
      setRoute(CLEANING);
    }
    items.push(
      getItem(
        "Cleaning",
        CLEANING,
        <Icon icon="ic:outline-cleaning-services" height={20} width={20} />
      )
    );
  }
  if (profile.readViews) {
    if (!route) {
      setRoute(AVAILABLE_PROPERTIES);
    }
    items.push(
      getItem(
        "Views",
        VIEWS,
        <Icon icon="ci:user-02" height={20} width={20} />,
        [
          // getItem(
          //   "Available Properties",
          //   VIEWS_AVAILABLE_PROPERTIES,
          //   <Icon icon="lets-icons:user-box" height={20} width={20} />
          // ),
          getItem(
            "Property Availability",
            AVAILABLE_PROPERTIES,
            <Icon icon="lets-icons:user-box" height={20} width={20} />
          ),
          getItem(
            "Check-ins - Check-outs",
            VIEWS_RESERVATIONS,
            <Icon icon="lucide:users" height={20} width={20} />
          ),
          getItem(
            "Reservations",
            VIEWS_ALL_RESERVATIONS,
            <Icon icon="lucide:users" height={20} width={20} />
          ),
        ]
      )
    );
  }

  if (profile.readGuestInvoicing) {
    if (!route) {
      setRoute(CHECKIN_INSTRUCTIONS);
    }
    items.push(
      getItem(
        "Check-in Instructions",
        CHECKIN_INSTRUCTIONS,
        <Icon icon="ion:list" height={20} width={20} />
      )
    );
  }
  if (profile.readFinance) {
    if (!route) {
      setRoute(FINANCE);
    }
    items.push(
      getItem(
        "Finance",
        FINANCE,
        <Icon icon="material-symbols:attach-money" height={20} width={20} />
      )
    );
  }
  if (profile?.readReservationsOverview) {
    if (!route) {
      setRoute(RESERVATION_OVERVIEW);
    }
    items.push(
      getItem(
        "Reservation Overview",
        RESERVATION_OVERVIEW,
        <Icon
          icon="material-symbols:overview-outline-sharp"
          height={20}
          width={20}
        />
      )
    );
  }

  // if (profile.readListing) {
  //   items.push(
  //     getItem(
  //       "Listing",
  //       LISTING,
  //       <Icon icon="ion:list" height={20} width={20} />,
  //       [
  //         getItem(
  //           "Listings",
  //           LISTINGS,
  //           <Icon icon="ion:list" height={20} width={20} />
  //         ),
  //         getItem(
  //           "Custom Fields",
  //           LISTINGS_CUSTOM_FIELDS,
  //           <Icon icon="basil:diamond-solid" height={20} width={20} />
  //         ),
  //       ]
  //     )
  //   );
  // }
  if (profile.readReservation) {
    if (!route) {
      setRoute(RESERVATION_REPORT_NEW);
    }
    items.push(
      getItem(
        "Reservations Reports",
        RESERVATION_REPORT,
        <Icon icon="mdi:report-line" height={20} width={20} />,
        [
          getItem(
            "New",
            RESERVATION_REPORT_NEW,
            <Icon icon="clarity:new-line" height={20} width={20} />
          ),
          getItem(
            "Cancelled",
            RESERVATION_REPORT_CANCELED,
            <Icon icon="carbon:rule-cancelled" height={20} width={20} />
          ),
        ]
      )
    );
  }
  // if (profile.readReservationTool) {
  //   if (!route) {
  //     setRoute(RESERVATION_TOOL);
  //   }
  //   items.push(
  //     getItem(
  //       "Reservation Tool",
  //       RESERVATION_TOOL,
  //       <Icon icon="carbon:tool-kit" height={20} width={20} />
  //     )
  //   );
  // }
  if (profile.readGuestCom) {
    if (!route) {
      setRoute(GUEST_COM);
    }
    items.push(
      getItem(
        "Guest Com",
        GUEST_COM,
        <Icon icon="fluent:guest-16-regular" height={20} width={20} />
      )
    );
  }

  if (profile.readRelocationTool) {
    if (!route) {
      setRoute(RELOCATION_TOOL);
    }
    items.push(
      getItem(
        "Reservation Tool",
        RELOCATION_TOOL,
        <Icon icon="carbon:tool-kit" height={20} width={20} />
      )
    );
  }

  if (profile?.readPropertyReport) {
    if (!route) {
      setRoute(PROPETY_REPORT);
    }
    items.push(
      getItem(
        "Property Report",
        PROPETY_REPORT,
        <Icon
          icon="fluent-emoji-high-contrast:page-facing-up"
          height={20}
          width={20}
        />
      )
    );
  }

  if (profile.isAdmin) {
    items.push(
      getItem(
        "Logs",
        LOGS,
        <Icon icon="ic:twotone-log-out" height={20} width={20} />
      )
    );
  }

  if (profile.isAdmin) {
    items.push(
      getItem(
        "User Management",
        USER_MANAGEMENT,
        <Icon icon="ci:user-02" height={20} width={20} />,
        [
          getItem(
            "Users",
            USER_MANAGEMENT_USERS,
            <Icon icon="lets-icons:user-box" height={20} width={20} />
          ),
          getItem(
            "Groups",
            USER_MANAGEMENT_GROUPS,
            <Icon icon="lucide:users" height={20} width={20} />
          ),
        ]
      )
    );
  }
  if (profile.isAdmin) {
    items.push(
      getItem(
        "Sync",
        SYNC_DATA,
        <Icon icon="ic:twotone-log-out" height={20} width={20} />
      )
    );
  }
  // SYNC_DATA
  // items.push(
  //   getItem(
  //     "Monday.com",
  //     MONDAY_DOT_COM,
  //     <Icon icon="fa6-solid:m" height={20} width={20} />
  //   )
  // );
  // if (profile.isAdmin) {
  //   items.push(
  //     getItem(
  //       "Inbox",
  //       INBOX_MESSAGE,
  //       <Icon icon="ci:user-02" height={20} width={20} />,
  //       [
  //         getItem(
  //           "Inbox",
  //           INBOX_MESSAGES,
  //           <Icon icon="ci:user-02" height={20} width={20} />
  //         ),
  //         getItem(
  //           "Message Templates",
  //           INBOX_MESSAGE_TEMPLATES,
  //           <Icon icon="ci:user-02" height={20} width={20} />
  //         ),
  //         getItem(
  //           "Automations",
  //           INBOX_AUTOMATIONS,
  //           <Icon icon="ci:user-02" height={20} width={20} />
  //         ),
  //         getItem(
  //           "Manage Messages",
  //           INBOX_MANAGE_MESSAGES,
  //           <Icon icon="ci:user-02" height={20} width={20} />
  //         ),
  //       ]
  //     )
  //   );
  // }
  // if (profile.isAdmin) {
  //   items.push(
  //     getItem(
  //       "Inbox",
  //       INBOX_MESSAGE,
  //       <Icon icon="ci:user-02" height={20} width={20} />,
  //       [
  //         getItem(
  //           "Inbox",
  //           INBOX_MESSAGES,
  //           <Icon icon="ci:user-02" height={20} width={20} />
  //         ),
  //         getItem(
  //           "Message Templates",
  //           INBOX_MESSAGE_TEMPLATES,
  //           <Icon icon="ci:user-02" height={20} width={20} />
  //         ),
  //         getItem(
  //           "Automations",
  //           INBOX_AUTOMATIONS,
  //           <Icon icon="ci:user-02" height={20} width={20} />
  //         ),
  //         getItem(
  //           "Manage Messages",
  //           INBOX_MANAGE_MESSAGES,
  //           <Icon icon="ci:user-02" height={20} width={20} />
  //         ),
  //       ]
  //     )
  //   );
  // }

  return items;
};

// import { CalendarOutlined, HomeOutlined } from "@ant-design/icons";
// import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
// import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import EventRepeatOutlinedIcon from "@mui/icons-material/EventRepeatOutlined";
// import DiamondIcon from "@mui/icons-material/Diamond";
// import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
// import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
//  import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
//  import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
//  import RateReviewIcon from "@mui/icons-material/RateReview";
//  import TryOutlinedIcon from "@mui/icons-material/TryOutlined";
//  import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
//  import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
//  import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
//  import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
//  import StarRateOutlinedIcon from "@mui/icons-material/StarRateOutlined";
//  import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined";
//  import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
//  import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
//  import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
//  import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
//  import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
//  import PieChartOutlinedIcon from "@mui/icons-material/PieChartOutlined";
//  import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
//  import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
//  import FolderSpecialOutlinedIcon from "@mui/icons-material/FolderSpecialOutlined";
//  import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
//  import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
// import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
//  import PowerOutlinedIcon from "@mui/icons-material/PowerOutlined";
//  import SegmentOutlinedIcon from "@mui/icons-material/SegmentOutlined";
// import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
// import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
// import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
//  import TabOutlinedIcon from "@mui/icons-material/TabOutlined";
//  import BrushOutlinedIcon from "@mui/icons-material/BrushOutlined";
//  import WebOutlinedIcon from "@mui/icons-material/WebOutlined";
//  import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
//  import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
//  import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
//  import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
//  import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
//  import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
//  import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
//  import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
//  import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";

// import {
//    BOOKING_ENGINE,
//    BOOKING_ENGINE_DESIGN,
//    BOOKING_ENGINE_LISTINGS,
//    BOOKING_ENGINE_PAGES,
//    BOOKING_ENGINE_SETTINGS,
//   CALENDAR,
//   CALENDAR_MONTHLY,
//   CALENDAR_MULTI,
//   CALENDAR_YEARLY,
//    CHANNEL_MANAGER,
//    CHANNEL_MANAGER_CHANNELS,
//    CHANNEL_MANAGER_LISTING_MAPPING,
//    FINANCIAL_REPORTING,
//    FINANCIAL_REPORTING_ANALYTICS,
//    FINANCIAL_REPORTING_EXPENSES_AND_EXTRAS,
//    FINANCIAL_REPORTING_FINANCIAL_SETTINGS,
//    FINANCIAL_REPORTING_LISTING_FINANCIALS,
//    FINANCIAL_REPORTING_OCCUPANCY_REPORTS,
//    GUEST_PAYMENTS,
//    GUEST_PAYMENTS_AUTO_PAYMENTS,
//    GUEST_PAYMENTS_CHARGES,
//    GUEST_PAYMENTS_DOCUMENT_TEMPLATES,
//    INBOX_MESSAGES,
//    INBOX_AUTOMATIONS,
//    INBOX_MANAGE_MESSAGES,
//    INBOX_MESSAGE_TEMPLATES,
//   LISTINGS,
//   LISTINGS_CUSTOM_FIELDS,
//    MARKETPLACE,
//   OVERVIEW,
//    OWNER_STATEMENTS,
//    OWNER_STATEMENTS_CREATE_AND_EDIT,
//    OWNER_STATEMENTS_MANAGE_STATEMENTS,
//   RESERVATION,
//   RESERVATIONS,
//   RESERVATIONS_COUPONS,
//   RESERVATIONS_CUSTOM_FIELDS,
//   RESERVATIONS_GUESTBOOK,
//    REVIEWS,
//    REVIEWS_AUTO_REVIEWS,
//    REVIEWS_MANAGE_REVIEWS,
//    REVIEWS_REVIEW_TEMPLATES,
//    SETTINGS,
//    SETTINGS_ACCOUNT,
//    SETTINGS_HOSTAWAY_API,
//    SETTINGS_INTEGRATIONS,
//    SETTINGS_INVOICES_AND_CHARGES,
//    SETTINGS_NOTIFICATIONS,
//    TASKS,
//    TASKS_ARCHIVED,
//    TASKS_CHECKLIST_TEMPLATES,
//    TASKS_CUSTOM_FIELDS,
//    TASKS_MANAGE_AUTO_TASKS,
//    TASKS_MANAGE_TASKS,
//   USER_MANAGEMENT,
//   USER_MANAGEMENT_GROUPS,
//   USER_MANAGEMENT_USERS,
//   ACTIONS,
//    INBOX_MESSAGE,
//   LISTING,
// } from "../../constants/dashboardRoutes";
// import { getItem } from "../../utils";

// export const sidebarMenu = [
//   getItem("Overview", OVERVIEW, <HomeOutlined sx={{ height: 20, width: 20 }} />),
//   getItem("Calendar", CALENDAR, <CalendarOutlined sx={{ height: 20, width: 20 }} />, [
//     getItem("Multi", CALENDAR_MULTI, <CalendarMonthOutlinedIcon sx={{ height: 20, width: 20 }} />),
//     getItem("Monthly", CALENDAR_MONTHLY, <CalendarTodayOutlinedIcon sx={{ height: 20, width: 20 }} />),
//     getItem("Yearly", CALENDAR_YEARLY, <EventRepeatOutlinedIcon sx={{ height: 20, width: 20 }} />),
//   ]),
//   getItem("Reservation", RESERVATION, <CalendarTodayOutlinedIcon sx={{ height: 20, width: 20 }} />, [
//     getItem("Reservations", RESERVATIONS, <CalendarTodayOutlinedIcon sx={{ height: 20, width: 20 }} />),
//     getItem("Custom Fields", RESERVATIONS_CUSTOM_FIELDS, <DiamondIcon sx={{ height: 20, width: 20 }} />),
//     getItem("Guestbook", RESERVATIONS_GUESTBOOK, <BookOutlinedIcon sx={{ height: 20, width: 20 }} />),
//     getItem("Coupons", RESERVATIONS_COUPONS, <LocalOfferOutlinedIcon sx={{ height: 20, width: 20 }} />),
//   ]),

//    getItem("Tasks", TASKS, <BusinessCenterOutlinedIcon sx={{ height: 20, width: 20 }} />, [
//      getItem("Manage Tasks", TASKS_MANAGE_TASKS, <BusinessCenterOutlinedIcon sx={{ height: 20, width: 20 }} />),
//      getItem(
//        "Manage Auto-tasks",
//        TASKS_MANAGE_AUTO_TASKS,
//        <BusinessCenterOutlinedIcon sx={{ height: 20, width: 20 }} />
//      ),
//      getItem(
//        "Checklist Templates",
//        TASKS_CHECKLIST_TEMPLATES,
//        <ChecklistOutlinedIcon sx={{ height: 20, width: 20 }} />
//      ),
//      getItem("Archived", TASKS_ARCHIVED, <DeleteOutlineOutlinedIcon sx={{ height: 20, width: 20 }} />),
//      getItem("Custom Fields", TASKS_CUSTOM_FIELDS, <DiamondIcon sx={{ height: 20, width: 20 }} />),
//    ]),
//    getItem("Reviews", REVIEWS, <StarBorderOutlinedIcon sx={{ height: 20, width: 20 }} />, [
//      getItem(
//        "Manage Reviews",
//        REVIEWS_MANAGE_REVIEWS,
//        <StarBorderOutlinedIcon sx={{ height: 20, width: 20 }} />
//      ),
//      getItem("Auto-reviews", REVIEWS_AUTO_REVIEWS, <StarRateOutlinedIcon sx={{ height: 20, width: 20 }} />),
//      getItem(
//        "Review Templates",
//        REVIEWS_REVIEW_TEMPLATES,
//        <ReviewsOutlinedIcon sx={{ height: 20, width: 20 }} />
//      ),
//    ]),
//    getItem("Guest Payments", GUEST_PAYMENTS, <CreditCardOutlinedIcon sx={{ height: 20, width: 20 }} />, [
//      getItem("Charges", GUEST_PAYMENTS_CHARGES, <AddCardOutlinedIcon sx={{ height: 20, width: 20 }} />),
//      getItem(
//        "Auto Payments",
//        GUEST_PAYMENTS_AUTO_PAYMENTS,
//        <ReceiptOutlinedIcon sx={{ height: 20, width: 20 }} />
//      ),
//      getItem(
//        "Document Templates",
//        GUEST_PAYMENTS_DOCUMENT_TEMPLATES,
//        <RequestQuoteOutlinedIcon sx={{ height: 20, width: 20 }} />
//      ),
//    ]),
//    getItem(
//      "Financial Reporting",
//      FINANCIAL_REPORTING,
//      <LocalAtmOutlinedIcon sx={{ height: 20, width: 20 }} />,
//      [
//        getItem(
//          "Analytics",
//          FINANCIAL_REPORTING_ANALYTICS,
//          <PieChartOutlinedIcon sx={{ height: 20, width: 20 }} />
//        ),
//        getItem(
//          "Listing Financials",
//          FINANCIAL_REPORTING_LISTING_FINANCIALS,
//          <PaymentsOutlinedIcon sx={{ height: 20, width: 20 }} />
//        ),
//        getItem(
//          "Occupancy Report",
//          FINANCIAL_REPORTING_OCCUPANCY_REPORTS,
//          <SummarizeOutlinedIcon sx={{ height: 20, width: 20 }} />
//        ),
//        getItem(
//          "Expenses & Extras",
//          FINANCIAL_REPORTING_EXPENSES_AND_EXTRAS,
//          <PaymentsOutlinedIcon sx={{ height: 20, width: 20 }} />
//        ),
//        getItem(
//          "Financial Settings",
//          FINANCIAL_REPORTING_FINANCIAL_SETTINGS,
//          <FolderSpecialOutlinedIcon sx={{ height: 20, width: 20 }} />
//        ),
//      ]
//    ),
//    getItem("Owner Statements", OWNER_STATEMENTS, <ArticleOutlinedIcon sx={{ height: 20, width: 20 }} />, [
//      getItem(
//        "Create & Edit",
//        OWNER_STATEMENTS_CREATE_AND_EDIT,
//        <BorderColorOutlinedIcon sx={{ height: 20, width: 20 }} />
//      ),
//      getItem(
//        "Manage Statements",
//        OWNER_STATEMENTS_MANAGE_STATEMENTS,
//        <ArticleOutlinedIcon sx={{ height: 20, width: 20 }} />
//      ),
//    ]),
//   getItem("Listing", LISTING, <FormatListBulletedOutlinedIcon sx={{ height: 20, width: 20 }} />, [
//     getItem("Listings", LISTINGS, <FormatListBulletedOutlinedIcon sx={{ height: 20, width: 20 }} />),
//     getItem("Custom Fields", LISTINGS_CUSTOM_FIELDS, <DiamondIcon sx={{ height: 20, width: 20 }} />),
//   ]),
//    getItem("Channel Manager", CHANNEL_MANAGER, <PowerOutlinedIcon sx={{ height: 20, width: 20 }} />, [
//      getItem(
//        "Listing Mapping",
//        CHANNEL_MANAGER_LISTING_MAPPING,
//        <SegmentOutlinedIcon sx={{ height: 20, width: 20 }} />
//      ),
//      getItem("Channels", CHANNEL_MANAGER_CHANNELS, <PowerOutlinedIcon sx={{ height: 20, width: 20 }} />),
//    ]),
//   getItem("User Management", USER_MANAGEMENT, <Person2OutlinedIcon sx={{ height: 20, width: 20 }} />, [
//     getItem("Users", USER_MANAGEMENT_USERS, <PersonPinOutlinedIcon sx={{ height: 20, width: 20 }} />),
//     getItem("Groups", USER_MANAGEMENT_GROUPS, <GroupOutlinedIcon sx={{ height: 20, width: 20 }} />),
//   ]),
//   getItem("Booking Engine", BOOKING_ENGINE, <TabOutlinedIcon sx={{ height: 20, width: 20 }} />, [
//     getItem("Design", BOOKING_ENGINE_DESIGN, <BrushOutlinedIcon sx={{ height: 20, width: 20 }} />),
//     getItem("Listings", BOOKING_ENGINE_LISTINGS, <WebOutlinedIcon sx={{ height: 20, width: 20 }} />),
//     getItem("Pages", BOOKING_ENGINE_PAGES, <LibraryBooksOutlinedIcon sx={{ height: 20, width: 20 }} />),
//     getItem(
//       "Settings",
//       BOOKING_ENGINE_SETTINGS,
//       <SettingsApplicationsOutlinedIcon sx={{ height: 20, width: 20 }} />
//     ),
//   ]),
//   getItem("Marketplace", MARKETPLACE, <StoreOutlinedIcon sx={{ height: 20, width: 20 }} />),
//   getItem("Settings", SETTINGS, <SettingsSuggestOutlinedIcon sx={{ height: 20, width: 20 }} />, [
//     getItem("Account", SETTINGS_ACCOUNT, <AccountCircleOutlinedIcon sx={{ height: 20, width: 20 }} />),
//     getItem(
//       "Invoices & Charges",
//       SETTINGS_INVOICES_AND_CHARGES,
//       <ReceiptLongOutlinedIcon sx={{ height: 20, width: 20 }} />
//     ),
//     getItem("Integrations", SETTINGS_INTEGRATIONS, <ExtensionOutlinedIcon sx={{ height: 20, width: 20 }} />),
//     getItem("Hostaway API", SETTINGS_HOSTAWAY_API, <CodeOutlinedIcon sx={{ height: 20, width: 20 }} />),
//     getItem(
//       "Notifications",
//       SETTINGS_NOTIFICATIONS,
//       <NotificationsActiveOutlinedIcon sx={{ height: 20, width: 20 }} />
//     ),
//    ]),
//   getItem("Actions", ACTIONS, <HomeOutlined sx={{ height: 20, width: 20 }} />),
// ];
