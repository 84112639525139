import React, { useContext, useEffect, useState } from "react";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import OverviewBigCard from "./OverviewBigCard";
import OverviewSmallCard from "./OverviewSmallCard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DoneIcon from "@mui/icons-material/Done";
import MoneyIcon from "@mui/icons-material/Money";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { apiInstance } from "../../utils/API";
import Loading from "../Loaders/Loading";
import { Link } from "react-router-dom";
import { LISTINGS } from "../../constants/dashboardRoutes";
import { AppContext } from "../../AppContext";
import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { SERVER_URL } from "../../constants/serverUrl";

const Overview = () => {
  const profile = useContext(AppContext).profile || {};
  const isAdmin = profile.isAdmin;
  const hasListingReadAccess = profile.readListing;
  const hasReservationReadAccess = profile.readReservation;
  const [reservationOverview, setReservationOverview] = useState(null);
  const [propertiesOverview, setPropertiesOverview] = useState(null);

  useEffect(() => {
    getReservationOverview();
    getPropertiesOverview();
  }, []);

  const getReservationOverview = async () => {
    try {
      let url = `/reservation/overview`;
      const res = await apiInstance.get(url);
      const list = res?.data || {};
      setReservationOverview(list);
    } catch (error) {
      setReservationOverview({});
    }
  };

  const getPropertiesOverview = async () => {
    try {
      let url = `/properties/overview`;
      const res = await apiInstance.get(url);
      const list = res?.data || {};
      setPropertiesOverview(list);
    } catch (error) {
      setPropertiesOverview({});
    }
  };

  const handleDownloadClick = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/logFiles/download`);

      if (response.ok) {
        // Trigger the download by creating a blob from the response
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "all_log_files.zip";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } else {
        const errorMessage = await response.text();
        console.log({ errorMessage });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="p-5 bg-dashboardBackground">
      {hasReservationReadAccess && (
        <>
          <div className="flex items-center gap-3 bg-white lg:w-full min-h-[58px] pl-5 mb-7">
            <EventAvailableIcon className="text-themeGrey" />
            <div className="text-themeGrey font-semibold text-xl">
              Reservations
            </div>
          </div>
          {!reservationOverview ? (
            <Loading />
          ) : (
            <div className="grid items-start  grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-4 mb-6 lg:w-[100%]">
              <OverviewBigCard
                tooltipTitle="Listing timezone"
                text="Today's check-outs"
                bottomText="Check-outs today"
                data={reservationOverview.todayCheckOuts}
              />
              <OverviewBigCard
                tooltipTitle="Listing timezone"
                text="Today's check-ins"
                bottomText="Check-ins today"
                data={reservationOverview.todayCheckIns}
              />
              <OverviewBigCard
                tooltipTitle="These reservations need to be accepted or declined by the Host within the showed period of time. After that period, the reservations expire and cannot be approved any longer. In addition, the channels may apply some penalties and block the calendar. Listing timezone"
                text="Reservations that need your approval"
                calendarRedirection={false}
                bottomText="Pending & unconfirmed reservation(s)"
                data={reservationOverview.pendingReservations}
              />
              <OverviewBigCard
                tooltipTitle="Total confirmed (new and modified) reservations from active listings which check-in falls between today's date and the next 7 days. Listing timezone"
                text="Upcoming reservation(s)"
                bottomText="Upcoming reservations"
                data={reservationOverview.upcommingCheckIns}
                dayText="Next 7 days"
              />
              <OverviewBigCard
                tooltipTitle="Total confirmed (new and modified) reservations and owner stays from active listings which check-in falls on before or equal to today's date and their check-out falls on today's date or after today. Listing timezone"
                text="Staying guest(s)"
                bottomText="Guest who are currently staying"
                data={reservationOverview.activeReservations}
              />
              {/* <OverviewBigCard text="Pending Airbnb inquiries" bottomText="Guest inquiries for reservation"  /> */}
              <div className="bg-white pt-2 px-4">
                <div className="flex items-center justify-between border-b-2 border-creme">
                  <div>
                    <div className="flex items-center gap-1">
                      {
                        <EventAvailableIcon
                          className="text-themeGrey"
                          style={{ fontSize: 16 }}
                        />
                      }
                      <p className="text-themeGrey font-semibold">
                        Pending Airbnb inquiries
                      </p>
                      {/* <Tooltip placement="top" title={tooltipTitle}>
                  <HelpOutlineIcon
                    className="text-blue-500 mb-1 cursor-pointer"
                    style={{ fontSize: 14 }}
                  />
                </Tooltip> */}
                    </div>
                  </div>
                  <div className="bg-themeSecondaryGreen px-2 rounded-sm text-white text-[10px] font-semibold"></div>
                </div>
                <p className="text-themeGrey text-3xl"></p>
                <p className=" text-xs mb-2 py-4 text-center text-cancelButton">
                  Under Construction
                </p>
              </div>
            </div>
          )}
        </>
      )}
      {!reservationOverview ? (
        <></>
      ) : (
        isAdmin && (
          <div className="grid items-start grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-4 mb-7 lg:w-full">
            <OverviewSmallCard
              tooltipTitle="Total of confirmed (new and modified) reservations from active listings which have their check-in time in the last 30 days."
              text="Check-in"
              labelText="Last 30 days"
              count={reservationOverview.checkIns}
              bottomText="Guest check-ins"
              icon={
                <ArrowForwardIcon
                  className="text-themeGrey"
                  style={{ fontSize: 16 }}
                />
              }
              percentage={reservationOverview.checkInsPercent + "%"}
              percentageColor={
                reservationOverview.checkInsPercent.startsWith("-")
                  ? "text-red-500"
                  : "text-green-500"
              }
              percentageIcon={
                reservationOverview.checkInsPercent.startsWith("-") ? (
                  <ArrowDownwardIcon
                    className="text-red-500"
                    style={{ fontSize: 16 }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    className="text-themeGreen"
                    style={{ fontSize: 16 }}
                  />
                )
              }
            />
            <OverviewSmallCard
              tooltipTitle="Average total price of confirmed (new and modified) reservations from active listings which have their check-in time in the last 30 days."
              text="Average Revenue"
              labelText="Last 30 days"
              count={reservationOverview.avgRevenue}
              bottomText="Average revenue"
              icon={
                <MoneyIcon
                  className="text-themeGrey"
                  style={{ fontSize: 16 }}
                />
              }
              percentage={reservationOverview.avgRevenuePercent + "%"}
              percentageColor={
                reservationOverview.avgRevenuePercent.startsWith("-")
                  ? "text-red-500"
                  : "text-green-500"
              }
              percentageIcon={
                reservationOverview.avgRevenuePercent.startsWith("-") ? (
                  <ArrowDownwardIcon
                    className="text-red-500"
                    style={{ fontSize: 16 }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    className="text-themeGreen"
                    style={{ fontSize: 16 }}
                  />
                )
              }
            />

            <OverviewSmallCard
              tooltipTitle="Sum total price of confirmed (new and modified) reservations from active listings which have their check-in time in the last 30 days."
              text="Revenue"
              labelText="Last 30 days"
              count={
                Math.round(Number(reservationOverview.totalRevenue) * 100) / 100
              }
              bottomText="Total revenue"
              icon={
                <MoneyIcon
                  className="text-themeGrey"
                  style={{ fontSize: 16 }}
                />
              }
              percentage={reservationOverview.totalRevenuePercent + "%"}
              percentageColor={
                reservationOverview.totalRevenuePercent.startsWith("-")
                  ? "text-red-500"
                  : "text-green-500"
              }
              percentageIcon={
                reservationOverview.totalRevenuePercent.startsWith("-") ? (
                  <ArrowDownwardIcon
                    className="text-red-500"
                    style={{ fontSize: 16 }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    className="text-themeGreen"
                    style={{ fontSize: 16 }}
                  />
                )
              }
            />
            <OverviewSmallCard
              tooltipTitle="Total confirmed (new and modified) reservations from active listings which have their creation date (were submitted by guests) in the last 30 days including today's date."
              text="Confirmed"
              labelText="Last 30 days"
              count={reservationOverview.confirmations}
              bottomText="Confirmed reservations"
              icon={
                <DoneIcon className="text-themeGrey" style={{ fontSize: 16 }} />
              }
              percentage={reservationOverview.confirmationsPercent + "%"}
              percentageColor={
                reservationOverview.confirmationsPercent.startsWith("-")
                  ? "text-red-500"
                  : "text-green-500"
              }
              percentageIcon={
                reservationOverview.confirmationsPercent.startsWith("-") ? (
                  <ArrowDownwardIcon
                    className="text-red-500"
                    style={{ fontSize: 16 }}
                  />
                ) : (
                  <ArrowUpwardIcon
                    className="text-themeGreen"
                    style={{ fontSize: 16 }}
                  />
                )
              }
            />
          </div>
        )
      )}
      <div>
        <div className="w-full flex justify-end mb-4">
          <Button
            type="primary"
            shape="round"
            icon={<DownloadOutlined />}
            className="bg-buttonTheme hover:!bg-buttonHoverTheme flex justify-center items-center"
            onClick={handleDownloadClick}
          >
            Download Logs
          </Button>
        </div>

        <div className="flex items-center gap-3 bg-white lg:w-full min-h-[58px] pl-5 mb-7">
          <QuestionAnswerRoundedIcon className="text-themeGrey" />
          <div className="text-themeGrey font-semibold text-xl">Messages</div>
        </div>
        <div className="flex items-center justify-center gap-3 bg-white lg:w-full py-4 text-red-500 text-center pl-5 mb-7">
          Under Construction
        </div>
        {/* <div className="grid grid-cols-2 gap-4 mb-7 lg:w-full">
          <div className="grid grid-cols-2 gap-4">
            <OverviewSmallCard
              text="Sent"
              labelText="Last 30 days"
              count={0}
              bottomText="Guest check-ins"
              icon={<QuestionAnswerIcon className="text-themeGrey" style={{ fontSize: 16 }} />}
              labelColor="bg-labelBlue"
              percentage="-100%"
              percentageColor="text-red-500"
              percentageIcon={<ArrowDownwardIcon className="text-red-500" style={{ fontSize: 16 }} />}
            />
            <OverviewSmallCard
              text="Average sent"
              labelText="Last 30 days"
              count={0}
              bottomText="Guest check-ins"
              icon={<QuestionAnswerIcon className="text-themeGrey" style={{ fontSize: 16 }} />}
              labelColor="bg-labelBlue"
            />
            <OverviewSmallCard
              text="Received"
              labelText="Last 30 days"
              count={0}
              bottomText="Guest check-ins"
              icon={<QuestionAnswerIcon className="text-themeGrey" style={{ fontSize: 16 }} />}
              labelColor="bg-labelBlue"
              percentage="-100%"
              percentageColor="text-red-500"
              percentageIcon={<ArrowDownwardIcon className="text-red-500" style={{ fontSize: 16 }} />}
            />
            <OverviewSmallCard
              text="Avg. received"
              labelText="Last 30 days"
              count={0}
              bottomText="Guest check-ins"
              icon={<QuestionAnswerIcon className="text-themeGrey" style={{ fontSize: 16 }} />}
              labelColor="bg-labelBlue"
            />
            <OverviewSmallCard
              text="Automation"
              labelText="Last 30 days"
              count={0}
              bottomText="Guest check-ins"
              icon={<QuestionAnswerIcon className="text-themeGrey" style={{ fontSize: 16 }} />}
              labelColor="bg-labelBlue"
              percentage="-100%"
              percentageColor="text-red-500"
              percentageIcon={<ArrowDownwardIcon className="text-red-500" style={{ fontSize: 16 }} />}
            />
            <OverviewSmallCard
              text="Automation"
              labelText="Last 30 days"
              count={0}
              bottomText="Guest check-ins"
              icon={<QuestionAnswerIcon className="text-themeGrey" style={{ fontSize: 16 }} />}
              labelColor="bg-labelBlue"
              percentage="25%"
              percentageIcon={<ArrowUpwardIcon className="text-themeGreen" style={{ fontSize: 16 }} />}
            />
          </div>
          <div className="grid grid-cols-1 gap-4 bg-white">
            <ConversationCard
              text="Unread conversation(s)"
              labelColor="bg-labelBlue"
              icon={<QuestionAnswerIcon className="text-themeGrey" style={{ fontSize: 16 }} />}
            />
          </div>
        </div> */}
      </div>
      {hasListingReadAccess && (
        <div>
          <div className="flex items-center gap-3 bg-white lg:w-full min-h-[58px] pl-5 mb-7">
            <FormatListBulletedRoundedIcon className="text-themeGrey" />
            <div className="text-themeGrey font-semibold text-xl">Listings</div>
          </div>
          {!propertiesOverview ? (
            <Loading />
          ) : (
            <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-7 lg:w-full">
              <OverviewSmallCard
                tooltipTitle="Total number of listings, including imported, currently importing, active and deactivated regardless of how many channels they are mapped to."
                text="Total"
                count={propertiesOverview.totalCount}
                bottomText="Total number of listing(s)"
                icon={
                  <FormatListBulletedRoundedIcon
                    className="text-themeGrey"
                    style={{ fontSize: 16 }}
                  />
                }
              />
              <OverviewSmallCard
                tooltipTitle="Total number of inactive listings. Inactive listings do not sync with channels."
                text="Inactive"
                count={propertiesOverview.inactiveCount}
                bottomText="Inactive listing(s)"
                icon={
                  <FormatListBulletedRoundedIcon
                    className="text-themeGrey"
                    style={{ fontSize: 16 }}
                  />
                }
              />
              <Link target="_blank" to={LISTINGS}>
                <OverviewSmallCard
                  tooltipTitle="Total number of incomplete listings. These are active listings you will be charged for but their
synchronization with the channels will not be done correctly because they are missing information. We ask you to review the listing and make sure all information is included: default price, default currency, default
minimum/maximum stay, price per extra person, capacity, listing type, listing name, listing timezone, listing location, default
checkin/checkout time"
                  text="Incomplete"
                  count={propertiesOverview.incompleteCount}
                  bottomText="Total revenue"
                  icon={
                    <FormatListBulletedRoundedIcon
                      className="text-themeGrey"
                      style={{ fontSize: 16 }}
                    />
                  }
                />
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Overview;
