/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import "./AddAutomation.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Dropdown, Form, Input, Menu, Pagination, Switch } from "antd";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  INBOX_AUTOMATIONS,
  RESERVATIONS,
} from "../../constants/dashboardRoutes";
import GenericButton from "../../components/GenericButton/GenericButton";
import Loading from "../../components/Loaders/Loading";
import { useState } from "react";
import FormInput from "../../components/Form/FormInput";
import FormSelect from "../../components/Form/FormSelect";
import TextEditor from "../../components/TextEditor/TextEditor";
import { apiInstance } from "../../utils/API";
import { manageToast } from "../../components/Common/ManageToast";
import { useEffect } from "react";
import { Icon } from "@iconify-icon/react";
import AutomationListingCard from "../../components/AutomationListingCard.jsx/AutomationListingCard";
import { CaretDownOutlined, UpOutlined } from "@ant-design/icons";
import { addAutomationItems, eventOptions } from "../../enum";
import { FormRule } from "../../enum/formRules";
import { AUTO_MESSAGES } from "../../constants/messages";
import NumberOfGuests from "../../components/NumberOfGuests/NumberOfGuests";
import ListingIsAvailable from "../../components/ListingIsAvailable/ListingIsAvailable";
import AdvanceTime from "../../components/AdvanceTime/AdvanceTime";
import ReservationCustomField from "../../components/ReservationCustomField/ReservationCustomField";
import RentalAgreement from "../../components/RentalAgreement/RentalAgreement";
import PaymentStatus from "../../components/PaymentStatus/PaymentStatus";
import CustomFilters from "../../components/CustomFIlters/CustomFilters";
const { Search } = Input;

export default function AddAutomation() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [channels, setChannels] = useState([
    { _id: "1", companyName: "Direct" },
    { _id: "2", companyName: "RentalsUnited" },
  ]);
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(true);
  const [addDropdownItems, setAddDropdownItems] = useState(addAutomationItems);
  const [selectedItems, setSelectedItems] = useState([]);
  const [propertyListings, setPropertyListings] = useState([]);
  const [selectedPropertyListings, setSelectedPropertyListings] = useState([]);
  const [isListingLoading, setIsListingLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProperties, setTotalProperties] = useState(0);
  const [isDisplayFilter, setIsDisplayFilter] = useState(false);
  const [isDisplayFilter2, setIsDisplayFilter2] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [editorValue, setEditorValue] = useState("");
  const [clickPosition, setClickPosition] = useState(null);
  const quillRef = useRef(null);
  const location = useLocation();
  const createSimilarId = location.state?.createSimilarId;
  const [
    isAutomaticallyAddNewListingsChecked,
    setIsAutomaticallyAddNewListingsChecked,
  ] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    getChannels();
    fetchTotalProperties();
    fetchPropertyListings();
  }, []);

  useEffect(() => {
    if (id || createSimilarId) {
      fetchData();
    }
  }, []);

  async function fetchData() {
    try {
      setIsLoading(true);

      if (!id && !createSimilarId) {
        setIsLoading(false);
        return;
      }

      const apiUrl = "/api/automations/" + (id || createSimilarId);
      const res = await apiInstance.get(apiUrl);
      const data = res.data;
      // Removing the already selected items from addDropdownItems except reservation custom field.
      const updatedDropdownItems = addDropdownItems.filter((item) => {
        return (
          !data.conditions.some(
            (conditionItem) => conditionItem.label === item.label
          ) || item.label === "Reservation custom field"
        );
      });
      setAddDropdownItems(updatedDropdownItems);
      setSelectedItems(data.conditions);
      setSelectedPropertyListings(data.listings);
      setIsAutomaticallyAddNewListingsChecked(
        data.isAutomaticallyAddNewListings
      );
      form.setFieldsValue(data);
      setIsLoading(false);
    } catch (error) {
      const message = error?.response?.data;
      console.log(error);
      manageToast(false, `${message?.err}` || "Something went wrong.");
      setIsLoading(false);
    }
  }

  const fetchTotalProperties = async () => {
    try {
      setIsLoading(true);
      let response = await apiInstance.get(
        `/properties/automationProperties?page=${currentPage}&pageSize=6`
      );
      setTotalProperties(response?.data?.propertyCount);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  };

  const fetchPropertyListings = async (page) => {
    try {
      setIsListingLoading(true);
      let response;
      if (page) {
        response = await apiInstance.get(
          `/properties/automationProperties?page=${currentPage}&pageSize=6&search=${page}`
        );
      } else {
        response = await apiInstance.get(
          `/properties/automationProperties?page=${currentPage}&pageSize=6`
        );
      }
      setPropertyListings(response?.data?.propertiesList);
      setIsListingLoading(false);
    } catch (error) {
      setIsListingLoading(false);
      throw new Error(error);
    }
  };

  const getChannels = async () => {
    try {
      const apiUrl = `/reservation/channels`;
      const response = await apiInstance.get(apiUrl);
      setChannels((arr) => [...arr, ...response.data]);
    } catch (e) {}
  };

  const calculateConditions = (data) => {
    // Case 1: Number of Guests
    const numberOfGuestsData = {
      key: "1",
      label: "Number of Guests",
      numberOfGuestsCondition: data.numberOfGuestsCondition,
      numberOfGuestsFrom: data.numberOfGuestsFrom,
      numberOfGuestsTo: data.numberOfGuestsTo,
      numberOfGuestsValue: data.numberOfGuestsValue,
    };

    // Case 2: Listing is Available
    const listingIsAvailableData = {
      key: "2",
      label: "Listing is available",
      listingIsAvailableCondition: data.listingIsAvailableCondition,
      listingIsAvailableFrom: data.listingIsAvailableFrom,
      listingIsAvailableTo: data.listingIsAvailableTo,
      listingIsAvailableValue: data.listingIsAvailableValue,
      listingIsAvailableEvent: data.listingIsAvailableEvent,
    };

    // Case 3: Advance Time
    const advanceTimeData = {
      key: "3",
      label: "Advance time",
      advanceTimeCondition: data.advanceTimeCondition,
      advanceTimeValue: data.advanceTimeValue,
      advanceTimeUnit: data.advanceTimeUnit,
    };

    // Case 4: Reservation Custom Field
    const reservationCustomFieldData = {
      key: "4",
      label: "Reservation custom field",
      reservationCustomField: data.reservationCustomField,
      reservationCustomFieldCondition: data.reservationCustomFieldCondition,
      reservationCustomFieldValue: data.reservationCustomFieldValue,
    };

    // Case 5: Rental Agreement
    const rentalAgreementData = {
      key: "5",
      label: "Rental agreement",
      rentalAgreementCondition: data.rentalAgreementCondition,
      rentalAgreementValue: data.rentalAgreementValue,
    };

    // Case 6: Payment Status
    const paymentStatusData = {
      key: "6",
      label: "Payment status",
      paymentStatusCondition: data.paymentStatusCondition,
      paymentStatusValue: data.paymentStatusValue,
    };

    const conditionsData = [
      numberOfGuestsData,
      listingIsAvailableData,
      reservationCustomFieldData,
      advanceTimeData,
      rentalAgreementData,
      paymentStatusData,
    ];

    const filteredData = conditionsData.filter((item) => {
      switch (item.label) {
        case "Number of Guests":
          return item.numberOfGuestsCondition !== undefined;
        case "Listing is available":
          return item.listingIsAvailableCondition !== undefined;
        case "Reservation custom field":
          return item.reservationCustomFieldCondition !== undefined;
        case "Advance time":
          return item.advanceTimeCondition !== undefined;
        case "Rental agreement":
          return item.rentalAgreementCondition !== undefined;
        case "Payment status":
          return item.paymentStatusCondition !== undefined;
        default:
          return true;
      }
    });

    return filteredData;
  };

  const onSubmit = async (data) => {
    try {
      setSending(true);
      const body = {
        ...data,
        conditions: calculateConditions(data),
        listings: selectedPropertyListings,
        isAutomaticallyAddNewListings: isAutomaticallyAddNewListingsChecked,
      };
      let res;
      // Case 1: Update Automation
      if (id) {
        res = await apiInstance.put("/api/automations/" + id, body);
      }
      // Case 2: Create Automation
      else {
        res = await apiInstance.post("/api/automations", body);
      }
      if (res.status === 200) {
        manageToast(true, id ? "Updated Successfully" : "Saved Successfully");
        navigate(INBOX_AUTOMATIONS);
      } else {
        manageToast(false, "Something went wrong!");
      }
    } catch (error) {
      console.error(error);
      manageToast(false, "Something went wrong!");
    } finally {
      setSending(false);
    }
  };

  const handleMenuClick = (item) => {
    let clickedItem = addDropdownItems.find(
      (dropdownItem) => dropdownItem.key === item.key
    );
    let previousSelectedItems = [...selectedItems];
    setSelectedItems([...previousSelectedItems, clickedItem]);
    if (clickedItem) {
      if (clickedItem.label !== "Reservation custom field") {
        const newItems = addDropdownItems.filter(
          (remaining) => remaining.label !== clickedItem.label
        );
        setAddDropdownItems(newItems);
      }
    }
  };

  const handleDeleteClick = (item) => {
    // Remove deleted item from selected item
    const updatedItemList = selectedItems.filter(
      (selectedItem) => selectedItem.label !== item.label
    );
    setSelectedItems(updatedItemList);
    let previousAddDropdownItems = [...addDropdownItems];
    if (item.label !== "Reservation custom field")
      setAddDropdownItems([...previousAddDropdownItems, item]);
  };

  const getComponentForLabel = (item) => {
    const componentMapping = {
      "Number of Guests": <NumberOfGuests initialValues={item} />,
      "Listing is available": <ListingIsAvailable initialValues={item} />,
      "Advance time": <AdvanceTime initialValues={item} />,
      "Reservation custom field": (
        <ReservationCustomField initialValues={item} />
      ),
      "Rental agreement": <RentalAgreement initialValues={item} />,
      "Payment status": <PaymentStatus initialValues={item} />,
    };

    return componentMapping[item.label] ? componentMapping[item.label] : null;
  };

  const menu = (
    <Menu>
      {addDropdownItems.map((item) => {
        return (
          <Menu.Item
            key={item.key}
            className="text-themeGrey"
            style={{ fontSize: "12px" }}
            onClick={() => handleMenuClick(item)}
          >
            {item.label}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const handleCheckboxChange = (property) => {
    const { _id, appartmentIndex, mainTitle } = property;

    const isSelected = selectedPropertyListings.some(
      (item) => item._id === _id
    );

    if (isSelected) {
      setSelectedPropertyListings((prevSelectedListings) =>
        prevSelectedListings.filter((item) => item._id !== _id)
      );
    } else {
      setSelectedPropertyListings((prevSelectedListings) => [
        ...prevSelectedListings,
        { _id, appartmentIndex, mainTitle },
      ]);
    }
  };

  const handleAddCustomFieldToMessage = (value, filterFor) => {
    if (filterFor === "message") {
      const quill = quillRef.current.getEditor();
      if (cursorPosition) {
        const cursor = cursorPosition.index;
        const newText = value;
        quill.insertText(cursor, " " + newText + " ");
        quill.setSelection(cursor + newText.length);
      } else {
        const values = form.getFieldsValue();
        const message = values.message;
        form.setFieldValue("message", message + " " + value);
      }
    } else if (filterFor === "subject") {
      insertTextAtCursorPosition(value);
    }
  };

  const getSubjectCursorPosition = () => {
    if (inputRef.current && inputRef.current.input) {
      return inputRef.current.input.selectionStart;
    }
    return 0;
  };

  const insertTextAtCursorPosition = (textToInsert) => {
    const currentPosition = getSubjectCursorPosition();
    if (inputRef.current && inputRef.current.input) {
      const inputValue = inputRef.current.input.value;
      const space = " ";
      const newValue =
        inputValue.substring(0, currentPosition) +
        space +
        textToInsert +
        space +
        inputValue.substring(currentPosition);

      form.setFieldsValue({
        subject: newValue,
      });

      const cursorPosition =
        currentPosition + space.length + textToInsert.length + space.length;
      inputRef.current.input.value = newValue;
      inputRef.current.input.setSelectionRange(cursorPosition, cursorPosition);
    }
  };

  return (
    <div
      className="bg-white relative"
      id="custom-automation"
      onClick={(e) => {
        console.log(e.target);
        if (clickPosition === "insideSubject") {
          setIsDisplayFilter2(false);
          setClickPosition(null);
        }
        if (clickPosition === "insideMessage") {
          setIsDisplayFilter(false);
          setClickPosition(null);
        }
      }}
    >
      <Form
        name="Automation"
        initialValues={{ message: "", subject: " " }}
        onFinish={onSubmit}
        autoComplete="off"
        form={form}
        layout="vertical"
      >
        <div className="py-5 md:px-5 px-2 flex md:flex-nowrap flex-wrap gap-2 md:gap-0 border-b-2 items-center justify-between fixed bg-white md:left-[257px] left-0 right-0 z-[9]">
          <Link className="text-themeGreen" to={INBOX_AUTOMATIONS}>
            <ArrowBackIosNewIcon style={{ fontSize: 16 }} /> Automation
          </Link>
          <div className="flex flex-wrap md:flex-nowrap  gap-2">
            <GenericButton
              loadingClass="text-white"
              isLoading={sending}
              disabled={sending || isLoading}
              type="submit"
              label="Save"
              className="bg-themeGreen hover:!text-white text-white disabled:bg-buttonTheme disabled:hover:!bg-buttonTheme disabled:text-white"
            />
            <GenericButton
              type="button"
              disabled={sending}
              onClick={() => {
                navigate(RESERVATIONS);
              }}
              label="Cancel"
              className="bg-labelGreen text-themeGreen disabled:bg-labelGreen disabled:text-themeGreen disabled:opacity-50"
            />
          </div>
        </div>
        <div className="md:pt-[72px] pt-[53px] px-5 pb-5">
          {isLoading ? (
            <div className="h-[50vh] flex justify-center items-center">
              <Loading />
            </div>
          ) : (
            <div className="flex gap-x-12 mt-2">
              <div className="w-8/12">
                <FormInput
                  name="name"
                  label="Automation name"
                  placeholder="Automation name"
                  rules={FormRule.AUTOMATION}
                />
                <div className="grid grid-cols-2 gap-2 md:gap-7 mt-2 items-center">
                  <FormInput
                    name="ccEmail1"
                    label="CC email 1"
                    placeholder="CC email 1"
                    rules={FormRule.CC_EMAIL_1}
                    toolTipFlag={true}
                    tooltipTitle="Add any address you wish to include as a CC for outgoing automations"
                  />
                  <FormInput
                    name="ccEmail2"
                    label="CC email 2"
                    placeholder="CC email 2"
                    rules={FormRule.CC_EMAIL_2}
                    toolTipFlag={true}
                    tooltipTitle="Add any address you wish to include as a CC for outgoing automations"
                  />
                </div>
                {/* Subject */}
                <div className="my-4 relative">
                  <div className="absolute -top-1 right-0 z-10 text-sm text-gray-500 ">
                    <div className="flex justify-end">
                      <GenericButton
                        label="Fields"
                        className="text-themeGrey bg-white rounded-none"
                        icon={<CaretDownOutlined />}
                        onClick={() => {
                          setIsDisplayFilter2(!isDisplayFilter2);
                          setClickPosition("insideSubject");
                        }}
                      />
                    </div>
                    {isDisplayFilter2 && (
                      <CustomFilters
                        filterFor="subject"
                        handleAddCustomFieldToMessage={
                          handleAddCustomFieldToMessage
                        }
                      />
                    )}
                  </div>
                  <div>
                    <Form.Item
                      name="subject"
                      rules={FormRule.SUBJECT}
                      label="Subject"
                      className="mb-0"
                    >
                      <Input
                        ref={inputRef}
                        className={`w-[100%] rounded-md placeholder:capitalize`}
                        placeholder={
                          <div className="text-themeGrey font-bold">
                            Subject
                          </div>
                        }
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="my-2 relative">
                  <div
                    className={`absolute -top-1 right-0 ${
                      isDisplayFilter2 ? "" : "z-10"
                    } text-sm text-gray-500 `}
                  >
                    <div className="flex justify-end">
                      <GenericButton
                        label="Fields"
                        className="text-themeGrey bg-white rounded-none"
                        icon={<CaretDownOutlined />}
                        onClick={() => {
                          setIsDisplayFilter(!isDisplayFilter);
                          setClickPosition("insideMessage");
                        }}
                      />
                    </div>

                    {isDisplayFilter && (
                      <CustomFilters
                        filterFor="message"
                        handleAddCustomFieldToMessage={
                          handleAddCustomFieldToMessage
                        }
                      />
                    )}
                  </div>
                  {/* Text Editor */}
                  <div
                    onClick={() => {
                      const quill = quillRef.current.getEditor();
                      setCursorPosition(quill.getSelection());
                    }}
                  >
                    <TextEditor
                      name="message"
                      label="Default Message"
                      rules={FormRule.DEFAULT_MESSAGE}
                      handleAddCustomFieldToMessage={
                        handleAddCustomFieldToMessage
                      }
                      value={editorValue}
                      onChange={setEditorValue}
                      quillRef={quillRef}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  <FormSelect
                    label="event"
                    placeholder="Select event"
                    name={"event"}
                    rules={FormRule.EVENT}
                    options={eventOptions}
                  />
                </div>
                <FormSelect
                  mode="multiple"
                  label="channels"
                  placeholder="Select channel"
                  name={"channelIds"}
                  options={channels.map((e) => {
                    return {
                      label: e.companyName,
                      value: e._id,
                      key: e._id,
                    };
                  })}
                />
              </div>
              <div className="w-4/12">
                <div className="text-[13px] font-semibold  justify-self-end mb-4">
                  You can choose existing template as an example.
                </div>
                <div
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                  className="text-sm flex items-center gap-x-3 font-semibold bg-labelGreen hover:bg-buttonTheme mb-2 hover:text-white rounded-md text-buttonTheme py-2 px-4 cursor-pointer justify-self-end"
                >
                  <span className="inline-flex">
                    <Icon
                      icon={isOpen ? "mdi:plus" : "mdi:minus"}
                      height={20}
                      width={20}
                    />
                  </span>
                  Choose one of example templates
                </div>
                {isOpen && (
                  <ul className="list pl-5 list-none">
                    {AUTO_MESSAGES.map((e, i) => {
                      return (
                        <li
                          className="bg-[#f3f3f4] hover:bg-gray-200 mb-2 text-[13px] rounded-md py-3 px-4 cursor-pointer"
                          key={i}
                          onClick={() => {
                            form.setFieldValue("message", e.message);
                          }}
                        >
                          {e.title}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          )}
        </div>
        {/* Add Button */}
        <div className="md:pt-[72px] px-5 pb-5">
          {!isLoading && (
            <Dropdown overlay={menu} placement="topLeft">
              <Button className="bg-selectedGreen text-themeGreen border-none w-20 flex items-center justify-between gap-1">
                Add <UpOutlined />
              </Button>
            </Dropdown>
          )}
        </div>
        {!isLoading && (
          <div className="flex flex-col gap-x-12 mt-2 px-5 w-8/12">
            {selectedItems.map((item) => {
              return (
                <div key={item.key} className="flex items-center mt-2">
                  <div className="border-2 border-creme w-full mr-4 py-4 px-2 flex items-center justify-between gap-3">
                    {getComponentForLabel(item)}
                    <div className="mr-4">
                      <GenericButton
                        className="bg-red-500 text-white"
                        label="X"
                        onClick={() => handleDeleteClick(item)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Form>
      {/* Listing Property Section */}
      <div className="md:px-5 px-2 xs:pb-40 w-8/12">
        {!isLoading && (
          <>
            <div className="text-themeGrey font-bold text-xl py-2">
              Listings
            </div>
            <div className="flex xs:flex-col sm:flex-row gap-3 items-center justify-between mr-5">
              <Search
                placeholder="Type to search listing"
                size="large"
                className="xs:w-[100%] xmd:w-[30%]"
                onSearch={(values) => {
                  fetchPropertyListings(values);
                }}
              />
              {/* Commented temporarily */}
              {/* <GenericButton
                label="Select all"
                className="text-white bg-themeGreen rounded-none"
                size="large"
              /> */}
            </div>
            {isListingLoading ? (
              <div className="flex items-center justify-center text-themeGrey py-5">
                Loading...
              </div>
            ) : propertyListings.length ? (
              <div className="grid xs:grid-cols-1 md:grid-cols-2 gap-5 py-2 mr-5">
                {propertyListings.map((property) => (
                  <AutomationListingCard
                    updateId={id}
                    listings={selectedPropertyListings}
                    propertyId={property._id}
                    key={property._id}
                    property={property}
                    onCheckboxChange={handleCheckboxChange}
                  />
                ))}
              </div>
            ) : (
              <div className="flex items-center justify-center text-themeGrey">
                No properties found.
              </div>
            )}
            {!isLoading && (
              <Pagination
                className="flex items-center justify-center py-4"
                current={currentPage}
                total={totalProperties}
                pageSize={6}
                onChange={(page) => {
                  setCurrentPage(page);
                  fetchPropertyListings(page);
                }}
              />
            )}
          </>
        )}
      </div>
      {!isLoading && (
        <div className="md:px-2 px-2 pb-5 w-8/12 flex gap-3 items-center">
          <Switch
            className="bg-themeGreen"
            defaultChecked={isAutomaticallyAddNewListingsChecked}
            onChange={(checked) => {
              setIsAutomaticallyAddNewListingsChecked(checked);
            }}
          />
          <div className="text-themeGrey">Automatically add new listings?</div>
        </div>
      )}
    </div>
  );
}
